import { useRegistrationFeeActions } from "../components/actions/useRegistrationFeeActions";
import { ConfirmUpdateFooterComponent } from "../components/interfaces/confirmUpdateFooter";
import { RegistrationFeeQuickAddControls } from "../components/registrationFees/RegistrationFeeQuickAddControls";
import { getPlatformFunctions } from "../platformSpecific";
import { showRegistrationFeeCreateButtons } from "../registrationFeeUtils";

export const useRegistrationFeeListPageData = (
  ConfirmUpdateFooter: ConfirmUpdateFooterComponent,
) => {
  const { useApi } = getPlatformFunctions();
  const { showUpdateForm } = useRegistrationFeeActions(ConfirmUpdateFooter);

  const { data: registrationFees, isLoading } = useApi("listRegistrationFee", {
    selectAll: false,
  });

  const isEmpty = registrationFees?.data.length === 0;

  const controls = (
    <RegistrationFeeQuickAddControls
      {...showRegistrationFeeCreateButtons(registrationFees?.data || [])}
    />
  );

  return { registrationFees, controls, isLoading, isEmpty, showUpdateForm };
};
