import { useTrialActions } from "shared/components";

import { SummaryCard } from "@/modules/company/classPlanner/classes/classDetails/SummaryCard";
import { Router } from "@/routing/router";

export const TrialsSummaryCard = ({
  classId,
  trialsCount,
}: {
  classId: string;
  trialsCount: number;
}) => {
  const actions = useTrialActions();

  return (
    <SummaryCard
      addPermission={"enrolments:manage"}
      icon={"time"}
      onCardClick={() => Router.push("ClassTrials", { classId })}
      onAddClick={() =>
        actions.showCreateForm({
          classId,
        })
      }
      text={`${trialsCount} trial student${trialsCount === 1 ? "" : "s"}`}
      footer={"Outstanding"}
    />
  );
};
