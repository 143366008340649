import { match } from "ts-pattern";

import { setPublicCompanyId } from "shared/lib";

import { AddNewCompanyPage } from "@/modules/auth/add-company-page/AddNewCompanyPage";
import { LoginPage } from "@/modules/auth/basic/LoginPage";
import { Logout } from "@/modules/auth/basic/Logout";
import { AccountSelectionPage } from "@/modules/auth/common/AccountSelectionPage";
import { RegisterCompanyPage } from "@/modules/auth/companyRegistration/RegisterCompanyPage";
import { InvitationPage } from "@/modules/auth/linked/InvitationPage";
import { LinkedLogin } from "@/modules/auth/linked/LinkedLogin";
import { LinkedRegister } from "@/modules/auth/linked/LinkedRegister";
import { ForgottenPasswordPage } from "@/modules/auth/reset/ForgottenPasswordPage";
import { PasswordResetLinkSentPage } from "@/modules/auth/reset/PasswordResetLinkSentPage";
import { ResetPasswordPage } from "@/modules/auth/reset/ResetPasswordPage";
import { CustomerAccessOverlay } from "@/modules/customer/CustomerAccessOverlay";
import { Home } from "@/modules/home/Home";
import { AdminRouter } from "@/routing/AdminRouter";
import { CatalogueRouter } from "@/routing/CatalogueRouter";
import { CompanyRouter } from "@/routing/CompanyRouter";
import { CustomerRouter } from "@/routing/CustomerRouter";
import { RouteNotFound } from "@/routing/RouteNotFound";
import { Router } from "@/routing/router";

export const AppRoutes = [
  "Home",
  "Login",
  "Logout",
  "RegisterCompany",
  "AddNewCompany",

  "ForgottenPassword",
  "PasswordResetLinkSent",
  "ResetPassword",

  "Invitation",
  "LinkedLogin",
  "LinkedRegister",
  "Accounts",

  "AdminPortal",
  "CompanyPortal",
  "CustomerPortal",
  "Catalogue",

  // "QuickbooksCallback",
] as const;

export const AppRouter = () => {
  const route = Router.useRoute(AppRoutes);

  // Always reset the current catalogue company on route change
  // The CatalogueRouter will take care of setting it appropriately from the slug, where available
  setPublicCompanyId(null);

  /**
   * @see https://swan-io.github.io/chicane/route-pattern-syntax
   */
  return (
    match(route)
      .with({ name: "Home" }, () => <Home />)
      .with({ name: "Login" }, () => <LoginPage />)
      .with({ name: "Logout" }, () => <Logout />)
      .with({ name: "RegisterCompany" }, ({ params: { email } }) => (
        <RegisterCompanyPage email={email} />
      ))
      .with({ name: "AddNewCompany" }, () => <AddNewCompanyPage />)
      .with({ name: "ForgottenPassword" }, () => <ForgottenPasswordPage />)
      .with({ name: "PasswordResetLinkSent" }, () => (
        <PasswordResetLinkSentPage />
      ))
      .with({ name: "ResetPassword" }, ({ params: { token, email } }) => (
        <ResetPasswordPage token={token} email={email} />
      ))
      .with({ name: "Invitation" }, ({ params: { id } }) => (
        <InvitationPage linkId={id} />
      ))
      .with({ name: "LinkedLogin" }, ({ params: { id, email } }) => (
        <LinkedLogin linkId={id} email={email} />
      ))
      .with({ name: "LinkedRegister" }, ({ params: { id, email } }) => (
        <LinkedRegister linkId={id} email={email} />
      ))
      .with({ name: "Accounts" }, () => <AccountSelectionPage />)
      // .with({ name: "QuickbooksCallback" }, () => <QuickbooksCallback />)

      // Delegate control to our individual portal routers
      .with({ name: "AdminPortal" }, () => <AdminRouter />)
      .with({ name: "CompanyPortal" }, () => <CompanyRouter />)
      .with({ name: "CustomerPortal" }, () => (
        <CustomerAccessOverlay>
          <CustomerRouter />
        </CustomerAccessOverlay>
      ))
      .with({ name: "Catalogue" }, ({ params: { slug } }) => (
        <CatalogueRouter slug={slug} />
      ))
      .otherwise(() => <RouteNotFound redirectTo="Login" />)
  );
};
