import {
  GenericFormComponent,
  GenericFormProps,
  useGenericForm,
} from "shared/components";

import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";

export const GenericForm: GenericFormComponent = <
  Dto extends object,
  FormSchema extends object,
  SubmitResponse extends object,
>({
  apiRequest,
  formDefinitionHook,
  defaultValues,
  onSuccess,
  onChange,
}: GenericFormProps<Dto, FormSchema, SubmitResponse>) => {
  return useGenericForm<SubmitResponse, FormSchema>({
    apiRequest,
    defaultValues,
    formDefinition: formDefinitionHook(),
    onSuccess,
    useFormBuilder,
    onChange,
  });
};
