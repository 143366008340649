import { Locale as LocaleEnum } from "@justraviga/classmanager-sdk";
import i18next, { TOptions } from "i18next";
import { initReactI18next } from "react-i18next";

import { common as enGb } from "./locales/enGB";
import { common as enUs } from "./locales/enUS";

export type Locale = `${LocaleEnum}`;

// i18next plural suffixes to filter out
const pluralSuffixes = [
  "_one",
  "_other",
  "_zero",
  "_two",
  "_few",
  "_many",
] as const;

// Extract the raw translation keys from JSON
type RawTranslationKeys = keyof typeof enUs;

// Remove plural suffixes from keys
type RemovePlural<T extends string> =
  T extends `${infer Base}${(typeof pluralSuffixes)[number]}` ? Base : T;

// Create the final translation key type
export type TranslationKey = RemovePlural<RawTranslationKeys>;

export type TFunction = <Key extends TranslationKey>(
  key: Key,
  options?: TOptions,
) => string;

export const defaultNS = "common"; // Default name space

export const initTranslations = async () => {
  await i18next.use(initReactI18next).init({
    defaultNS,
    fallbackLng: "en-US",
    debug: false,
    keySeparator: false,
    resources: {
      "en-GB": {
        common: enGb,
      },
      "en-US": {
        common: enUs,
      },
    },
  });
};

export const setLanguageLocale = (locale: Locale) =>
  i18next.changeLanguage(locale);

// @ts-expect-error - We don't care about the shape of globalThis
globalThis.t = i18next.t as typeof t;
