import { SeasonDto } from "@justraviga/classmanager-sdk";

import { useLocationsAndRooms } from "./useLocationsAndRooms";
import {
  ageRangeDescription,
  classAvailableSpacesInformation,
  classEnrolmentDescription,
  displayLessonTimes,
  getLocationDetails,
  minutesToHumanReadable,
} from "../classUtils";
import { useSettings } from "../components/useSettings";
import { ClassEntity } from "../entities";
import { formatDate, formatMoneyFromInteger } from "../intlFormatter";
import { getFullName } from "../personUtil";
import { getPlatformFunctions } from "../platformSpecific";
import { getSeasonStatus } from "../seasonUtils";
import { enumLabel } from "../translateUtils";

interface UseClassPreviewArgs {
  classEntity: ClassEntity;
  season: SeasonDto;
}

export const useClassPreview = ({
  classEntity,
  season,
}: UseClassPreviewArgs) => {
  const { useApi } = getPlatformFunctions();
  const { class: classDto, waitingLists } = classEntity;
  const { locations, rooms, isLoading } = useLocationsAndRooms({
    includeArchived: true,
  });
  const { data: staff } = useApi(
    "getStaff",
    { id: classDto.staffId! },
    { enabled: classDto.staffId !== null },
  );
  const na = "-";

  return {
    description: classDto.description || na,
    weekDay: enumLabel("weekDays", classDto.dayOfWeek),
    timeFrame: displayLessonTimes(classEntity.class),
    duration: minutesToHumanReadable(classDto.durationInMinutes),
    startDate: formatDate(season.startAt, "dayMonthYear"),
    endDate: formatDate(season.endAt, "dayMonthYear"),
    location: !isLoading
      ? getLocationDetails(rooms, locations, classDto.roomId)
      : na,
    instructor: classDto.staffId && staff ? getFullName(staff) : na,
    price: classDto.price ? `${formatMoneyFromInteger(classDto.price)}` : na,
    waitingEnrolment: ` There are ${waitingLists.totalCount || 0} students on the waiting list`,
    capacity: classDto.capacity || na,
    enrolmentDesc: classEnrolmentDescription(classEntity),
    availableSpaces: classAvailableSpacesInformation({
      course: classEntity.props,
      seasonStatus: getSeasonStatus(season),
    }),
    ageDesc: ageRangeDescription(classDto.minAgeMonths, classDto.maxAgeMonths),
    trialSettings: useSettings("trial"),
  };
};
