import { AggregateClassDto, PricingModel } from "@justraviga/classmanager-sdk";
import { PricingScheme } from "@justraviga/classmanager-sdk/dist/models/PricingScheme";

import { createClassUpdateFormDefaultValues } from "../../classUtils";
import { useTaxRates } from "../../data/useTaxRates";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeClassUpdateFormDefinition,
  makeClassUpdateRequest,
} from "../formDefinitions/courseForm";
import { useGenericComponents } from "../GenericComponentsProvider";

interface Props {
  course: AggregateClassDto;
  pricingScheme: PricingScheme;
  pricingModel: PricingModel;
}

export const CourseUpdateForm = ({
  course,
  pricingScheme,
  pricingModel,
}: Props) => {
  const { GenericForm } = useGenericComponents();
  const { usePlatformEntityActions } = getPlatformFunctions();
  const { taxRates, defaultTaxRateId } = useTaxRates();
  const defaultActions = usePlatformEntityActions<AggregateClassDto>({
    entity: "course",
  });

  return (
    <GenericForm
      apiRequest={makeClassUpdateRequest({
        id: course.entity.id,
        existingImage: course.entity.image,
      })}
      defaultValues={createClassUpdateFormDefaultValues(
        course,
        taxRates,
        defaultTaxRateId,
      )}
      formDefinitionHook={makeClassUpdateFormDefinition({
        pricingScheme,
        taxRates,
        pricingModel,
      })}
      onSuccess={defaultActions.onUpdateSuccess}
    />
  );
};
