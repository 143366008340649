import { ReactNode } from "react";

import { EnrolmentStatsDto } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { SeasonStatus } from "../../../../../seasonUtils";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

interface EnrolmentsSummaryCardProps {
  courseCapacity: number;
  enrolmentCounts: EnrolmentStatsDto;
  seasonStatus: SeasonStatus;
  goToEnrolments: () => void;
  addEnrolmentButton?: ReactNode;
}

export const EnrolmentsSummaryCard = ({
  courseCapacity,
  enrolmentCounts,
  seasonStatus,
  goToEnrolments,
  addEnrolmentButton,
}: EnrolmentsSummaryCardProps) => {
  const { BaseCard, ProtectedOverlay } = useGenericComponents();

  const enrolmentsCount = match(seasonStatus)
    .with("past", () => enrolmentCounts.pastCount)
    .with(
      "current",
      () => enrolmentCounts.currentCount + enrolmentCounts.upcomingCount,
    )
    .with("upcoming", () => enrolmentCounts.upcomingCount)
    .exhaustive();

  const availableSpaces = courseCapacity - enrolmentsCount;
  const availableSpaceText =
    courseCapacity > 0
      ? availableSpaces <= 0
        ? "No spaces available"
        : `${availableSpaces} space${availableSpaces === 1 ? "" : "s"}`
      : "Spaces available";

  return (
    <ProtectedOverlay permission={"enrolments:view"}>
      <BaseCard
        icon="people"
        iconPosition="top"
        title={`${enrolmentsCount} enrollment${enrolmentsCount === 1 ? "" : "s"}`}
        titleSize={16}
        titleColor="grey-900"
        description={availableSpaceText}
        onClick={goToEnrolments}
        headerRightSlot={Boolean(addEnrolmentButton) && addEnrolmentButton}
      />
    </ProtectedOverlay>
  );
};
