import { QuickActionButton } from "./QuickActionButton";
import { IconName } from "../../availableIcons";
import { useBreakpoint } from "../../breakpoints";
import { useGenericComponents } from "../GenericComponentsProvider";

interface ResponsiveQuickActionButtonProps {
  title: string;
  cardIcon: IconName;
  buttonIcon: IconName;
  action: () => void;
  variant: "brand" | "secondary" | "default" | "secondary-fill";
  forceTextWrap?: boolean;
}

export const ResponsiveQuickActionButton = ({
  action,
  title,
  cardIcon,
  buttonIcon,
  variant,
  forceTextWrap = false,
}: ResponsiveQuickActionButtonProps) => {
  const { View, BaseCard } = useGenericComponents();
  const breakpoint = useBreakpoint();

  return (
    <View className="flex-1">
      {breakpoint.md ? (
        <BaseCard
          onClick={action}
          title={title}
          titleSize={16}
          titleColor="grey-900"
          icon={cardIcon}
          iconPosition="left-framed"
        />
      ) : (
        <QuickActionButton
          size="md"
          onClick={action}
          text={title}
          icon={buttonIcon}
          variant={variant}
          forceTextWrap={forceTextWrap}
        />
      )}
    </View>
  );
};
