import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  ClassImplementedActions,
  useEnrolmentsActions,
} from "shared/components";
import {
  ClassEntity,
  EmptyEntity,
  getSeasonStatus,
  isDefinedEntity,
  useBreakpoint,
  useClassLessonsData,
} from "shared/lib";

import {
  CardContainer,
  CardContainerContent,
} from "@/modules/common/cardContainer/CardContainer";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { CenteredLoadingSpinner } from "@/modules/common/ui/CenteredLoadingSpinner";
import { ClassDetailsHeader } from "@/modules/company/classPlanner/classes/classDetails/ClassDetailsHeader";
import { EnrolmentSummaryCard } from "@/modules/company/classPlanner/classes/classDetails/EnrolmentSummaryCard";
import { TrialsSummaryCard } from "@/modules/company/classPlanner/classes/classDetails/TrialsSummaryCard";
import { useCourseDetailsPageData } from "@/modules/company/classPlanner/classes/classDetails/useCourseDetailsPageData";
import { WaitingListSummaryCard } from "@/modules/company/classPlanner/classes/classDetails/WaitingListSummaryCard";
import { ClassLessonsList } from "@/modules/company/classPlanner/classes/lessons/ClassLessonsList";
import { useCourseActions } from "@/modules/company/classPlanner/classes/useCourseActions";
import { DetailPageLayout } from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

export const CourseDetailsPage = ({ id }: { id: string }) => {
  const { isLoading, classEntity, season, isDesktop, crumbs } =
    useCourseDetailsPageData(id);
  const courseActions = useCourseActions();
  const enrolmentActions = useEnrolmentsActions();

  const actions = useActionMenu(classEntity, season, courseActions);

  const { records: lessons, isLoading: isLoadingLessons } = useClassLessonsData(
    (classEntity as ClassEntity | undefined)?.class?.id ?? null,
  );

  if (
    isLoading ||
    !isDefinedEntity(classEntity) ||
    season === undefined ||
    isLoadingLessons
  ) {
    return <CenteredLoadingSpinner />;
  }

  const goToEnrolments = () => {
    Router.push("Enrolments", {
      classId: classEntity.class.id,
    });
  };

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        permissions={{
          edit: "schedule:manage",
          restore: "schedule:manage",
        }}
        crumbs={crumbs}
        isArchived={classEntity.isArchived()}
        header={
          <ClassDetailsHeader classEntity={classEntity} season={season} />
        }
        restoreAction={() => courseActions.unarchive(classEntity.props)}
        archivedEntityName={"Class"}
        actions={actions}
        editAction={() => courseActions.showUpdateForm(classEntity, season)}
        isDesktop={isDesktop}>
        <CardContainer variant={"section"}>
          <CardContainerContent>
            <EnrolmentSummaryCard
              seasonStatus={getSeasonStatus(season)}
              enrolmentCounts={classEntity.props.stats.enrolments}
              courseCapacity={classEntity.class.capacity}
              goToEnrolments={goToEnrolments}
              addEnrolment={() =>
                enrolmentActions.showCreateForm({
                  course: classEntity.props,
                  season,
                })
              }
            />
            <TrialsSummaryCard
              classId={classEntity.props.entity.id}
              trialsCount={classEntity.trials.totalCount}
            />
            <WaitingListSummaryCard
              classId={classEntity.props.entity.id}
              waitingListCount={classEntity.waitingLists.totalCount}
            />
          </CardContainerContent>

          <ClassLessonsList
            records={lessons}
            courseDto={classEntity.class}
            season={season}
          />
        </CardContainer>
      </DetailPageLayout>
    </CompanyLayout>
  );
};

function useActionMenu(
  classEntity: ClassEntity | EmptyEntity,
  season: SeasonDto | undefined,
  courseActions: ClassImplementedActions,
) {
  const { md: isDesktop } = useBreakpoint();

  const items: ActionMenuItemProps[] = [];

  if (!isDefinedEntity(classEntity) || season === undefined) {
    return items;
  }

  /**
   * Pending a refactor of our ActionMenu to support the everything we want in one object,
   * this is as close as we can get with ease.
   */

  const group: ActionMenuItemProps["group"] = {
    items: [],
  };

  if (!isDesktop) {
    group.items.push({
      title: "Edit",
      leftIcon: "createOutline",
      onClick: () => courseActions.showUpdateForm(classEntity, season),
      permission: "schedule:manage",
    });
  }

  // todo: push to group.items once implemented
  // {
  //   title: "Email all current enrollments",
  //   leftIcon: "mailOutline",
  //   onPress: () =>
  //     showAlert({ content: "Not implemented yet", variant: "important" }),
  // },

  items.push(
    {
      title: classEntity.class.name,
      group,
    },
    {
      title: classEntity.isArchived() ? "Restore" : "Archive",
      hasDestructiveStyle: false,
      destructive: true,
      leftIcon: classEntity.isArchived() ? "reloadOutline" : "archiveOutline",
      onClick: () =>
        classEntity.isArchived()
          ? courseActions.unarchive(classEntity.props)
          : courseActions.archive(classEntity.props),
      permission: "schedule:manage",
    },
    {
      title: "Delete",
      destructive: true,
      leftIcon: "trashOutline",
      onClick: () =>
        courseActions
          .deleteOne(classEntity.props)
          .then(() => Router.push("SeasonDetails", { id: season.id })),
      permission: "schedule:manage",
    },
  );

  return items;
}
