import { z } from "zod";

import { initTranslations } from "@shared/translation/i18n";

import { customErrorMap } from "shared/lib";

// Setup the translator
initTranslations();

import "./setup/sentry";
import "./setup/appcues";
import "./setup/clarity";

// Set our global custom error map for Zod validation
z.setErrorMap(customErrorMap);
