import {
  BillingTiming,
  RegistrationFeeDto,
  RepetitionInterval,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { useAutomatedTuitionForm } from "./settings/automatedTuitionForm";
import { useAuthState } from "../../auth/useAuthState";
import { monthSpan } from "../../dateUtils";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { getPlatformFunctions } from "../../platformSpecific";
import { useRegistrationFeeActions } from "../actions/useRegistrationFeeActions";
import { useGenericComponents } from "../GenericComponentsProvider";
import { AlertDialog } from "../interfaces/alertDialog";
import { Sheet } from "../interfaces/sheet";
import { MonthlyPayment } from "../modules/company/seasons/create/MonthlyPayment";
import { PricingBlurb } from "../modules/company/seasons/create/PricingBlurb";
import { UpfrontPayment } from "../modules/company/seasons/create/UpfrontPayment";

export interface SeasonCreateFormDefinition {
  name: string;
  startAt: string;
  endAt: string;
  registrationFee: number;
  pricingPlan: string;
  upfront: boolean;
  monthlyInstallments: boolean;
  pricingScheme: string;
}

export interface SeasonCreateFormStateValues {
  discount: number | null;
  installments: number;
  userInstallments: number | null;
  paymentStartDate: string | null;
  seasonStartDate: string | null;
  seasonEndDate: string | null;
  userPaymentStartDate: string | null;
  userBillingDayOfMonth: number | null;
  userBillingTiming: BillingTiming | null;
}

export const useCreateSeasonFormSeasonDetailsDefinition = (
  openSheet: Sheet["openSheet"],
  closeSheet: Sheet["closeSheet"],
  showAlertDialog: AlertDialog,
  stateValues: SeasonCreateFormStateValues,
  setStateValues: (values: Partial<SeasonCreateFormStateValues>) => void,
) => {
  const { useApi } = getPlatformFunctions();
  const { setAccount, account } = useAuthState();

  const showAutomatedTuitionSettingsForm = useAutomatedTuitionForm(true);
  const registrationFeeActions = useRegistrationFeeActions();

  const { Button } = useGenericComponents();

  const { data: regFees } = useApi("listRegistrationFee", {
    selectAll: true,
    sort: { name: SortSchema.Asc },
  });

  return (
    new FormDefinitionBuilder<SeasonCreateFormDefinition>()
      .group(t("pageTitle.seasonDetails"), [
        "name",
        "startAt",
        "endAt",
        "registrationFee",
      ])
      .text("name", {
        label: "Name",
        required: true,
        description: "Recommended length: 20 characters",
      })
      .date("startAt", {
        label: "Start date",
        required: true,
        maxDate: stateValues.seasonEndDate,
      })
      .date("endAt", {
        label: "End date",
        required: true,
        minDate: stateValues.seasonStartDate,
      })
      .select("registrationFee", {
        label: "Registration fee",
        placeholder: "Click to select",
        data:
          regFees?.data.map((registrationFee: RegistrationFeeDto) => {
            return {
              label: registrationFee.name,
              value: registrationFee.id,
            };
          }) ?? [],
      })
      .contentBlock(
        "after",
        "registrationFee",
        <Button
          variant={"tertiaryLight"}
          size={"xs"}
          className={"pb-4"}
          text={"Create registration fee"}
          onClick={() => {
            // TODO: change this to use additional fees when ready.
            registrationFeeActions.showCreateForm(RepetitionInterval.OneOff);
          }}
        />,
      )
      .group("Billing information", ["pricingPlan"])
      .bigRadio("pricingPlan", {
        label: "How do you price your classes?",
        description: t("description.pricingPlan"),
        cols: 3,
        required: true,
        options: [
          {
            label: "By lesson",
            value: "lesson",
            icon: "calendarNumberOutline",
          },
          { label: "By month", value: "month", icon: "calendarOutline" },
          {
            label: t("label.pricingPlan.bySeason"),
            value: "season",
            icon: "calendarClearOutline",
          },
        ],
      })
      .conditional(
        ["pricingScheme"],
        ["pricingPlan", "startAt", "endAt"],
        v => {
          return (
            v.pricingPlan === "month" &&
            v.startAt !== undefined &&
            v.endAt !== undefined
          );
        },
      )
      .bigRadio("pricingScheme", {
        label: "How do you want to calculate your fees?",
        description:
          "Charge for your classes in a way that works best for you, out of the following:",
        cols: 1,
        options: [
          {
            label: "Fixed monthly fee",
            description: "Charge a fixed fee for each class.",
            value: "fixedMonthlyFee",
            icon: "cashOutline",
          },
          {
            label: "Number of classes",
            description:
              "Set a monthly fee based on how many classes a student attends weekly.",
            value: "numberOfClasses",
            icon: "personAddOutline",
          },
          {
            label: "Number of hours",
            description:
              "Monthly fee based on students' total weekly class hours. Perfect for varying lesson lengths.",
            value: "numberOfHours",
            icon: "timeOutline",
          },
        ],
      })
      .conditional(
        ["upfront", "monthlyInstallments"],
        ["pricingPlan", "startAt", "endAt"],
        v =>
          v.pricingPlan !== undefined &&
          v.startAt !== undefined &&
          v.endAt !== undefined,
      )
      // ::TODO:: switch this when we have a checkbox group component
      .contentBlock("before", "upfront", <PricingBlurb />)
      .checkbox("upfront", {
        label: t("label.season.payUpFront"),
        description: (
          <UpfrontPayment
            userPaymentStartDate={stateValues.userPaymentStartDate}
            paymentStartDate={stateValues.seasonStartDate!}
            openSheet={openSheet}
            closeSheet={closeSheet}
            discount={stateValues.discount}
            setDiscount={(discount: number | null) =>
              setStateValues({ discount })
            }
            setUserPaymentStartDate={(userPaymentStartDate: string | null) =>
              setStateValues({ userPaymentStartDate })
            }
            showAlertDialog={showAlertDialog}
          />
        ),
      })
      .checkbox("monthlyInstallments", {
        label: `Pay in ${stateValues.userInstallments || stateValues.installments} monthly installment${stateValues.userInstallments || stateValues.installments === 1 ? "" : "s"}`,
        description: (
          <MonthlyPayment
            seasonStartDate={stateValues.seasonStartDate!}
            closeSheet={closeSheet}
            openSheet={openSheet}
            account={account}
            setAccount={setAccount}
            installments={stateValues.installments}
            setUserBillingDayOfMonth={(userBillingDayOfMonth: number | null) =>
              setStateValues({ userBillingDayOfMonth })
            }
            setUserBillingTiming={(userBillingTiming: BillingTiming | null) =>
              setStateValues({ userBillingTiming })
            }
            setUserInstallments={(userInstallments: number) =>
              setStateValues({ userInstallments })
            }
            showAutomatedTuitionSettingsForm={showAutomatedTuitionSettingsForm}
            userBillingDayOfMonth={stateValues.userBillingDayOfMonth}
            userBillingTiming={stateValues.userBillingTiming}
            userInstallments={stateValues.userInstallments}
          />
        ),
      })
      .onChange(form => {
        if (form.startAt) {
          if (form.startAt !== stateValues.seasonStartDate) {
            setStateValues({ seasonStartDate: form.startAt });
          }
        }
        if (form.endAt) {
          if (form.endAt !== stateValues.seasonEndDate) {
            setStateValues({ seasonEndDate: form.endAt });
          }
        }
        if (form.startAt && form.endAt) {
          const installments = monthSpan(form.startAt, form.endAt);
          if (installments !== stateValues.installments) {
            setStateValues({ installments });
          }
        }
      })
      .getDefinition()
  );
};
