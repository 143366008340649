import {
  AggregateClassDto,
  PricingModel,
  WeekDay,
} from "@justraviga/classmanager-sdk";
import { PricingScheme } from "@justraviga/classmanager-sdk/dist/models/PricingScheme";

import { useTaxRates } from "../../data/useTaxRates";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeClassCreateFormDefinition,
  makeClassCreateRequest,
} from "../formDefinitions/courseForm";
import { useGenericComponents } from "../GenericComponentsProvider";

interface Props {
  seasonId: string;
  dayOfWeek?: WeekDay;
  pricingScheme: PricingScheme;
  pricingModel: PricingModel;
}

export const CourseCreateForm = ({
  seasonId,
  dayOfWeek,
  pricingScheme,
  pricingModel,
}: Props) => {
  const { GenericForm } = useGenericComponents();
  const { usePlatformEntityActions } = getPlatformFunctions();
  const { taxRates, defaultTaxRateId } = useTaxRates();
  const defaultActions = usePlatformEntityActions<AggregateClassDto>({
    entity: "course",
  });
  return (
    <GenericForm
      apiRequest={makeClassCreateRequest({
        seasonId: seasonId,
      })}
      defaultValues={{
        startTime: "12:00",
        discountable: true,
        portalEnrolmentEnabled: true,
        limitedCapacity: false,
        capacity: 0,
        dayOfWeek: dayOfWeek,
        taxRateId: defaultTaxRateId,
        trialsEnabled: true,
      }}
      formDefinitionHook={makeClassCreateFormDefinition({
        pricingScheme: pricingScheme,
        taxRates,
        pricingModel,
      })}
      onSuccess={defaultActions.onCreateSuccess}
    />
  );
};
