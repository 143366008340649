import { LocationDto } from "@justraviga/classmanager-sdk";

import {
  LocationCard,
  NoLocationsPlaceholder,
  useGenericComponents,
  useLocationActions,
} from "shared/components";
import { useLocationsAndRooms } from "shared/lib";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const LocationListPage = () => {
  const { View } = useGenericComponents();
  const { locations, roomCountForLocation, isEmpty, isLoading } =
    useLocationsAndRooms();

  const { showCreateForm } = useLocationActions();

  const primaryAction: PrimaryActionItemProps = {
    icon: "addOutline",
    title: "Create",
    onClick: showCreateForm,
    permission: "schedule:manage",
  };

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Go to archived items",
      leftIcon: "archiveOutline",
      onClick: () => Router.push("LocationListArchived"),
      permission: "schedule:view",
    },
  ];

  function goToLocation(location: LocationDto) {
    Router.push("LocationDetails", { id: location.id });
  }

  return (
    <CompanyCardPage
      title={"Locations"}
      isEmpty={isEmpty}
      isLoading={isLoading}
      primaryAction={primaryAction}
      menuItems={menuItems}>
      <CardContainerPlaceholder>
        <NoLocationsPlaceholder
          onCreateClick={showCreateForm}
          goToArchivedItems={() => Router.push("LocationListArchived")}
        />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {locations.map((location, key) => (
          <View key={key}>
            <LocationCard
              location={location}
              roomCount={roomCountForLocation(location)}
              goToLocation={goToLocation}
            />
          </View>
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "schedule:view",
  LocationListPage,
  "Locations",
);

export { PermissionChecked as LocationListPage };
