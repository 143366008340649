import {
  EmptyEntity,
  entityActionMenuBuilder,
  isDefinedEntity,
  WaiverEntity,
  WaiverImplementedActions,
} from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { Router } from "@/routing/router";

export function useWaiverDetailActionMenu(
  waiver: WaiverEntity | EmptyEntity,
  waiverActions: WaiverImplementedActions,
  forceEditAction: boolean = false,
) {
  const breakpoints = useBreakpoint();

  if (!isDefinedEntity(waiver)) {
    return [];
  }

  return entityActionMenuBuilder<ActionMenuItemProps>(waiver)
    .edit(
      {
        title: "Edit",
        onClick: () => waiverActions.showEditForm(waiver),
        permission: "waivers:manage",
      },
      !breakpoints.md || forceEditAction,
    )
    .restore({
      title: "Restore",
      onClick: () => waiverActions.unarchive(waiver.props),
      permission: "waivers:manage",
    })
    .archive({
      title: "Archive",
      onClick: () => waiverActions.archive(waiver.props),
      permission: "waivers:manage",
    })
    .delete({
      title: "Delete",
      onClick: () =>
        waiverActions
          .deleteOne(waiver.props)
          .then(() => Router.push("CompanyWaivers")),
      permission: "waivers:manage",
    })
    .build();
}
