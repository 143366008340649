import { RegistrationFeeDto } from "@justraviga/classmanager-sdk";

import {
  displayRegistrationFeeAmounts,
  displayRegistrationFeeType,
} from "../../registrationFeeUtils";
import { actionMenuBuilder } from "../action-menu/actionMenuBuilder";
import { useRegistrationFeeActions } from "../actions/useRegistrationFeeActions";
import { useGenericComponents } from "../GenericComponentsProvider";
import { Chip } from "../ui/Chip";

const ProRatedChip = ({ proRated }: { proRated: boolean }) => {
  if (proRated) {
    return <Chip label="Pro-rated" variant="neutral" />;
  }

  return null;
};

interface RegistrationFeeCardProps {
  fee: RegistrationFeeDto;
  showUpdateForm: (item: RegistrationFeeDto) => void;
}

export const RegistrationFeeCard = ({
  fee,
  showUpdateForm,
}: RegistrationFeeCardProps) => {
  const { BaseCard, View, Text } = useGenericComponents();
  const registrationFeeActions = useRegistrationFeeActions();

  const actions = actionMenuBuilder()
    .item({
      text: "Edit",
      icon: "createOutline",
      onClick: () => showUpdateForm(fee),
      permission: "schedule:manage",
    })
    .separator()
    .item({
      text: "Delete",
      icon: "trashOutline",
      onClick: () => registrationFeeActions.deleteOne(fee),
      variant: "destructive",
      permission: "schedule:manage",
    });

  return (
    <BaseCard
      onClick={() => showUpdateForm(fee)}
      title={fee.name}
      titleColor={"grey-900"}
      titleSize={16}
      titleWeight={600}
      actions={actions}
      bodySlot={
        <View className="flex flex-col gap-2">
          <Text>{displayRegistrationFeeAmounts(fee)}</Text>
          <Text>{displayRegistrationFeeType(t, fee)}</Text>
        </View>
      }
      headerRightSlot={<ProRatedChip proRated={!!fee.proRated} />}
    />
  );
};
