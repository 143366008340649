import { useEffect } from "react";

import { RegistrationFeeDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import { zeroYearDateToMonthDay } from "../../registrationFeeUtils";
import { useFormActions } from "../FormActionsProvider";
import {
  makeRegistrationFeeUpdateRequest,
  useRegistrationFeeFormDefinition,
} from "../formDefinitions/registrationFeeForm";
import { useGenericComponents } from "../GenericComponentsProvider";

interface UpdateRegistrationFeeFormProps {
  item: RegistrationFeeDto;
  onSuccess: () => void;
}

export const UpdateRegistrationFeeForm = ({
  item,
  onSuccess,
}: UpdateRegistrationFeeFormProps) => {
  const { api } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const { setAllowCreateAdditional } = useFormActions();

  useEffect(() => {
    setAllowCreateAdditional(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenericForm
      onSuccess={onSuccess}
      apiRequest={makeRegistrationFeeUpdateRequest({
        api,
        id: item.id,
        repetitionInterval: item.repetitionInterval,
      })}
      defaultValues={{
        ...item,
        dueDate: item.dueDate
          ? zeroYearDateToMonthDay(item.dueDate)
          : undefined,
        proRated: item.proRated ?? false,
        taxRateId: item.taxRate?.id ?? undefined,
      }}
      formDefinitionHook={useRegistrationFeeFormDefinition(
        item.repetitionInterval,
      )}
    />
  );
};
