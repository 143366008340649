import { ReactNode } from "react";

import { PermissionContext } from "./permissionContext";
import { Permission, permissionsToRoles } from "../../permission";
import { useAuthState } from "../AuthStateProvider";

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const { account, isLoading } = useAuthState();

  const hasPermission = (permission: Permission) => {
    return (
      account?.permissionSet !== undefined &&
      permissionsToRoles[permission].includes(account.permissionSet)
    );
  };

  return (
    <PermissionContext.Provider
      value={{
        hasPermission,
        isLoading,
      }}>
      {children}
    </PermissionContext.Provider>
  );
};
