import {
  SeasonDto,
  SeasonRegistrationStatus,
} from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { useAlert } from "../../../../../alertState";
import { colors } from "../../../../../colors";
import { ActionMenuBuilder } from "../../../../action-menu/actionMenuBuilder";
import { useSeasonRegistrationActions } from "../../../../actions/useSeasonRegistrationActions";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const RegistrationWidget = ({ season }: { season: SeasonDto }) => {
  const { View } = useGenericComponents();

  const regCard = match(season.registrationStatus)
    .with(SeasonRegistrationStatus.Never, () => (
      <NeverRegistrationCard season={season} />
    ))
    .with(SeasonRegistrationStatus.Open, () => (
      <OpenRegistrationBanner season={season} />
    ))
    .with(SeasonRegistrationStatus.Closed, () => (
      <ClosedRegistrationBanner season={season} />
    ))
    .with(SeasonRegistrationStatus.Ended, () => <EndedRegistrationBanner />)
    .exhaustive();

  return <View className={"w-full"}>{regCard}</View>;
};

const NeverRegistrationCard = ({ season }: { season: SeasonDto }) => {
  const { showAlert } = useAlert();
  const { BaseCard, Button, View } = useGenericComponents();
  const { enableSeasonRegistration } = useSeasonRegistrationActions();

  return (
    <BaseCard
      title={"Ready for registration?"}
      titleSize={16}
      titleColor={"grey-900"}
      fillColor={colors.grey["100"]}
      icon={"create"}
      iconPosition={"top"}
      iconGap={4}
      gap={1}
      description={
        "Open registration so your members can start enrolling for classes through the Family Portal."
      }
      footerSlot={
        <View className={"pt-4"}>
          <Button
            variant={"brand"}
            size={"sm"}
            text={"Open now"}
            onClick={() => {
              enableSeasonRegistration(season.id).then(season => {
                season &&
                  showAlert({
                    content: "Family portal registration opened successfully",
                  });
              });
            }}
            className={"px-4 py-1"}
          />
        </View>
      }
    />
  );
};

const OpenRegistrationBanner = ({ season }: { season: SeasonDto }) => {
  const { showAlert } = useAlert();
  const { Banner } = useGenericComponents();
  const { disableSeasonRegistration } = useSeasonRegistrationActions();
  return (
    <Banner
      title={"Registration open"}
      content={undefined}
      icon={{ name: "checkmarkCircle", color: colors.green[600] }}
      actionMenuTitle={"Registration"}
      actionMenuItems={new ActionMenuBuilder()
        .item({
          text: "Close Family Portal registration",
          onClick: () => {
            disableSeasonRegistration(season.id).then(season => {
              season &&
                showAlert({
                  content: "Family portal registration closed successfully",
                });
            });
          },
          icon: "closeCircleOutline",
        })
        .items()}
    />
  );
};

const ClosedRegistrationBanner = ({ season }: { season: SeasonDto }) => {
  const { showAlert } = useAlert();
  const { Banner } = useGenericComponents();
  const { enableSeasonRegistration } = useSeasonRegistrationActions();
  return (
    <Banner
      title={"Registration closed"}
      content={undefined}
      icon={{ name: "closeCircle", color: colors.grey[900] }}
      actionMenuTitle={"Registration"}
      actionMenuItems={new ActionMenuBuilder()
        .item({
          text: "Open Family Portal registration",
          onClick: () => {
            enableSeasonRegistration(season.id).then(season => {
              season &&
                showAlert({
                  content: "Family portal registration opened successfully",
                });
            });
          },
          icon: "checkmarkCircleOutline",
        })
        .items()}
    />
  );
};

const EndedRegistrationBanner = () => {
  const { Banner, Text, View } = useGenericComponents();
  return (
    <Banner
      title={"Registration closed"}
      content={
        <View>
          <Text className={"text-label-400 text-grey-900"}>
            {t("banner.registrationEnded.description")}
          </Text>
        </View>
      }
      icon={{ name: "closeCircle", color: colors.grey[900] }}
    />
  );
};
