import { TodaysScheduleWidget as SharedTodaysScheduleWidget } from "@shared/components/modules/company/dashboard/TodaysScheduleWidget";

import { Select } from "@/modules/common/form/select/Select";
import { Router } from "@/routing/router";

export const TodaysScheduleWidget = () => {
  return (
    <SharedTodaysScheduleWidget
      Select={Select}
      goToAttendances={params =>
        Router.push("ClassLessonAttendances", {
          ...params,
          id: params.courseId,
        })
      }
      goToLesson={params =>
        Router.push("LessonDetails", { ...params, id: params.courseId })
      }
    />
  );
};
