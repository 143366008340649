import { RepetitionInterval } from "@justraviga/classmanager-sdk";

import { useRegistrationFeeActions } from "../actions/useRegistrationFeeActions";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ResponsiveQuickActionButton } from "../ui/ResponsiveQuickActionButton";

interface RegistrationFeeQuickAddControlsProps {
  showOneOff?: boolean;
  showAnnual?: boolean;
}

export const RegistrationFeeQuickAddControls = ({
  showOneOff,
  showAnnual,
}: RegistrationFeeQuickAddControlsProps) => {
  const { View, ProtectedOverlay } = useGenericComponents();
  const registrationFeeActions = useRegistrationFeeActions();

  if (!showOneOff && !showAnnual) {
    return null;
  }

  return (
    <View className="flex flex-row items-center justify-start space-x-5 max-w-xl">
      {showOneOff && (
        <View>
          <ProtectedOverlay permission="schedule:manage">
            <ResponsiveQuickActionButton
              title={"Create one-off fee"}
              cardIcon="timeOutline"
              buttonIcon="timeOutline"
              forceTextWrap
              action={() =>
                registrationFeeActions.showCreateForm(RepetitionInterval.OneOff)
              }
              variant="secondary-fill"
            />
          </ProtectedOverlay>
        </View>
      )}
      {showAnnual && (
        <View>
          <ProtectedOverlay permission="schedule:manage">
            <ResponsiveQuickActionButton
              title={"Create annual fee"}
              cardIcon="repeat"
              buttonIcon="repeat"
              action={() =>
                registrationFeeActions.showCreateForm(RepetitionInterval.Annual)
              }
              variant="secondary-fill"
              forceTextWrap
            />
          </ProtectedOverlay>
        </View>
      )}
    </View>
  );
};
