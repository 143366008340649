import { PropsWithChildren } from "react";

import { useFamilyStripeStatus } from "../../payments/useFamilyStripeStatus";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";

export const StripeSetupChecker = ({
  children,
  hasImplicitCompany,
}: PropsWithChildren<{
  hasImplicitCompany?: boolean;
}>) => {
  const { View } = useGenericComponents();
  const { protector, isLoading } = useFamilyStripeStatus(hasImplicitCompany);

  // If we're loading, we don't want to show anything
  if (isLoading) {
    return null;
  }

  if (!protector) {
    return children;
  }

  return (
    <View className="h-full">
      <ContentPlaceholder
        icon={"buildOutline"}
        title={"We’re currently setting up your portal"}
        description={"Check back soon!"}
      />
    </View>
  );
};
