import { DiscountSchemeDto } from "@justraviga/classmanager-sdk";

import { discountSchemeNoContentDescription } from "@shared/discountScheme";

import { useDiscountSchemeTiersDatatable } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { useDiscountSchemeTierActions } from "@/modules/company/billing/discountSchemes/tiers/useDiscountSchemeTierActions";

export const DiscountSchemeTiersDatatable = ({
  discountScheme,
}: {
  discountScheme: DiscountSchemeDto;
}) => {
  const { deleteOne, showCreateForm, showUpdateForm } =
    useDiscountSchemeTierActions();

  const { datatable, totalRecords } = useDiscountSchemeTiersDatatable({
    Datatable,
    api,
    discountScheme: discountScheme,
    noContentDescription:
      discountSchemeNoContentDescription[discountScheme.scheme],
    showCreateForm: () =>
      showCreateForm({
        discountScheme: discountScheme,
        quantity: totalRecords + 1,
      }),
    showUpdateForm,
    deleteOne,
  });

  return <div className={"mt-8"}>{datatable}</div>;
};
