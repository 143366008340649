import { Breadcrumb, Breadcrumbs, ContentPlaceholder } from "shared/components";
import {
  colors,
  formatDate,
  urlParamToTime,
  useAttendanceStatuses,
  useBreakpoint,
  useLessonDetailsData,
} from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { AttendanceRow } from "@/modules/company/classPlanner/lessons/attendances/row/AttendanceRow";
import { LessonAttendancesHeader } from "@/modules/company/classPlanner/lessons/headers/LessonAttendancesHeader";
import { LessonAttendancesProgress } from "@/modules/company/classPlanner/lessons/headers/LessonAttendancesProgress";
import {
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
} from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const LessonAttendancesPage = ({
  id,
  date,
  time,
}: {
  id: string;
  date: string;
  time: string;
}) => {
  const formattedTime = urlParamToTime(time);

  const { season, courseDto, records, isLoading } = useLessonDetailsData({
    courseId: id,
    date,
    time: formattedTime,
  });

  const {
    statuses,
    setAttendanceStatuses,
    setAttendanceStatus,
    attendanceProgress,
  } = useAttendanceStatuses({
    classId: id,
    date,
    time: formattedTime,
  });

  const breakpoints = useBreakpoint();

  const crumbs: Breadcrumb[] = [
    {
      text: t("breadcrumb.seasons"),
      onClick: () => Router.push("SeasonList"),
    },
    {
      text: season?.name ?? "",
      onClick: () => {
        if (courseDto) {
          Router.push("SeasonDetails", { id: courseDto?.seasonId });
        }
      },
    },
    {
      text: courseDto?.name ?? "",
      onClick: () => {
        if (courseDto) {
          Router.push("ClassDetails", { id: courseDto?.id });
        }
      },
    },
    {
      text: formatDate(date, "weekdayShortDayMonthYear"),
      onClick: () => {
        if (courseDto) {
          Router.push("LessonDetails", {
            id: courseDto?.id,
            date: date,
            time: time,
          });
        }
      },
    },
    {
      text: "Attendance",
    },
  ];

  if (isLoading || !courseDto || !season || !statuses) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  const isEmpty = records.length === 0;

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <CompanyTitleBar isTopLevel={false}>
        {breakpoints.md ? (
          <Breadcrumbs items={crumbs} />
        ) : (
          <>
            <div className={"-ml-2"}>
              <IconButton
                icon={"chevronBackOutline"}
                variant={"standard"}
                size={"lg"}
                onClick={() => history.back()}
              />
            </div>
          </>
        )}

        <div className={"sm:hidden"}>
          {!isEmpty && (
            <LessonAttendancesProgress progress={attendanceProgress} />
          )}
        </div>
      </CompanyTitleBar>
      <CompanyContent>
        <LessonAttendancesHeader
          courseDto={courseDto}
          date={date}
          progress={attendanceProgress}
          setStatuses={setAttendanceStatuses}
          numStudents={records.length}
          statuses={statuses}
        />

        {isEmpty && (
          <div className={"py-8"}>
            <ContentPlaceholder
              icon={"schoolOutline"}
              title={"No students yet"}
              description={
                "When students are enrolled or trialling this class, they will appear here for you to mark attendance."
              }
            />
          </div>
        )}

        {!isEmpty && (
          <section className={"-mx-5 flex flex-col pt-2 sm:mx-0"}>
            {records.map(record => {
              return (
                <div key={record.student.id}>
                  <AttendanceRow
                    record={record}
                    status={statuses[record.student.id]}
                    onChangeStatus={status => {
                      setAttendanceStatus(record.student.id, status);
                    }}
                  />

                  <HorizontalSeparator spacing={2} />
                </div>
              );
            })}

            <div className={"flex flex-row space-x-2 px-5 py-4"}>
              <Icon
                name={"checkmarkCircleOutline"}
                size={24}
                color={colors.grey[900]}
              />
              <p className={"text-body-400 text-grey-900"}>
                You’ve made it to the end
              </p>
            </div>
          </section>
        )}
      </CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "attendance:manage",
  LessonAttendancesPage,
  "Lesson attendances",
);

export { PermissionChecked as LessonAttendancesPage };
