import { useState } from "react";

import { checkboxOptions } from "@shared/admin/docs/checkbox-examples";

import { DocWrapper } from "@/modules/admin/docs/DocWrapper";
import { Checkbox } from "@/modules/common/form/Checkbox";

export const CheckboxPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <DocWrapper
        title="Checkbox"
        component={Checkbox}
        options={checkboxOptions(isChecked, setIsChecked)}
      />
    </>
  );
};
