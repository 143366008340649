import { FooterLinks } from "@/modules/common/layout/FooterLinks";

interface Props {
  showTerms?: boolean;
}

export const NonAuthWebFooterSmall = ({ showTerms = true }: Props) => {
  return (
    <footer
      className={
        "flex items-center justify-center bg-white px-5 pb-10 pt-8 text-label-400 text-grey-600"
      }>
      <FooterLinks showTerms={showTerms} />
    </footer>
  );
};
