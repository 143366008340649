import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { PoweredBy } from "@/modules/auth/common/PoweredBy";
import { InvitationBasePage } from "@/modules/auth/linked/InvitationBasePage";
import { InvitationPageForm } from "@/modules/auth/linked/InvitationPageForm";
import { useInvitation } from "@/modules/auth/linked/useInvitation";
import { CompanyLogo } from "@/modules/company/CompanyLogo";

interface InvitationPageProps {
  linkId: string;
}

export const InvitationPage = ({ linkId }: InvitationPageProps) => {
  const {
    isLoading,
    hasError: apiError,
    linkDetails,
    companyDetails,
  } = useInvitation(linkId);

  const hasError = apiError || linkDetails?.id === undefined;

  return (
    <InvitationBasePage hasError={hasError} isLoading={isLoading}>
      <AuthPageCard
        showTerms={false}
        header={
          <div className="flex w-full justify-center">
            <CompanyLogo logo={companyDetails?.logo} />
          </div>
        }
        title={`You’ve been invited to join ${companyDetails?.name}`}
        subtitle={`Please enter your preferred email address below. You can have multiple Class Manager accounts with the same email address.`}
        content={<InvitationPageForm linkDetails={linkDetails} />}
        footer={<PoweredBy />}
      />
    </InvitationBasePage>
  );
};
