import { IconName } from "@shared/availableIcons";
import { Permission } from "@shared/permission";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export const SummaryCard = ({
  addPermission,
  onCardClick,
  onAddClick,
  icon,
  text,
  footer,
}: {
  addPermission?: Permission;
  onCardClick(): void;
  onAddClick(): void;
  icon: IconName;
  text: string;
  footer?: string;
}) => {
  return (
    <BaseCard
      onClick={onCardClick}
      headerRightSlot={
        <ProtectedOverlay permission={addPermission}>
          <IconButton
            className={"-mr-2 -mt-2"}
            icon={"addCircleOutline"}
            variant={"standard"}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onAddClick();
            }}
          />
        </ProtectedOverlay>
      }
      icon={icon}
      description={text}
      descriptionSize={16}
      descriptionColor={"grey-900"}
      footerSlot={
        footer && <p className={"text-label-400 text-grey-600"}>{footer}</p>
      }
    />
  );
};
