import {
  RegistrationFeeDto,
  RepetitionInterval,
} from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import { getRegistrationFeeFormTitle } from "../../registrationFeeUtils";
import { CreateRegistrationFeeForm } from "../forms/CreateRegistrationFeeForm";
import { UpdateRegistrationFeeForm } from "../forms/UpdateRegistrationFeeForm";
import { AlertDialogProps } from "../interfaces/alertDialog";
import { ConfirmUpdateFooterComponent } from "../interfaces/confirmUpdateFooter";

type Model = RegistrationFeeDto;

export const useRegistrationFeeActions = (
  ConfirmUpdateFooter?: ConfirmUpdateFooterComponent,
) => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<Model>({
    entity: "registrationFee",
  });

  return {
    showCreateForm: (repetitionInterval: RepetitionInterval) =>
      defaultActions.showCreateForm({
        title: getRegistrationFeeFormTitle(t, repetitionInterval, "create"),
        form: (
          <CreateRegistrationFeeForm
            repetitionInterval={repetitionInterval}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),
    showUpdateForm: (item: Model) =>
      defaultActions.showUpdateForm({
        title: getRegistrationFeeFormTitle(
          t,
          item.repetitionInterval,
          "update",
        ),
        form: (
          <UpdateRegistrationFeeForm
            item={item}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
        footer: ConfirmUpdateFooter && (
          <ConfirmUpdateFooter
            saveButtonText={"Save"}
            confirm={
              {
                title: `Confirm changes`,
                description: `Changing this registration fee will apply it to all future enrollments.`,
                confirmButtonText: "Save changes",
                confirmButtonStyle: "primary",
              } as AlertDialogProps
            }
          />
        ),
      }),
    deleteOne: (item: Model) =>
      defaultActions.deleteOne(
        () => api.registrationFees.deleteRegistrationFee({ id: item.id }),
        {
          description:
            "Are you sure you want to delete this registration fee? Any records of it being charged will be removed, and your students may be re-charged a registration fee.",
        },
      ),
  };
};
