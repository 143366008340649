import { useStripeStatus } from "./useStripeStatus";

export function useFamilyStripeStatus(hasImplicitCompany?: boolean) {
  const { protector: stripeCompanyProtector, isLoading } =
    useStripeStatus(hasImplicitCompany);

  const stripeFamilyProtector = stripeCompanyProtector
    ? "You don't currently have access to the Family Portal."
    : undefined;

  return {
    protector: stripeFamilyProtector,
    isLoading,
  };
}
