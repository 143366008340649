import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  makeSeasonUpdateRequest,
  SeasonImplementedActions,
  useSharedSeasonActions,
  useUpdatePricingSchemeDefinition,
} from "shared/components";
import { formatMoneyFromInteger } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Banner } from "@/modules/common/ui/Banner";
import { SeasonPreview } from "@/modules/company/classPlanner/seasons/preview/SeasonPreview";
import { Router } from "@/routing/router";

type Model = SeasonDto;

export const useSeasonActions = (): SeasonImplementedActions => {
  const { openSheet } = useSheet();
  const defaultActions = usePlatformEntityActions<SeasonDto>({
    entity: "season",
  });
  const { closeSheet, setTitle } = useSheet();

  const sharedActions = useSharedSeasonActions({
    defaultActions,
    goToDetailsPage: (season: Model) => {
      Router.push("SeasonDetails", { id: season.id });
    },
    closeSheet,
    setTitle,
  });

  return {
    ...sharedActions,

    showPricingSchemeUpdateForm: (entity: Model) => {
      return defaultActions.showUpdateForm({
        title: "Edit pricing scheme",
        form: (
          <>
            <Banner
              content={
                <>
                  <Banner.title>Prices will reset</Banner.title>
                  <Banner.content>
                    Editing this pricing scheme will reset all class prices
                    to&nbsp;
                    {formatMoneyFromInteger(0, 0)}.
                  </Banner.content>
                </>
              }
              variant={"warning"}
            />
            <GenericForm
              apiRequest={makeSeasonUpdateRequest({ api, id: entity.id })}
              defaultValues={entity}
              formDefinitionHook={useUpdatePricingSchemeDefinition}
              onSuccess={defaultActions.onUpdateSuccess}
            />
          </>
        ),
      });
    },

    showPreview: (entity: Model) => {
      openSheet({
        title: t("pageTitle.seasonPreview"),
        content: <SeasonPreview season={entity} />,
      });
    },
  };
};
