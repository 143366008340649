import { AttendanceDto, TrialDto } from "@justraviga/classmanager-sdk";

import { useEnrolmentsActions } from "@shared/components/actions/useEnrolmentActions";
import { useTrialActions } from "@shared/components/actions/useTrialActions";
import { useTrialsDatatable } from "@shared/components/datatables/useTrialsDatatable";
import { AttendanceStatusChip } from "@shared/components/trials/AttendanceStatusChip";
import { formatWeekDayType } from "@shared/dateUtils";
import { formatDate, formatTime } from "@shared/intlFormatter";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { useCompanySettingsActions } from "@/modules/company/settings/useCompanySettingsActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const TrialsPage = ({ classId }: { classId?: string }) => {
  const actions = useTrialActions();
  const { showCreateForm: showEnrolmentForm } = useEnrolmentsActions();
  const { showTrialsForm } = useCompanySettingsActions();

  const { datatable } = useTrialsDatatable({
    classId,
    Datatable,
    api,
    useApi,
    archive: actions.archive,
    deleteOne: actions.deleteOne,
    showCreateForm: actions.showCreateForm,
    showUpdateForm: item => actions.showUpdateForm(item),
    goToStudent: id => Router.push("StudentDetails", { id }),
    enrollForClass: item => {
      showEnrolmentForm({
        course: item.class,
        season: item.season,
        studentIds: [item.studentId],
        showStudentSelector: false,
      });
    },
    renderClassDetails: item => (
      <div className="flex w-full flex-col gap-1">
        <Tooltip
          trigger={
            <div className="truncate text-body-400 text-grey-900">
              {`${item.class.entity.name}`}
            </div>
          }
          children={`${item.class.entity.name}`}
        />

        <div className="s line-clamp-1 text-label-400 capitalize text-grey-600">
          {[
            formatWeekDayType(item.class.entity.dayOfWeek),
            formatTime(item.class.entity.startTime, "hourMinute"),
          ].join(" • ")}
        </div>
      </div>
    ),
    renderTrialDetails: item => (
      <div className="flex flex-col">
        <div className="text-label-400 text-grey-600">
          {formatDate(item.trialAt, "dayMonthYear")}
        </div>
        <ChipRow item={item} />
      </div>
    ),
    renderMobileDetails: item => (
      <div>
        <div className="text-label-400 capitalize leading-tight text-grey-600">
          {item.class.entity.dayOfWeek},{" "}
          {formatTime(item.class.entity.startTime, "hourMinute")}
          {" • "}
          {formatDate(item.trialAt, "dayMonthYear")}
        </div>
        <ChipRow item={item} />
      </div>
    ),
    openTrialSettings: () => showTrialsForm(),
  });

  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const ChipRow = ({
  item,
}: {
  item: TrialDto & { attendance?: AttendanceDto };
}) => (
  <div className="mt-1 flex flex-row justify-start">
    <AttendanceStatusChip attendance={item.attendance} />
  </div>
);

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "enrolments:view",
  TrialsPage,
  "Trials",
);

export { PermissionChecked as TrialsPage };
