import { NoItemsContentPlaceholder } from "../../../ui/NoItemsContentPlaceholder";

interface Props {
  goToArchivedItems: () => void;
  onCreateClick: () => void;
}

export const NoSeasonsPlaceholder = ({
  onCreateClick,
  goToArchivedItems,
}: Props) => {
  return (
    <NoItemsContentPlaceholder
      icon={"calendarClearOutline"}
      title={t("placeholder.noSeasons")}
      description={t("placeholder.noSeasons.description")}
      createAction={{
        onClick: onCreateClick,
        text: t("button.createSeason"),
        permission: "schedule:manage",
      }}
      navigationAction={{
        onClick: goToArchivedItems,
        text: "Go to archived items",
        permission: "schedule:view",
      }}
    />
  );
};
