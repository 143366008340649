import {
  AggregateClassDto,
  SeasonDto,
  WeekDay,
} from "@justraviga/classmanager-sdk";

import { ClassEntity } from "../../entities";
import { getPlatformFunctions } from "../../platformSpecific";
import { CourseCreateForm } from "../forms/CourseCreateForm";
import { CourseUpdateForm } from "../forms/CourseUpdateForm";
import { EntityActions } from "../useDefaultEntityActions";

type Model = AggregateClassDto;

export type ClassImplementedActions = Omit<
  EntityActions<Model>,
  "showUpdateForm"
> & {
  showCreateFormWithWeekDay: (season: SeasonDto, dayOfWeek: WeekDay) => void;
  showUpdateForm: (entity: ClassEntity, season: SeasonDto) => void;
  showPreview: (entity: ClassEntity, season: SeasonDto) => void;
};

export const useSharedCourseActions = () => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<Model>({
    entity: "course",
  });

  return {
    showCreateFormWithWeekDay: (season: SeasonDto, dayOfWeek: WeekDay) =>
      defaultActions.showCreateForm({
        form: (
          <CourseCreateForm
            seasonId={season.id}
            dayOfWeek={dayOfWeek}
            pricingScheme={season.pricingScheme}
            pricingModel={season.pricingModel}
          />
        ),
      }),

    showCreateForm: (season: SeasonDto) =>
      defaultActions.showCreateForm({
        form: (
          <CourseCreateForm
            seasonId={season.id}
            pricingScheme={season.pricingScheme}
            pricingModel={season.pricingModel}
          />
        ),
      }),

    showUpdateForm: (classEntity: ClassEntity, season: SeasonDto) =>
      defaultActions.showUpdateForm({
        form: (
          <CourseUpdateForm
            course={classEntity.props}
            pricingScheme={season.pricingScheme}
            pricingModel={season.pricingModel}
          />
        ),
      }),

    deleteOne: (aggCourseDto: Model) =>
      defaultActions.deleteOne(() =>
        api.courses.deleteCourse({ id: aggCourseDto.entity.id }),
      ),

    deleteMany: (ids: string[]) =>
      defaultActions.deleteMany(
        () =>
          api.courses.deleteManyCourse({ deleteManyCourseRequest: { ids } }),
        ids.length,
      ),

    archive: (aggCourseDto: Model) =>
      defaultActions.archive(() =>
        api.courses.archiveCourse({ id: aggCourseDto.entity.id }),
      ),

    archiveMany: (ids: string[]) =>
      defaultActions.archiveMany(
        () => api.courses.archiveCourses({ archiveCoursesRequest: { ids } }),
        ids.length,
      ),

    unarchive: (aggCourseDto: Model) =>
      defaultActions.unarchive(() =>
        api.courses.unarchiveCourse({ id: aggCourseDto.entity.id }),
      ),

    unarchiveMany: (ids: string[]) =>
      defaultActions.unarchiveMany(() =>
        api.courses.unarchiveCourses({ unarchiveCoursesRequest: { ids } }),
      ),
  };
};
