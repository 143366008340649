import { NoItemsContentPlaceholder } from "../../../ui/NoItemsContentPlaceholder";

interface Props {
  goToArchivedItems: () => void;
  onCreateClick: () => void;
}

export const NoLocationsPlaceholder = ({
  onCreateClick,
  goToArchivedItems,
}: Props) => {
  return (
    <NoItemsContentPlaceholder
      icon={"locationOutline"}
      title={"You haven't created any locations"}
      createAction={{
        onClick: onCreateClick,
        text: "Create",
        permission: "schedule:manage",
      }}
      navigationAction={{
        onClick: goToArchivedItems,
        text: "Go to archived items",
        permission: "schedule:view",
      }}
    />
  );
};
