import { PropsWithChildren } from "react";

import { PermissionLayoutProps } from "@shared/components/permission/PermissionLayout";
import { cn } from "@shared/cssUtils";

import { Tooltip } from "@/modules/common/overlays/Tooltip";

export interface InteractionOverlayProps extends PropsWithChildren {
  message?: string;
  enabled?: boolean;
  shape?: PermissionLayoutProps["shape"];
}

export const InteractionOverlay = ({
  children,
  enabled,
  message,
  shape,
}: InteractionOverlayProps) => {
  if (!enabled) {
    return children;
  }

  return (
    <Tooltip
      trigger={
        <div
          className="relative w-full"
          onClickCapture={e => {
            e.stopPropagation();
            e.preventDefault();
          }}>
          <div className="opacity-30">{children}</div>
          <div
            className={cn("absolute inset-0", {
              "rounded-full": shape === "rounded-full",
              rounded: shape === "rounded",
            })}></div>
        </div>
      }
      children={<div className={"text-center"}>{message}</div>}
    />
  );
};
