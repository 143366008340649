import { FC, ReactNode } from "react";

import {
  AggregateClassDto,
  AttendanceDto,
  SeasonDto,
  StudentDto,
  TrialDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { fetchTrialsData } from "../../data/trialsData";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { FilterFormDefinitionBuilder } from "../../forms/filterFormDefinitionBuilder";
import { formatDate } from "../../intlFormatter";
import { getFullName } from "../../personUtil";
import { displayStudentAge } from "../../studentUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import { AttendanceStatusChip } from "../trials";

type Dto = TrialDto;
type RowDto = Dto & {
  attendance?: AttendanceDto;
  class: AggregateClassDto;
  season: SeasonDto;
  student: StudentDto;
};
type FilterForm = object;

interface UseClassTrialsDatatableParams {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, object, RowDto> }>;
  api: Api;
  classId: string;
  goToStudent: (id: string) => void;
  enrollForClass: (item: RowDto) => void;
  markAsNotContinuing: (item: RowDto) => void;
  showUpdateForm: (item: RowDto) => void;
  deleteOne: (item: RowDto) => void;
  showCreateForm?: () => void;
}

export const useClassTrialsDatatable = ({
  Datatable,
  api,
  classId,
  goToStudent,
  enrollForClass,
  markAsNotContinuing,
  showUpdateForm,
  deleteOne,
  showCreateForm,
}: UseClassTrialsDatatableParams): { datatable: ReactNode } => {
  const { View } = useGenericComponents();

  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    return fetchTrialsData({ api, request, classId });
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: `class-trials-${classId}`,
    permissions: {
      archive: "enrolments:manage",
      create: "enrolments:manage",
      delete: "enrolments:manage",
      edit: "enrolments:manage",
      restore: "enrolments:manage",
    },
    createLabel: "Add",
    createAction: showCreateForm,
    filterForm: new FilterFormDefinitionBuilder()
      .withArchiveFilter()
      .getDefinition(),
    rowActions: {
      title: item => getFullName(item.student),
      click: item => goToStudent(item.student.id),
      additionalRowActions: () => [
        {
          icon: "openOutline",
          label: "Go to student",
          onClick: item => goToStudent(item.student.id),
          permission: "members:view",
        },
        {
          title: "Actions",
          actions: [
            {
              icon: "calendarOutline",
              label: "Enroll for class",
              onClick: enrollForClass,
              permission: "enrolments:manage",
            },
            {
              icon: "closeCircleOutline",
              label: "Mark as not continuing",
              onClick: markAsNotContinuing,
              permission: "enrolments:manage",
            },
          ],
        },
        {
          title: "Trial",
          actions: [
            {
              icon: "createOutline",
              label: "Edit",
              onClick: showUpdateForm,
              permission: "enrolments:manage",
            },
          ],
        },
        {
          icon: "trashOutline",
          label: "Delete",
          onClick: deleteOne,
          isDestructive: true,
          permission: "enrolments:manage",
        },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "No trials for this class yet",
        description: "",
      },
    },
    hasPagination: false,
    hasSearch: true,
    showTotalRecords: true,
    placeholdersCount: 5,
    fetchData,
    columns: [
      {
        label: "Student",
        placeholder: "tile",
        value: row => ({
          type: "tile",
          title: getFullName(row.item.student),
          subtitle: displayStudentAge(row.item.student),
          image: row.item.student.profilePicture ?? undefined,
        }),
      },
      {
        label: "Trial date",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDate(row.item.trialAt, "dayMonthYear"),
        }),
      },
      {
        label: "Attendance",
        placeholder: "text",
        value: row => ({
          type: "custom",
          children: <AttendanceStatusChip attendance={row.item.attendance} />,
        }),
      },
    ],
    mobileColumn: {
      hasImage: true,
      title: row => getFullName(row.item.student),
      subtitle: row => formatDate(row.item.trialAt, "dayMonthYear"),
      image: row => row.item.student.profilePicture ?? undefined,
      children: row => (
        <View className="pt-1 flex flex-row items-start">
          <AttendanceStatusChip attendance={row.item.attendance} />
        </View>
      ),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
