import {
  makeUnPostedTuitionFormRequest,
  useUnpostedTuitionForm,
} from "shared/components";
import { useAlert } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";

export const UnpostedTuitionForm = ({ familyId }: { familyId: string }) => {
  const { showAlert } = useAlert();
  const { closeSheet } = useSheet();

  const onSuccess = () => {
    showAlert({
      content: "Tuition generated successfully",
    });
    closeSheet();
  };

  return (
    <div className={"flex flex-col space-y-4"}>
      <p className={"pb-5 text-body-400 text-grey-600"}>
        {t("description.unpostedTuitionForm")}
      </p>
      <GenericForm
        apiRequest={makeUnPostedTuitionFormRequest({
          api,
          familyId: familyId,
        })}
        defaultValues={{
          familyId: familyId,
        }}
        formDefinitionHook={useUnpostedTuitionForm}
        onSuccess={onSuccess}
      />
    </div>
  );
};
