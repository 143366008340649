import { useFormActions } from "@shared/components/FormActionsProvider";
import { ConfirmUpdateFooterProps } from "@shared/components/interfaces/confirmUpdateFooter";

import { FormFooter } from "@/modules/common/form/FormFooter";
import { useAlertDialog } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";

export const ConfirmUpdateFooter = ({
  confirm,
  saveButtonText = "Save",
}: ConfirmUpdateFooterProps) => {
  const { isLoading, submit, setShouldCloseOnSuccess, isSubmitDisabled } =
    useFormActions();
  const { closeSheet } = useSheet();
  const { showAlert: showConfirmationDialog } = useAlertDialog();

  const onSave = () => {
    showConfirmationDialog({
      title: confirm.title,
      description: confirm.description,
      confirmButtonText: confirm?.confirmButtonText ?? "Save",
      confirmButtonStyle: confirm?.confirmButtonStyle,
      cancelText: confirm?.cancelText ?? "Cancel",
    }).then(result => {
      if (result) {
        setShouldCloseOnSuccess(true);
        submit();
      }
    });
  };

  return (
    <FormFooter>
      <Button text={"Cancel"} variant="secondary" onClick={closeSheet} />
      <Button
        variant={"brand"}
        text={saveButtonText}
        disabled={isLoading || isSubmitDisabled}
        loading={isLoading}
        onClick={onSave}
      />
    </FormFooter>
  );
};
