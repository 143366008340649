import React from "react";

import { SeasonDto } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../../../alertState";
import {
  SeasonCreateFormDefinition,
  SeasonCreateFormStateValues,
  useCreateSeasonFormSeasonDetailsDefinition,
} from "../../../formDefinitions/useCreateSeasonFormSeasonDetailsDefinition";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { Sheet } from "../../../interfaces";
import { AlertDialog } from "../../../interfaces/alertDialog";

interface Props {
  goToSeasonDetails: (seasonId: SeasonDto["id"]) => void;
  openSheet: Sheet["openSheet"];
  closeSheet: Sheet["closeSheet"];
  showAlertDialog: AlertDialog;
}

export const SeasonCreatePageForm = ({
  goToSeasonDetails,
  openSheet,
  closeSheet,
  showAlertDialog,
}: Props) => {
  const { GenericForm, View } = useGenericComponents();

  const [stateValues, setStateValues] =
    React.useState<SeasonCreateFormStateValues>({
      discount: null,
      installments: 1,
      userInstallments: null,
      paymentStartDate: null,
      seasonStartDate: null,
      seasonEndDate: null,
      userPaymentStartDate: null,
      userBillingDayOfMonth: null,
      userBillingTiming: null,
    });

  const formDefinition = useCreateSeasonFormSeasonDetailsDefinition(
    openSheet,
    closeSheet,
    showAlertDialog,
    stateValues,
    values => {
      setStateValues(v => ({ ...v, ...values }));
    },
  );

  function submitForm(form: SeasonCreateFormDefinition) {
    const errors: {
      statusCode: number;
      messages: string[];
      validationErrors: Record<string, string[]>;
    } = {
      statusCode: 422,
      messages: [],
      validationErrors: {},
    };
    if (!form.upfront && !form.monthlyInstallments) {
      errors.messages.push("Select at least one payment term.");
      errors.validationErrors.monthlyInstallments = [
        "Select at least one payment term.",
      ];
    }
    if (form.pricingPlan === "month" && !form.pricingScheme) {
      errors.messages.push("Select a calculation method for monthly payments.");
      errors.validationErrors.pricingScheme = [
        "Select a calculation method for monthly payments.",
      ];
    }
    if (errors.messages.length > 0) {
      return Promise.reject(errors);
    }

    /**
     * todo
     *   this is as far as we can go right now because the backend isn't updated to support
     *   the contents of this form yet.
     */
    const requestData = {
      ...form,
      pricingScheme: form.pricingPlan === "month" ? form.pricingScheme : null,
      discount: stateValues.discount,
      installments: stateValues.userInstallments || stateValues.installments,
      paymentStartDate:
        stateValues.userPaymentStartDate || stateValues.paymentStartDate,
      billingDayOfMonth: stateValues.userBillingDayOfMonth,
      billingTiming: stateValues.userBillingTiming,
    };
    console.log(requestData);

    // temporary: "pretend" to succeed
    return Promise.resolve({
      id: "sea_01hsbdkg2hnnwnkw22cn3rps9q",
      companyId: "co_01hsbdkes29a2d2qyn6cj943v9",
      name: form.name,
      startAt: form.startAt,
      endAt: form.endAt,
      registrationFee: form.registrationFee,
      pricingPlan: form.pricingPlan,
      upfront: form.upfront,
      monthlyInstallments: form.monthlyInstallments,
      pricingScheme: form.pricingScheme,
      registrationOpen: false,
      registrationFeeTaxRateId: null,
      hasMaxRegistrationFee: false,
    } as unknown as SeasonDto);
  }

  function submitFormSuccess(result: SeasonDto) {
    showAlert({
      content: t("alert.season.created", { name: result.name }),
    });
    goToSeasonDetails(result.id);
  }

  return (
    <View className={"md:px-24"}>
      <GenericForm
        apiRequest={submitForm}
        defaultValues={{}}
        formDefinitionHook={() => formDefinition}
        onSuccess={submitFormSuccess}
      />
    </View>
  );
};
