import { RegistrationFeeCard } from "@shared/components/registrationFees/RegistrationFeeCard";
import { useRegistrationFeeListPageData } from "@shared/data/useRegistrationFeeListPageData";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { ConfirmUpdateFooter } from "@/modules/common/form/ConfirmUpdateFooter";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";

const RegistrationFeeListPage = () => {
  const { registrationFees, controls, isLoading, isEmpty, showUpdateForm } =
    useRegistrationFeeListPageData(ConfirmUpdateFooter);

  return (
    <CompanyCardPage
      title={"Registration fees"}
      isEmpty={isEmpty}
      isLoading={isLoading}>
      <CardContainerPlaceholder>{controls}</CardContainerPlaceholder>
      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-row">
          {!isEmpty && !isLoading && controls}
        </div>
        <CardContainerContent maxColumns={2}>
          {registrationFees?.data.map((fee, key) => (
            <RegistrationFeeCard
              fee={fee}
              key={key}
              showUpdateForm={showUpdateForm}
            />
          ))}
        </CardContainerContent>
      </div>
    </CompanyCardPage>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "schedule:view",
  RegistrationFeeListPage,
  "Registration fees",
);

export { PermissionChecked as RegistrationFeeListPage };
