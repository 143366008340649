import { LocationDto } from "@justraviga/classmanager-sdk";

import { actionMenuBuilder } from "../../../../action-menu/actionMenuBuilder";
import { useLocationActions } from "../../../../actions/useLocationActions";
import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { Chip } from "../../../../ui/Chip";

interface LocationCardProps {
  location: LocationDto;
  roomCount: number;
  goToLocation(location: LocationDto): void;
}

export const LocationCard = ({
  location,
  roomCount,
  goToLocation,
}: LocationCardProps) => {
  const { BaseCard, Text, View } = useGenericComponents();
  const { showUpdateForm, deleteOne, archive, unarchive } =
    useLocationActions();

  const actions = actionMenuBuilder()
    .item({
      text: "Edit",
      icon: "createOutline",
      onClick: () => showUpdateForm(location),
      permission: "schedule:manage",
    })
    .item({
      text: "Archive",
      icon: "archiveOutline",
      onClick: () => archive(location),
      visible: location.archivedAt === undefined,
      permission: "schedule:manage",
    })

    .item({
      text: "Restore",
      icon: "reloadOutline",
      onClick: () => unarchive(location),
      visible: location.archivedAt !== undefined,
      permission: "schedule:manage",
    })
    .item({
      text: "Delete",
      icon: "trashOutline",
      onClick: () => deleteOne(location),
      variant: "destructive",
      permission: "schedule:manage",
    });

  const plural = roomCount === 1 ? "" : "s";

  return (
    <BaseCard
      onClick={() => goToLocation(location)}
      title={location.name}
      titleColor={"grey-900"}
      titleWeight={600}
      titleSize={16}
      actions={actions}
      bodySlot={
        <Text>
          {roomCount} room{plural}
        </Text>
      }
      footerSlot={
        <View className={"flex justify-start flex-row pt-2"}>
          <LocationStatusChip
            status={location.archivedAt ? "Archived" : "Active"}
          />
        </View>
      }
    />
  );
};

const LocationStatusChip = ({ status }: { status: "Archived" | "Active" }) => {
  const variant = status === "Archived" ? "neutral" : "success";

  return <Chip label={status} variant={variant} />;
};
