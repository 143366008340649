import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AggregateMessageDto, ContactDto } from "@justraviga/classmanager-sdk";
import { useDebouncedCallback } from "use-debounce";

import { EmailRecipientChips } from "./EmailRecipientChips";
import { useAlert } from "../../alertState";
import { IconName } from "../../availableIcons";
import { useBreakpoint } from "../../breakpoints";
import { uniqueIds, uniqueRecordsById } from "../../collectionUtils";
import { FormBuilder } from "../../forms/formBuilderTypes";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  EmailFormSchema,
  makeEmailFormDefinition,
  makeSaveEmailRequest,
  makeSendEmailRequest,
} from "../formDefinitions/emailForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { BaseCardProps, ButtonVariant } from "../interfaces";
import { useSendToContact } from "../modules/company/messages/useSendToContact";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";
import { QuickActionButton } from "../ui/QuickActionButton";
import { SaveState } from "../ui/SaveStateIndicator";

type BaseCardComponent = ComponentType<
  Omit<BaseCardProps<unknown>, "headerActions" | "padding">
>;

type ButtonComponent = ComponentType<{
  onClick: () => void;
  size?: "sm" | "lg" | "xs";
  text?: string;
  variant: ButtonVariant;
}>;

type TooltipComponent = ComponentType<{
  trigger: React.ReactNode;
  children?: React.ReactNode;
}>;

export const useEmailForm = ({
  BaseCard,
  Button,
  Tooltip,
  id,
  contactId,
  goBack,
  goToEmailPage,
  useFormBuilder,
  showRecipientsByStudentForm,
  showRecipientsByClassForm,
  showRecipientsBySeasonForm,
}: {
  BaseCard: BaseCardComponent;
  Button: ButtonComponent;
  Tooltip: TooltipComponent;
  id?: string;
  contactId?: string;
  goBack: () => void;
  goToEmailPage: () => void;
  useFormBuilder: FormBuilder;
  showRecipientsByStudentForm: (
    updateRecipients: (newRecipients: ContactDto[]) => void,
  ) => void;
  showRecipientsByClassForm: (
    updateRecipients: (newRecipients: ContactDto[]) => void,
  ) => void;
  showRecipientsBySeasonForm: (
    updateRecipients: (newRecipients: ContactDto[]) => void,
  ) => void;
}) => {
  const [saveState, setSaveState] = useState<SaveState>("none");
  const [message, setMessage] = useState<AggregateMessageDto | null>(null);
  const [recipients, innerSetRecipients] = useState<Array<ContactDto>>([]);
  const { Banner, Text, View } = useGenericComponents();
  const { api } = getPlatformFunctions();
  const alert = useAlert();
  const breakpoint = useBreakpoint();
  const { isLoading: contactIsLoading } = useSendToContact(
    innerSetRecipients,
    contactId,
  );

  const isMessageLoading = id ? !message : false;
  const isContactLoading = contactId ? contactIsLoading : false;
  const isLoading = isMessageLoading || isContactLoading;
  const subjectIsSet = message && message.entity.subject !== "";
  const bodyIsSet = message && message.entity.body !== "";
  const sendIsDisabled =
    recipients.length === 0 || !subjectIsSet || !bodyIsSet || isLoading;

  const handleKeyboardSubmit = useCallback(() => {}, []);
  const defaultValues = useMemo(() => ({}), []);

  const {
    form,
    formHandlers: { handleSubmit, setValue, watch },
    displayApiValidationErrors,
  } = useFormBuilder(
    makeEmailFormDefinition(Banner, View),
    handleKeyboardSubmit,
    defaultValues,
  );

  useEffect(
    () => {
      if (id) {
        api.messages.getMessage({ id }).then(message => {
          setMessage(message);
          setValue("subject", message.entity.subject);
          setValue("body", message.entity.body);
        });

        api.messageInstances
          .listMessageInstance({
            selectAll: true,
            messageId: id,
          })
          .then(({ data }) => {
            if (data.length === 0) {
              return;
            }

            const recipientIds = data.map(instance => instance.entityId);
            api.contacts
              .listContact({
                selectAll: true,
                where: { id: { in: recipientIds } },
              })
              .then(response => {
                innerSetRecipients(response.data);
              });
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  const debouncedSave = useDebouncedCallback(
    (data: Partial<EmailFormSchema>) => {
      save(
        {
          ...data,
          subject: data.subject ?? "",
          body: data.body ?? "",
        },
        false,
      );
    },
    1500,
  );

  /**
   * Callback version of watch.  It's your responsibility to unsubscribe when done.
   * @see https://react-hook-form.com/docs/useform/watch
   */
  useEffect(() => {
    const subscription = watch(value => {
      const { subject: newSubject, body: newBody } = value;
      // We only want to save the email if the user has made changes
      if (!isLoading) {
        if (message) {
          // This is an existing email, only update on changes
          const { subject: oldSubject, body: oldBody } = message.entity;
          if (
            (newSubject !== undefined && newSubject !== oldSubject) ||
            (newBody !== undefined && newBody !== oldBody)
          ) {
            setSaveState("saving");
            debouncedSave(value);
          }
        } else {
          // This is a new email, update on any value
          if (newSubject !== undefined || newBody !== undefined) {
            setSaveState("saving");
            debouncedSave(value);
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [debouncedSave, isLoading, message, watch]);

  const addRecipients = (newRecipients: ContactDto[]) => {
    setRecipients(uniqueRecordsById([...recipients, ...newRecipients]));
  };

  const removeRecipient = (id: string) => {
    setRecipients(recipients.filter(recipient => recipient.id !== id));
  };

  const setRecipients = (updatedRecipients: ContactDto[]) => {
    const recipientIds = uniqueIds(updatedRecipients);
    innerSetRecipients(updatedRecipients);
    save({ recipientIds }, false);
  };

  const save = (data: EmailFormSchema, send: boolean) => {
    setSaveState("saving");
    const request = send
      ? makeSendEmailRequest(api)
      : makeSaveEmailRequest(api);
    return request({
      subject: data.subject ?? message?.entity.subject ?? "",
      body: data.body ?? message?.entity.body ?? "",
      recipientIds: data.recipientIds ?? uniqueIds(recipients),
      attachments: [],
      id: message?.entity.id,
    })
      .then(message => {
        if (message) {
          setMessage(message);
        }
        setSaveState("saved");
      })
      .catch(e => {
        setSaveState("error");
        displayApiValidationErrors(e);
      });
  };

  const saveAsDraft = handleSubmit(data => {
    return save(data, false).then(() => {
      alert.showAlert({
        content: "Email successfully saved",
      });
      goBack();
    });
  });

  const send = handleSubmit(data => {
    return save(data, true).then(() => {
      // reset();
      alert.showAlert({
        content: "Email successfully sent",
      });
      goToEmailPage();
    });
  });

  const recipientSelectionOptions: Array<{
    title: string;
    cardIcon: IconName;
    buttonIcon: IconName;
    action: () => void;
  }> = [
    {
      title: "Add by student",
      cardIcon: "people",
      buttonIcon: "people",
      action: () => showRecipientsByStudentForm(addRecipients),
    },
    {
      title: "Add by class",
      cardIcon: "calendarNumber",
      buttonIcon: "calendarNumberOutline",
      action: () => showRecipientsByClassForm(addRecipients),
    },
    {
      title: t("label.emailRecipients.addBySeason"),
      cardIcon: "calendarClear",
      buttonIcon: "calendarClearOutline",
      action: () => showRecipientsBySeasonForm(addRecipients),
    },
    {
      title: "Send to all",
      cardIcon: "paperPlane",
      buttonIcon: "paperPlaneOutline",
      action: () => {
        api.contacts.listContact({ selectAll: true }).then(response => {
          addRecipients(response.data);
        });
      },
    },
  ];

  const recipientIds = recipients.map(recipient => recipient.id);

  const content = (
    <View>
      <View className="flex flex-row justify-between md:space-x-8">
        {recipientSelectionOptions.map((option, i) => (
          <View key={option.title} className="flex-1">
            {breakpoint.md ? (
              <BaseCard
                onClick={option.action}
                title={option.title}
                titleSize={16}
                titleColor="grey-900"
                icon={option.cardIcon}
                iconPosition="left-framed"
              />
            ) : (
              <QuickActionButton
                onClick={option.action}
                text={option.title}
                icon={option.buttonIcon}
                variant={i === 0 ? "brand" : "default"}
              />
            )}
          </View>
        ))}
      </View>
      <View className="mt-8">
        <View className="flex flex-row items-center justify-between">
          <Text className="pb-5 pt-2 text-body-600 font-semibold text-grey-900">
            Recipients
          </Text>
          <View>
            {recipients.length > 0 && (
              <Button
                text="Clear all"
                variant="tertiary"
                size="xs"
                onClick={() => setRecipients([])}
              />
            )}
          </View>
        </View>
        {recipients.length === 0 ? (
          <ContentPlaceholder
            icon="helpCircleOutline"
            title="No recipients yet"
          />
        ) : (
          <EmailRecipientChips
            recipientIds={recipientIds}
            Button={props => <Button {...props} />}
            Tooltip={Tooltip}
            removeRecipient={removeRecipient}
          />
        )}
      </View>
      <View className="mt-8">
        <Text className="pb-5 pt-2 text-body-600 font-semibold text-grey-900">
          Message
        </Text>
        {form}
      </View>
    </View>
  );

  return {
    message,
    content,
    send,
    sendIsDisabled,
    saveAsDraft,
    isLoading,
    saveState,
  };
};
