import {
  BillingTiming,
  UpdateCompanySettingsRequest,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { range } from "../../../arrayUtils";
import { AuthContextInterface } from "../../../authState";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { numberToOrdinal } from "../../../numberUtils";
import { enumLabel } from "../../../translateUtils";

type FormSchema = Pick<
  Required<UpdateCompanySettingsRequest>["billing"],
  "billingTiming" | "billingDayOfMonth"
> & { saveDefault: boolean };

const dayOfMonth = range(28).map(day => ({
  label: numberToOrdinal(day),
  value: day,
}));

export const useAutomatedTuitionForm = (showSaveDefault = false) => {
  return new FormDefinitionBuilder<FormSchema>()
    .select("billingDayOfMonth", {
      label: "Day of the month",
      description:
        "Tuition will be posted 7 days before, automated payments will be taken on this day.",
      data: dayOfMonth,
      required: true,
    })
    .group("Billing period", ["billingTiming"], {
      description: "Which period would you like the tuition fees to relate to?",
    })
    .bigRadio("billingTiming", {
      cols: 1,
      options: [
        {
          label: enumLabel("billingTiming", BillingTiming.Advance),
          description: enumLabel(
            "billingTimingDescription",
            BillingTiming.Advance,
          ),
          value: BillingTiming.Advance,
          icon: "calendarAdvanceOutline",
        },
        {
          label: enumLabel("billingTiming", BillingTiming.During),
          description: enumLabel(
            "billingTimingDescription",
            BillingTiming.During,
          ),
          value: BillingTiming.During,
          icon: "calendarOutline",
        },
        {
          label: enumLabel("billingTiming", BillingTiming.Arrears),
          description: enumLabel(
            "billingTimingDescription",
            BillingTiming.Arrears,
          ),
          value: BillingTiming.Arrears,
          icon: "calendarArrearsOutline",
        },
      ],
    })
    .conditional(["saveDefault"], [], () => showSaveDefault)
    .switch("saveDefault", {
      label: "Save as default settings",
    })
    .getDefinition();
};

export const makeAutomatedTuitionFormRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  async (data: FormSchema) => {
    return api.companies
      .updateCompanySettings({
        updateCompanySettingsRequest: {
          billing: data,
        },
      })
      .then(company => {
        setAccount({
          ...account,
          company,
        });
        return company;
      });
  };
