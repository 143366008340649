import { useHolidayDatatable } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { useHolidayActions } from "@/modules/company/holidays/useHolidayActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";

const HolidayList = () => {
  const holidayActions = useHolidayActions();
  const { datatable } = useHolidayDatatable({
    Datatable,
    api,
    deleteMany: holidayActions.deleteMany,
    deleteOne: holidayActions.deleteOne,
    showCreateForm: holidayActions.showCreateForm,
    showUpdateForm: holidayActions.showUpdateForm,
  });

  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "schedule:view",
  HolidayList,
  "Holidays",
);

export { PermissionChecked as HolidayList };
