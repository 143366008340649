import { CheckboxValue } from "@shared/components/interfaces/checkbox";

import { cn } from "shared/lib";

import { BaseCheckbox } from "@/modules/common/form/Checkbox";

export interface DatatableCheckboxProps {
  checked: CheckboxValue;
  onChange: (v: CheckboxValue) => void;
  role: "datatable-header-checkbox" | "datatable-row-checkbox";
}

export const DatatableCheckbox = ({
  checked,
  onChange,
  role,
}: DatatableCheckboxProps) => {
  return (
    <label className={cn("relative flex flex-row gap-x-3")}>
      <div
        className={cn(
          "box-content h-4 w-4 cursor-pointer rounded p-2 hover:bg-grey-200",
        )}>
        <label
          className={cn(
            "absolute -left-4 -top-4 cursor-pointer p-6 leading-none",
          )}>
          <BaseCheckbox
            role={role}
            checked={checked}
            onCheckedChange={e => {
              onChange(e);
            }}
          />
        </label>
      </div>
    </label>
  );
};
