import { useEffect } from "react";

import { ContactDto, SortSchema } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";

interface FormSchema {
  seasonIds: Array<string>;
}

const useFormDefinition = () => {
  const { useApi } = getPlatformFunctions();
  const { data: seasons } = useApi("listSeason", {
    sort: { startAt: SortSchema.Desc },
  });
  return new FormDefinitionBuilder<FormSchema>()
    .multiSelect("seasonIds", {
      label: t("label.seasons"),
      required: true,
      data:
        seasons?.data.map(season => ({
          value: season.id,
          label: season.name,
        })) ?? [],
    })
    .getDefinition();
};

const makeRequest = (api: Api) => async (data: FormSchema) => {
  return api.contacts.searchContact({
    where: { seasonId: { in: data.seasonIds } },
  });
};

export const RecipientsBySeasonForm = ({
  onSuccess,
}: {
  onSuccess: (recipients: Array<ContactDto>) => void;
}) => {
  const { GenericForm } = useGenericComponents();
  const { api } = getPlatformFunctions();
  const formActions = useFormActions();

  useEffect(() => {
    formActions.setAllowCreateAdditional(false);
    formActions.setCreateButtonText("Add");
  }, [formActions]);

  return (
    <GenericForm
      apiRequest={makeRequest(api)}
      defaultValues={{}}
      formDefinitionHook={useFormDefinition}
      onSuccess={onSuccess}
    />
  );
};
