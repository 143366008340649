import { FC, ReactNode, useState } from "react";

import { SortSchema } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { useFamilyTransactionsData } from "../../data/useFamilyTransactionsData";
import {
  DatatableAdditionalAction,
  DatatableConfiguration,
  DatatableMenuItem,
  DatatableQueryProps,
} from "../../datatable";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { useStripeStatus } from "../../payments/useStripeStatus";
import { getTransactionAmountColor } from "../../transactions/transactionStyles";
import {
  AggregateTransactionWithPrimaryType,
  containsCreditableItems,
  getTransactionPrimaryType,
  isAutoPayment,
} from "../../transactionUtils";
import { ImplementedTransactionActions } from "../actions/useSharedTransactionActions";
import { TransactionFilterFormSchema } from "../formDefinitions/transactionsFilterForm";
import { AccountBalanceTitle, TransactionSummaryCell } from "../transactions";
import { TransactionTile } from "../transactions/TransactionTile";

type Dto = AggregateTransactionWithPrimaryType & {
  id: string;
  balance: number;
};

type FilterForm = TransactionFilterFormSchema;

export const useAccountStatementDatatable = ({
  familyId,
  Datatable,
  api,
  transactionActions,
  goToFamily,
  isDesktop,
  setSheetTitle,
}: {
  familyId: string;
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, FilterForm, Dto>;
  }>;
  api: Api;
  transactionActions: ImplementedTransactionActions;
  goToFamily: (item: Dto) => void;
  isDesktop: boolean;
  setSheetTitle: (title: string) => void;
}): {
  balance: number;
  datatable: ReactNode;
  additionalActions: {
    title?: string;
    items: DatatableAdditionalAction[];
  };
} => {
  // Make it easy to refresh the data after deletions, etc
  const [modificationCount, setModificationCount] = useState(0);
  const { protector: stripeProtector } = useStripeStatus();
  const { balance, balancesPromise } = useFamilyTransactionsData(familyId);

  const fetchData = async (query: DatatableQueryProps) => {
    const request = {
      ...query.toRequest(),
      where: {
        ...query.toRequest().where,
        familyId: {
          equals: familyId,
        },
      },
      sort: {
        date: SortSchema.Desc,
        createdAt: SortSchema.Desc,
      },
    };
    const { data: transactions, ...rest } =
      await api.transactions.listTransaction(request);

    const balances = await balancesPromise;

    const data = transactions.map(t => ({
      ...t,
      id: t.transaction.id,
      primaryType: getTransactionPrimaryType(t),
      balance: balances[t.transaction.id],
    }));

    return { data, ...rest };
  };

  const config: DatatableConfiguration<Dto, FilterForm, Dto> = {
    id: `account-statement-${familyId}`,
    title: <AccountBalanceTitle balance={balance} />,
    hasPagination: true,
    hasSearch: false,
    showTotalRecords: true,
    placeholdersCount: 4,
    permissions: {
      archive: "financial:manage",
      create: "financial:manage",
      delete: "financial:delete",
      edit: "financial:manage",
      restore: "financial:manage",
    },
    createAction: isDesktop
      ? transactionActions.showCustomTransactionCreateForm
      : undefined,
    additionalActions: {
      items: [
        {
          title: "Tuition & fees",
          items: [
            {
              icon: "eyeOutline",
              title: "Preview tuition",
              onClick: () =>
                transactionActions.showPreviewTuitionForm(familyId),
              permission: "financial:view",
            },
            {
              icon: "schoolOutline",
              title: "Generate unposted tuition",
              onClick: () =>
                transactionActions.showUnpostedTuitionForm(familyId),
              permission: "financial:manage",
            },
          ],
        },
        {
          title: "Billing",
          items: [
            {
              icon: "cashOutline",
              title: "Add custom transaction",
              onClick: () =>
                transactionActions.showCustomTransactionCreateForm(),
              permission: "financial:manage",
            },
            {
              title: "Charge card on file",
              onClick: () =>
                transactionActions.showChargeCardOnFileForm(familyId),
              icon: "cardOutline",
              protector: stripeProtector,
              permission: "financial:manage",
            },
          ],
        },
      ],
    },
    rowActions: {
      title: item => item.transaction.description ?? "",
      click: item => transactionActions.showPreview(item),
      delete: item =>
        transactionActions
          .deleteOne(item)
          .then(() => setModificationCount(modificationCount + 1)),
      additionalRowActions: (item: Dto) => {
        const actions: DatatableMenuItem<Dto>[] = [
          {
            label: "Go to family",
            icon: "openOutline",
            onClick: goToFamily,
            permission: "members:view",
          },
          {
            label: "Transaction details",
            icon: "listOutline",
            onClick: (item: Dto) => transactionActions.showPreview(item),
            permission: "financial:view",
          },
        ];

        if (containsCreditableItems(item)) {
          actions.push({
            title: "Actions",
            actions: [
              {
                label: "Add credit note",
                icon: "addCircleOutline",
                onClick: () =>
                  transactionActions.showCreditNoteForm(item, setSheetTitle),
                permission: "financial:manage",
              },
            ],
          });
        }

        if (isAutoPayment(item)) {
          actions.push({
            title: "Refund",
            actions: [
              {
                label: "Process refund",
                icon: "returnDownBack",
                onClick: () =>
                  transactionActions.showRefundForm(item, setSheetTitle),
                permission: "financial:manage",
              },
            ],
          });
        }
        return actions;
      },
    },
    fetchData,
    columns: [
      {
        label: "Description",
        placeholder: "tile",
        value: row => ({
          type: "custom",
          children: (
            <TransactionTile transaction={row.item} showFamilyName={false} />
          ),
        }),
        width: "35%",
      },
      {
        label: "Debit",
        align: "right",
        placeholder: "text",
        value: row => {
          return {
            type: "text",
            text:
              row.item.transaction.type === "debit"
                ? formatMoneyFromInteger(row.item.stats.total)
                : " ",
            color: getTransactionAmountColor(row.item.primaryType),
          };
        },
      },
      {
        label: "Credit",
        align: "right",
        placeholder: "text",
        value: row => {
          return {
            type: "text",
            text:
              row.item.transaction.type === "credit"
                ? formatMoneyFromInteger(row.item.stats.total)
                : " ",
            color: getTransactionAmountColor(row.item.primaryType),
          };
        },
      },
      {
        label: "Balance",
        placeholder: "text",
        align: "right",
        value: row => ({
          type: "text",
          text: formatMoneyFromInteger(row.item.balance),
        }),
      },
    ],
    mobileColumn: {
      children: row => {
        return (
          <TransactionSummaryCell
            balance={row.item.balance}
            transaction={row.item}
            showPreview={transactionActions.showPreview}
            showFamilyName={false}
          />
        );
      },
    },
    contentPlaceholders: {
      noContent: {
        title: "No transactions yet",
        description:
          "Here you will see a summary of all debits and credits. Debits include money owed to the business and refunds. Credits include money received or owed to a family.",
        icon: "helpCircleOutline",
      },
    },
  };

  return {
    balance,
    datatable: <Datatable configuration={config} />,
    additionalActions: config.additionalActions!,
  };
};
