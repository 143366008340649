import { ReactNode } from "react";

import { ContentPlaceholder } from "./ContentPlaceholder";
import { IconName } from "../../availableIcons";
import { useGenericComponents } from "../GenericComponentsProvider";
import { PlaceholderActionProps } from "../interfaces/contentPlaceholder";

interface Props {
  icon: IconName;
  title: string;
  description?: string | ReactNode;
  createAction: PlaceholderActionProps;
  navigationAction?: PlaceholderActionProps;
}

export const NoItemsContentPlaceholder = ({
  icon,
  title,
  description,
  createAction,
  navigationAction,
}: Props) => {
  const { Button, ProtectedOverlay, View } = useGenericComponents();

  return (
    <ContentPlaceholder
      icon={icon}
      title={title}
      description={description}
      action={
        <View className="flex flex-col justify-center items-center gap-8">
          <View>
            <ProtectedOverlay permission={createAction.permission}>
              <Button
                variant={"brand"}
                size={"sm"}
                onClick={createAction.onClick}
                text={createAction.text}
              />
            </ProtectedOverlay>
          </View>
          {navigationAction && (
            <View>
              <ProtectedOverlay permission={navigationAction.permission}>
                <Button
                  variant={"tertiaryLight"}
                  onClick={navigationAction.onClick}
                  text={navigationAction.text}
                />
              </ProtectedOverlay>
            </View>
          )}
        </View>
      }
    />
  );
};
