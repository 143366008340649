import { FC } from "react";

import {
  CustomFieldEntity,
  CustomFieldResponseDto,
} from "@justraviga/classmanager-sdk";

import { colors } from "../../../../colors";
import { Permission } from "../../../../permission";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { useCustomFieldResponsesActions } from "../../../actions/useCustomFieldResponsesActions";
import { CustomFieldActions } from "../../../actions/useSharedCustomFieldActions";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  customFieldActions: CustomFieldActions;
  entityId: string;
  defaultEntity: CustomFieldEntity;
  CustomFieldResponsesContent: FC<{ fieldResponses: CustomFieldResponseDto[] }>;
  managePermission: Permission;
}

export const CustomFieldResponsesCard = ({
  customFieldActions,
  entityId,
  defaultEntity,
  CustomFieldResponsesContent,
  managePermission,
}: Props) => {
  const { BaseCard, Icon, Text, View } = useGenericComponents();
  const { useData } = getPlatformFunctions();
  const { showUpdateForm } = useCustomFieldResponsesActions();

  const data = useData("getCustomFieldResponses", {
    entityId,
  });

  const customFieldResponses = data?.fields || [];

  const nothingToShow = customFieldResponses.length === 0;

  return (
    <BaseCard
      title={"Custom Fields"}
      collapsible={true}
      headerAction={
        nothingToShow
          ? undefined
          : {
              text: "Edit",
              onClick: () => showUpdateForm(entityId, customFieldResponses),
              permission: managePermission,
            }
      }
      permission="members:view"
      bodySlot={
        nothingToShow ? (
          <View className={"flex flex-row items-center space-x-2 py-2"}>
            <Icon
              name={"helpCircleOutline"}
              size={20}
              color={colors.grey[900]}
            />
            <Text className={"text-body-400 text-grey-900"}>
              No custom fields
            </Text>
          </View>
        ) : (
          <CustomFieldResponsesContent fieldResponses={customFieldResponses} />
        )
      }
      footerAction={{
        text: "Create new",
        onClick: () =>
          customFieldActions.showCreateForm({ entity: defaultEntity }),
        permission: managePermission,
      }}
    />
  );
};
