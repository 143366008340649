import { Breadcrumb, useSharedContactActions } from "shared/components";
import { getFullName } from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { NoContentPlaceholder } from "@/modules/common/datatable/content/NoContentPlaceholder";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
} from "@/modules/company/common/DetailPageLayout";
import {
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { ContactCard } from "@/modules/company/members/contact/ContactCard";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const ContactsList = ({ id: familyId }: { id: string }) => {
  const contactActions = useSharedContactActions();
  const breakpoint = useBreakpoint();

  const { data: contactsResponse } = useApi("listContact", {
    where: { familyId: { equals: familyId } },
  });

  const { data: family } = useApi("getFamily", {
    id: familyId,
  });

  const primaryContact = contactsResponse?.data.find(c => c.isPrimary);
  const nonPrimaryContacts = contactsResponse?.data
    .filter(c => !c.isPrimary)
    .sort((a, b) => getFullName(a).localeCompare(getFullName(b)));

  const crumbs: Breadcrumb[] = [
    {
      text: "Families",
      onClick: () => {
        Router.push("FamilyList");
      },
    },
    {
      text: family?.name ?? "",
      onClick: () => {
        Router.push("FamilyDetails", { id: familyId });
      },
    },
    {
      text: "Contacts",
    },
  ];
  return (
    <CompanyLayout>
      <CompanyBreadCrumbs crumbs={crumbs} />
      <CompanyTitleBar isTopLevel={false}>
        <CompanyTitleBarTitle title={"Contacts"} />
        <div>
          <ProtectedOverlay permission={"members:manage"}>
            {!breakpoint.md ? (
              <IconButton
                icon={"addOutline"}
                border={"rounded"}
                onClick={() => contactActions.showCreateForm(familyId)}
                variant={"secondary-fill"}
              />
            ) : (
              <Button
                variant={"brand"}
                text={"Create"}
                onClick={() => {
                  contactActions.showCreateForm(familyId);
                }}
              />
            )}
          </ProtectedOverlay>
        </div>
      </CompanyTitleBar>
      <CompanyContent>
        {!primaryContact && nonPrimaryContacts?.length === 0 ? (
          <NoContentPlaceholder />
        ) : (
          <DetailPageGrid cols={2}>
            <DetailPageGridLeftColumn>
              {primaryContact && <ContactCard contact={primaryContact} />}
            </DetailPageGridLeftColumn>
            <DetailPageGridRightColumn>
              {nonPrimaryContacts?.map((contact, index) => {
                return <ContactCard key={index} contact={contact} />;
              })}
            </DetailPageGridRightColumn>
          </DetailPageGrid>
        )}
      </CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "members:view",
  ContactsList,
  "Family contacts",
);

export { PermissionChecked as ContactsList };
