import { useEffect, useState } from "react";

import { SeasonDto, SortSchema } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../../platformSpecific";
import { generateMonthOptions } from "../../../transactionUtils";
import { SelectItem } from "../../interfaces";

export const usePreviewTuitionBillData = () => {
  const { useApi } = getPlatformFunctions();
  const [billableMonths, setBillableMonths] = useState<SelectItem[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<SeasonDto | undefined>(
    undefined,
  );
  const { data: seasons } = useApi("listSeason", {
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  });

  useEffect(() => {
    if (selectedSeason) {
      setBillableMonths(generateMonthOptions(selectedSeason));
    }
  }, [selectedSeason]);

  return {
    billableMonths,
    seasons,
    selectedSeason,
    setSelectedSeason,
    setBillableMonths,
  };
};
