import { ReactNode } from "react";

import type { BaseHit, Hit } from "instantsearch.js";
import { Hits } from "react-instantsearch";
import { Index } from "react-instantsearch-core";
import { match } from "ts-pattern";

import {
  getSearchCollectionTitle,
  SearchCollection,
  useSearchResultCount,
} from "shared/lib";

import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { NoResultsPlaceholder } from "@/modules/common/search/NoResultsPlaceholder";
import { CourseSearchResult } from "@/modules/common/search/searchResults/CourseSearchResult";
import { FamilySearchResult } from "@/modules/common/search/searchResults/FamilySearchResult";
import { SearchResultTitle } from "@/modules/common/search/searchResults/SearchResultsTitle";
import { SeasonSearchResult } from "@/modules/common/search/searchResults/SeasonSearchResult";
import { StaffSearchResult } from "@/modules/common/search/searchResults/StaffSearchResult";
import { StudentSearchResult } from "@/modules/common/search/searchResults/StudentSearchResult";
import { TypeToSearchPlaceholder } from "@/modules/common/search/TypeToSearchPlaceholder";

interface SearchGroupListProps<T extends BaseHit> {
  indexName: SearchCollection;
  hitComponent: ({ hit }: { hit: Hit<T> }) => ReactNode;
}

export const SearchGroupList = <T extends BaseHit>({
  indexName,
  hitComponent,
}: SearchGroupListProps<T>) => {
  const { groups } = useSearchResultCount();

  /** Index component needs to remain in the dom for search to correctly query the named index. */
  return (
    <Index indexName={indexName}>
      {groups[indexName].totalCount === 0 ? null : (
        <div data-testid={`${indexName}-results`}>
          <SearchResultTitle
            title={getSearchCollectionTitle(t, indexName)}
            count={groups[indexName].totalCount}
          />
          <Hits hitComponent={hitComponent} />
        </div>
      )}
    </Index>
  );
};

export const SearchResultsPlaceholder = () => {
  const { status, searchStatus, total } = useSearchResultCount();

  return match({ status, searchStatus, total })
    .with({ searchStatus: "loading", total: 0 }, () => <LoadingPlaceholder />)
    .with({ status: "awaiting-input" }, () => <TypeToSearchPlaceholder />)
    .with({ status: "empty-results" }, () => <NoResultsPlaceholder />)
    .otherwise(() => null);
};

export const SearchResults = () => (
  <div
    className={"min-h-full overflow-y-auto"}
    data-testid="search-results-list-container">
    <SearchGroupList
      indexName={SearchCollection.family}
      hitComponent={FamilySearchResult}
    />
    <SearchGroupList
      indexName={SearchCollection.student}
      hitComponent={StudentSearchResult}
    />
    <SearchGroupList
      indexName={SearchCollection.staff}
      hitComponent={StaffSearchResult}
    />
    <SearchGroupList
      indexName={SearchCollection.course}
      hitComponent={CourseSearchResult}
    />
    <SearchGroupList
      indexName={SearchCollection.season}
      hitComponent={SeasonSearchResult}
    />
    <SearchResultsPlaceholder />
  </div>
);
