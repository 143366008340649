import { LocationDto } from "@justraviga/classmanager-sdk";

import {
  Breadcrumb,
  RoomListCard,
  useLocationActions,
} from "shared/components";
import { useBreakpoint, useLocationDetails } from "shared/lib";

import {
  ActionMenuItemProps,
  ActionMenuProps,
} from "@/modules/common/ui/ActionMenu";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { AddressCard } from "@/modules/company/addresses/AddressCard";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const LocationDetailsPage = ({ id }: { id: LocationDto["id"] }) => {
  const breakpoints = useBreakpoint();
  const locationActions = useLocationActions();
  const { isLoading, location, rooms } = useLocationDetails(id);

  if (isLoading) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  const editAction: ActionMenuItemProps = {
    title: "Edit",
    onClick: () => {
      locationActions.showUpdateForm(location);
    },
    permission: "schedule:manage",
  };

  const actions: ActionMenuProps["items"] = [
    ...(breakpoints.md ? [] : [editAction]),
    {
      title: location.archivedAt ? "Restore" : "Archive",
      onClick: () => {
        location.archivedAt
          ? locationActions.unarchive(location).then()
          : locationActions.archive(location).then();
      },
      permission: "schedule:manage",
    },
    {
      title: "Delete",
      destructive: true,
      onClick: () => {
        locationActions.deleteOne(location).then(() => {
          Router.push("LocationList");
        });
      },
      permission: "schedule:manage",
    },
  ];

  const crumbs: Breadcrumb[] = [
    {
      text: "Locations",
      onClick: () => {
        Router.push("LocationList");
      },
    },
    {
      text: location.name,
    },
  ];

  const Header = () => (
    <div>
      <p className={"line-clamp-1 text-heading5-600"}>{location.name}</p>
    </div>
  );

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        isDesktop={breakpoints.md}
        archivedEntityName={"location"}
        permissions={{
          edit: "schedule:manage",
          restore: "schedule:manage",
        }}
        restoreAction={() => locationActions.unarchive(location).then()}
        editAction={() => locationActions.showUpdateForm(location)}
        actions={actions}
        crumbs={crumbs}
        isArchived={!!location.archivedAt}
        header={<Header />}>
        <DetailPageGrid>
          <DetailPageGridLeftColumn>
            <RoomListCard locationId={location.id} rooms={rooms.data} />
          </DetailPageGridLeftColumn>
          <DetailPageGridRightColumn>
            <AddressCard
              entityId={location.id}
              updatePermission="schedule:manage"
            />
          </DetailPageGridRightColumn>
        </DetailPageGrid>
      </DetailPageLayout>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "schedule:view",
  LocationDetailsPage,
  "Location details",
);

export { PermissionChecked as LocationDetailsPage };
