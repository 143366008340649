import {
  EnrolmentStatusSeasonRequestEnrolmentStatusEnum,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";

type ImplementedActions = {
  enableSeasonRegistration: (seasonId: string) => Promise<SeasonDto | void>;
  disableSeasonRegistration: (seasonId: string) => Promise<SeasonDto | void>;
};

export const useSeasonRegistrationActions = (): ImplementedActions => {
  const { api } = getPlatformFunctions();

  return {
    enableSeasonRegistration: (seasonId: string) =>
      api.seasons.enrolmentStatusSeason({
        id: seasonId,
        enrolmentStatusSeasonRequest: {
          enrolmentStatus: EnrolmentStatusSeasonRequestEnrolmentStatusEnum.Open,
        },
      }),

    disableSeasonRegistration: (seasonId: string) =>
      api.seasons.enrolmentStatusSeason({
        id: seasonId,
        enrolmentStatusSeasonRequest: {
          enrolmentStatus:
            EnrolmentStatusSeasonRequestEnrolmentStatusEnum.Closed,
        },
      }),
  };
};
