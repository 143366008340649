import { useEnrolmentsActions } from "@shared/components/actions/useEnrolmentActions";
import { useWaitingListActions } from "@shared/components/actions/useWaitingListActions";
import { useWaitingListDatatable } from "@shared/components/datatables/useWaitingListDatatable";
import { formatWeekDayType } from "@shared/dateUtils";
import { formatTime } from "@shared/intlFormatter";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const WaitingListPage = () => {
  const { showCreateForm, deleteOne } = useWaitingListActions();
  const { showCreateForm: showEnrolmentForm } = useEnrolmentsActions();

  const { datatable, getMobileSubtitle, getRequestedDate } =
    useWaitingListDatatable({
      Datatable,
      api,
      useApi,
      deleteOne,
      goToStudent: id => Router.push("StudentDetails", { id }),
      enrollForClass: item => {
        showEnrolmentForm({
          course: item.class,
          season: item.season,
          studentIds: [item.studentId],
          showStudentSelector: false,
        });
      },
      showCreateForm,
      renderMobileDetails: item => {
        return (
          <div className="flex flex-col gap-1 text-label-400 text-grey-600">
            <span className="truncate">{item.class.entity.name}</span>
            <span className="truncate">{getMobileSubtitle(item)}</span>
            <div className="flex">
              <Chip
                label={getRequestedDate(item.createdAt)}
                variant="neutral"
              />
            </div>
          </div>
        );
      },
      renderClassDetails: item => (
        <div className="flex w-full flex-col gap-1">
          <Tooltip
            trigger={
              <div className="truncate text-body-400 text-grey-900">
                {`${item.class.entity.name}`}
              </div>
            }
            children={`${item.class.entity.name}`}
          />

          <div className="s line-clamp-1 text-label-400 capitalize text-grey-600">
            {[
              formatWeekDayType(item.class.entity.dayOfWeek),
              formatTime(item.class.entity.startTime, "hourMinute"),
              item.season.name,
            ].join(" • ")}
          </div>
        </div>
      ),
    });

  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "enrolments:view",
  WaitingListPage,
  "Waiting list",
);

export { PermissionChecked as WaitingListPage };
