import { Permission } from "@shared/permission";

import { usePermission } from "shared/lib";

import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export const AvatarWithUpload = ({
  src,
  name,
  showUploadIcon,
  uploadAction,
  permission,
}: {
  src: string | null;
  name: string;
  showUploadIcon: boolean;
  uploadAction: () => void;
  permission: Permission;
}) => {
  const { hasPermission } = usePermission();

  const canUpload = permission === undefined || hasPermission(permission);

  return (
    <div className="relative">
      <button
        className={"cursor-pointer"}
        onClick={canUpload ? uploadAction : undefined}>
        <Avatar src={src ?? undefined} size="xl" name={name} />
      </button>
      {showUploadIcon && (
        <div className="absolute bottom-0 right-0">
          <ProtectedOverlay permission={permission} shape="rounded-full">
            <IconButton
              icon="cameraOutline"
              size="xs"
              border="rounded"
              onClick={uploadAction}
            />
          </ProtectedOverlay>
        </div>
      )}
    </div>
  );
};
