import { RoomDto } from "@justraviga/classmanager-sdk";

import { RoomCard } from "./RoomCard";
import { useRoomActions } from "../../../../actions/useRoomActions";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const RoomListCard = ({
  rooms,
  locationId,
}: {
  rooms: RoomDto[] | undefined;
  locationId: string;
}) => {
  const { showCreateForm } = useRoomActions();
  const { BaseCard, Icon, Text, View } = useGenericComponents();

  return (
    <BaseCard
      title={"Rooms"}
      collapsible={true}
      headerAction={{
        text: "Create new",
        onClick: () => showCreateForm({ locationId }),
        permission: "schedule:manage",
      }}
      bodySlot={
        (rooms ?? []).length === 0 ? (
          <View className="flex flex-row items-center space-x-2 py-2">
            <Icon name="helpCircleOutline" />
            <Text className="text-body-400">Nothing here!</Text>
          </View>
        ) : (
          <View className="space-y-4 pt-2">
            {rooms?.map((room, index) => (
              <View key={index}>
                <RoomCard room={room} />
              </View>
            ))}
          </View>
        )
      }
    />
  );
};
