import {
  AccountPermissionSet,
  AccountRole,
  BillingTiming,
  CompanyIndustry,
  CompanySize,
  ContactRelation,
  CountryCode,
  Currency,
  CustomFieldEntity,
  CustomFieldType,
  DiscountSchemeType,
  DiscountType,
  EntityName,
  InvitationStatus,
  Locale,
  PricingModel,
  StaffPermissionSet,
  StudentGender,
  TransactionDetailType,
  TransactionType,
  WeekDay,
} from "@justraviga/classmanager-sdk";
import { PricingScheme } from "@justraviga/classmanager-sdk/dist/models/PricingScheme";

import { TFunction } from "./translation/i18n";

export enum StudentStatus {
  Active = "active",
  Archived = "archived",
}

const enumLabels = {
  accountRole: {
    [AccountRole.Admin]: "Admin",
    [AccountRole.Family]: "Family",
    [AccountRole.Staff]: "Staff",
  },
  accountPermissionSet: {
    [AccountPermissionSet.Admin]: "Admin",
    [AccountPermissionSet.Family]: "Family",
    [AccountPermissionSet.Owner]: "Owner",
    [AccountPermissionSet.Manager]: "Manager",
    [AccountPermissionSet.Instructor]: "Instructor",
    [AccountPermissionSet.Assistant]: "Assistant",
    [AccountPermissionSet.None]: "None",
  },
  billingTiming: {
    [BillingTiming.Advance]: "In advance",
    [BillingTiming.Arrears]: "In arrears",
    [BillingTiming.During]: "For current month",
  },
  billingTimingDescription: {
    [BillingTiming.Advance]:
      "For example, charge in August for September tuition.",
    [BillingTiming.Arrears]:
      "For example, charge in September for August tuition.",
    [BillingTiming.During]: "For example, charge in August for August tuition.",
  },
  permissionSet: {
    [StaffPermissionSet.Owner]: "Owner",
    [StaffPermissionSet.Assistant]: "Financial Assistant",
    [StaffPermissionSet.Manager]: "Office Manager",
    [StaffPermissionSet.Instructor]: "Instructor",
  },
  invitationStatus: {
    [InvitationStatus.NotInvited]: "Not Invited",
    [InvitationStatus.Invited]: "Invited",
    [InvitationStatus.Accepted]: "Active",
  },
  studentGender: {
    [StudentGender.Male]: "Male",
    [StudentGender.Female]: "Female",
    [StudentGender.NonBinary]: "Non-binary",
    [StudentGender.PreferNotToSay]: "Prefer not to say",
    [StudentGender.Other]: "Other",
  },
  studentStatus: {
    [StudentStatus.Active]: "Active",
    [StudentStatus.Archived]: "Archived",
  },
  companySize: {
    [CompanySize.NotStartedYet]: "Not started yet",
    [CompanySize._150]: "1-50",
    [CompanySize._51100]: "51-100",
    [CompanySize._101250]: "101-250",
    [CompanySize._251500]: "251-500",
    [CompanySize.MoreThan500]: "more than 500",
  },
  contactRelation: {
    [ContactRelation.Parent]: "Parent",
    [ContactRelation.Grandparent]: "Grandparent",
    [ContactRelation.Guardian]: "Guardian",
    [ContactRelation.Sibling]: "Sibling",
    [ContactRelation.Other]: "Other",
  },
  countryCode: {
    [CountryCode.Au]: "Australia",
    [CountryCode.Ca]: "Canada",
    [CountryCode.Ei]: "Ireland",
    [CountryCode.Gb]: "United Kingdom",
    [CountryCode.Nz]: "New Zealand",
    [CountryCode.Us]: "United States",
  },
  customFieldEntities: {
    [CustomFieldEntity.Staff]: "Staff",
    [CustomFieldEntity.Student]: "Students",
    [CustomFieldEntity.Family]: "Families",
  },
  customFieldTypes: {
    [CustomFieldType.Date]: "Date",
    [CustomFieldType.Number]: "Number",
    [CustomFieldType.Text]: "Short Text",
    [CustomFieldType.Textarea]: "Long Text",
    [CustomFieldType.Select]: "Select One",
    [CustomFieldType.Multiselect]: "Select Multiple",
    [CustomFieldType.Bool]: "Checkbox",
  },
  currencies: {
    [Currency.Aud]: "AUD $",
    [Currency.Cad]: "CAD $",
    [Currency.Eur]: "EUR €",
    [Currency.Gbp]: "GBP £",
    [Currency.Nzd]: "NZD $",
    [Currency.Usd]: "USD $",
  },
  locales: {
    [Locale.EnAu]: "English (Australia)",
    [Locale.EnCa]: "English (Canada)",
    [Locale.EnGb]: "English (UK)",
    [Locale.EnIe]: "English (Ireland)",
    [Locale.EnNz]: "English (New Zealand)",
    [Locale.EnUs]: "English (US)",
    [Locale.EnAu2]: "Old: English (Australia 2)",
    [Locale.EnCa2]: "Old: English (Canada 2)",
    [Locale.EnNz2]: "Old: English (New Zealand 2)",
  },
  weekDays: {
    [WeekDay.Monday]: "Monday",
    [WeekDay.Tuesday]: "Tuesday",
    [WeekDay.Wednesday]: "Wednesday",
    [WeekDay.Thursday]: "Thursday",
    [WeekDay.Friday]: "Friday",
    [WeekDay.Saturday]: "Saturday",
    [WeekDay.Sunday]: "Sunday",
  },
  weekDaysShort: {
    [WeekDay.Monday]: "Mon",
    [WeekDay.Tuesday]: "Tue",
    [WeekDay.Wednesday]: "Wed",
    [WeekDay.Thursday]: "Thu",
    [WeekDay.Friday]: "Fri",
    [WeekDay.Saturday]: "Sat",
    [WeekDay.Sunday]: "Sun",
  },
  pricingScheme: {
    [PricingScheme.None]: "Price per class per month",
    [PricingScheme.ClassCount]: "Number of classes",
    [PricingScheme.TotalDuration]: "Number of hours",
  },
  industry: {
    [CompanyIndustry.Dance]: "Dance",
    [CompanyIndustry.Cheer]: "Cheer",
    [CompanyIndustry.Gymnastics]: "Gymnastics",
    [CompanyIndustry.PerformingArts]: "Performing Arts",
    [CompanyIndustry.Swimming]: "Swimming",
    [CompanyIndustry.Tennis]: "Tennis",
    [CompanyIndustry.MartialArts]: "Martial Arts",
    [CompanyIndustry.Trampolining]: "Trampolining",
    [CompanyIndustry.Other]: "Other",
  },
  discountSchemeType: {
    [DiscountSchemeType.StudentQtyByFamily]: "Multiple student discount",
    [DiscountSchemeType.CourseQtyByStudent]: "Class discount by student",
    [DiscountSchemeType.CourseQtyByFamily]: "Class discount by family",
  },
  discountType: {
    [DiscountType.Percent]: "Percentage off",
    [DiscountType.Fixed]: "Fixed amount off",
  },
  transactionType: {
    [TransactionType.Credit]: "Credit",
    [TransactionType.Debit]: "Debit",
  },
  transactionDetailType: {
    [TransactionDetailType.PaymentAuto]: "Payment",
    [TransactionDetailType.PaymentManual]: "Payment",
    [TransactionDetailType.Tax]: "Tax",
    [TransactionDetailType.RegistrationFee]: "Registration fee",
    [TransactionDetailType.Tuition]: "Tuition fees",
    [TransactionDetailType.Discount]: "Discount",
    [TransactionDetailType.PaymentAutoFailed]: "Payment",
    [TransactionDetailType.PaymentAutoFailedReversal]: "Payment",
    [TransactionDetailType.Refund]: "Refund",
    [TransactionDetailType.CreditNote]: "Credit note",
    [TransactionDetailType.FeeClassManager]: "Fee",
    [TransactionDetailType.FeeCompany]: "Fee",
    [TransactionDetailType.ManualDebit]: "Manual debit",
  },
  pricingModel: {
    [PricingModel.Lesson]: "per lesson",
    [PricingModel.Month]: "per month",
    [PricingModel.Season]: "per season",
  },
  pricingModelWithoutPrefix: {
    [PricingModel.Lesson]: "lesson",
    [PricingModel.Month]: "month",
    [PricingModel.Season]: "season",
  },
};

export const enumLabel = <T extends keyof typeof enumLabels>(
  type: T,
  enumValue: keyof (typeof enumLabels)[T] | null | undefined,
  defaultValue: string = "",
) => {
  return enumValue ? enumLabels[type][enumValue] : defaultValue;
};

export const customFieldEntityLabel = (entity: CustomFieldEntity | string) => {
  switch (entity) {
    case CustomFieldEntity.Staff:
      return enumLabel("customFieldEntities", CustomFieldEntity.Staff);
    case CustomFieldEntity.Student:
      return enumLabel("customFieldEntities", CustomFieldEntity.Student);
    case CustomFieldEntity.Family:
      return enumLabel("customFieldEntities", CustomFieldEntity.Family);
  }
};

export const customFieldTypeLabel = (type: CustomFieldType | string) => {
  switch (type) {
    case CustomFieldType.Text:
      return enumLabel("customFieldTypes", CustomFieldType.Text);
    case CustomFieldType.Textarea:
      return enumLabel("customFieldTypes", CustomFieldType.Textarea);
    case CustomFieldType.Number:
      return enumLabel("customFieldTypes", CustomFieldType.Number);
    case CustomFieldType.Date:
      return enumLabel("customFieldTypes", CustomFieldType.Date);
    case CustomFieldType.Bool:
      return enumLabel("customFieldTypes", CustomFieldType.Bool);
    case CustomFieldType.Select:
      return enumLabel("customFieldTypes", CustomFieldType.Select);
    case CustomFieldType.Multiselect:
      return enumLabel("customFieldTypes", CustomFieldType.Multiselect);
    default:
      return "Unknown";
  }
};

/**
 * @deprecated - use getEntityTranslations or the t function directly, as these are not locale-aware
 */
export const entityTranslations: Record<EntityName, [string, string]> = {
  activityLog: ["Activity log", "Activity logs"],
  account: ["Account", "Accounts"],
  address: ["Address", "Addresses"],
  agreement: ["Agreement", "Agreements"],
  attendance: ["Attendance", "Attendances"],
  company: ["Company", "Companies"],
  companyBillingSettings: ["Settings", "Settings"],
  companyEnrolmentSettings: ["Settings", "Settings"],
  companyIntegrationQuickbooksSetting: ["Settings", "Settings"],
  companyIntegrationStripeSettings: ["Settings", "Settings"],
  companySettings: ["Settings", "Settings"],
  companyTaxSettings: ["Settings", "Settings"],
  companyTrialSettings: ["Settings", "Settings"],
  contact: ["Contact", "Contacts"],
  course: ["Class", "Classes"],
  classSetting: ["Class setting", "Class settings"],
  customField: ["Custom field", "Custom fields"],
  customFieldResponse: ["Custom field response", "Custom field responses"],
  datePeriod: ["Date period", "Date periods"],
  discountScheme: ["Discount scheme", "Discount schemes"],
  discountSchemeValue: ["Discount scheme value", "Discount scheme values"],
  enrolment: ["Enrolment", "Enrolments"],
  enrolmentAdjustment: ["Enrolment Adjustment", "Enrolment Adjustments"],
  waitingList: ["Waiting list", "Waiting lists"],
  family: ["Family", "Families"],
  feature: ["Feature", "Features"],
  fee: ["Fee", "Fees"],
  file: ["File", "Files"],
  holiday: ["Holiday", "Holidays"],
  lessonAdjustment: ["Lesson adjustment", "Lesson adjustments"],
  link: ["Link", "Links"],
  location: ["Location", "Locations"],
  makeUpLesson: ["Make up lesson", "Make up lessons"],
  message: ["Message", "Messages"],
  messageInstance: ["Message instance", "Message instances"],
  paymentLink: ["Payment link", "Payment links"],
  phone: ["Phone", "Phones"],
  pricingSchemeValue: ["Pricing scheme value", "Pricing scheme values"],
  quickbooksEntity: ["Quickbooks entity", "Quickbooks entities"],
  registrationFee: ["Registration fee", "Registration fees"],
  room: ["Room", "Rooms"],
  season: ["Season", "Seasons"],
  staff: ["Staff member", "Staff members"],
  student: ["Student", "Students"],
  taxRate: ["Tax rate", "Tax rates"],
  transaction: ["Transaction", "Transactions"],
  transactionDetail: ["Transaction detail", "Transaction details"],
  transactionDetailRelatedEntity: ["Related entity", "Related entities"],
  trial: ["Trial", "Trials"],
  user: ["User", "Users"],
  waiver: ["Waiver", "Waivers"],
};

/**
 * Because our current entityTranslations constant is not locale-aware, this function provides a backwards-compatible
 * alternative.
 */
export const getEntityTranslations = (
  t: TFunction,
): Record<EntityName, [string, string]> => ({
  ...entityTranslations,
  season: [
    t("entity.season", { count: 1 }),
    t("entity.season", { count: Infinity }),
  ],
});
