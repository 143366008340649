import React from "react";

import RemoveMarkdown from "remove-markdown";

export const containsNumber = (
  v: null | undefined | string | number = "",
): boolean => v !== undefined && v !== null && /\d/.test(String(v));

/**
 * Returns true if the two strings are equal, ignoring case.
 *
 * @param str1
 * @param str2
 */
export function isEqualIgnoreCase(
  str1: string | number,
  str2: string | number,
): boolean {
  if (typeof str1 === "number" && typeof str2 === "number") {
    return str1 === str2;
  }
  return (
    String(str1).trim().toLowerCase() === String(str2).trim().toLowerCase()
  );
}

/**
 * Returns a string representation of the given props object.
 *
 * @param props
 * @param joinGlue
 * @param valueGlue
 * @param isNested
 */
export function propsToString(
  props: object,
  joinGlue: string = " ",
  valueGlue: string = "=",
  isNested: boolean = false,
): string {
  type PossibleValue =
    | string
    | number
    | boolean
    | null
    | undefined
    | object
    | ((...args: unknown[]) => unknown)
    | Array<PossibleValue>;
  const encodeValue = (value: PossibleValue): string => {
    if (value === null) {
      return `null`;
    }
    if (value === undefined) {
      return `undefined`;
    }
    if (typeof value === "string") {
      return `"${value}"`;
    }
    if (typeof value === "number") {
      return String(value);
    }
    if (typeof value === "boolean") {
      return value ? "true" : "false";
    }
    if (Array.isArray(value)) {
      return `[${value.map(v => encodeValue(v)).join(", ")}]`;
    }
    if (typeof value === "function") {
      return isNested ? value.toString() : `${value.toString()}`;
    }

    if (React.isValidElement(value)) {
      return String(value);
    }

    if (typeof value === "object") {
      const withEncodedValues = Object.entries(value).map(([key, v]) => {
        return `${key}: ${encodeValue(v)}`;
      });
      return `{${withEncodedValues.join(", ")}}`;
    }

    return String(value);
  };

  return Object.entries(props)
    .map(([key, value]) => {
      return `${key}${valueGlue}{${encodeValue(value)}}`;
    })
    .join(joinGlue);
}

export const removeDomainFromURL = (url: string) => {
  // Don't manipulate URLs that don't contain a domain
  if (!url.includes("//")) {
    return url;
  }
  // Also, URLs without any path after the domain should be treated as empty
  if (!/[^/]\/[^/]/.test(url)) {
    return "";
  }
  // Find the position where the domain ends
  const domainEndIndex = url.indexOf("/", url.indexOf("//") + 2);

  // If the domain is found, return everything after it, otherwise return the original URL
  return domainEndIndex !== -1 ? url.substring(domainEndIndex) : url;
};

export const toFormattedDecimalString = (
  value: null | undefined | string | number = "",
  decimalPlaces: number = 2,
): string => {
  if (!containsNumber(value)) {
    return "";
  }
  const withMaxOnePoint = toSanitizedDecimalString(value);

  // Split the string into its parts
  const [integer = "0", fraction = "0".repeat(decimalPlaces)] =
    withMaxOnePoint.split(".");

  // Reduce the number of decimal places if necessary
  const newFraction = fraction.slice(0, decimalPlaces);

  // Pad the integer if necessary
  const newIntegerPadded = integer.padStart(1, "0");

  // Pad the fraction if necessary
  const newFractionPadded = newFraction.padEnd(decimalPlaces, "0");

  return `${newIntegerPadded}.${newFractionPadded}`;
};

export const toIntegerString = (
  value: null | undefined | string | number = "",
): string => {
  const asString = value === undefined || value === null ? "" : String(value);
  const sanitized = asString.replace(/[^0-9.]/g, "");
  const [integer] = sanitized.split(".");
  return integer;
};

export const toSanitizedDecimalString = (
  value: null | undefined | string | number = "",
): string => {
  if (!containsNumber(value)) {
    return "";
  }
  const asString = String(value).trim();

  // Remove all characters except numbers and decimal points
  const sanitized = asString.replace(/[^0-9.]/g, "");

  // Remove all but the first decimal point
  const [first, ...rest] = sanitized.split(".");
  const noPoints = rest.join("").replace(".", "");

  return `${first}${rest.length ? "." : ""}${noPoints}`;
};

export const urlToCacheKey = (url: string) => {
  try {
    const urlObj = new URL(url);
    const segments = urlObj.pathname.split("/");
    // Throw away the first segment, which is the API version
    const relevantSegments = segments.slice(2);
    const search = urlObj.search;
    return [...relevantSegments, search].filter(s => s !== "");
  } catch (_) {
    return [];
  }
};

/**
 * Truncates a string to a maximum length, adding an ellipsis if it's truncated.
 *
 * @param str
 * @param maxLength
 * @param ellipsis
 */
export function truncateString(
  str: string,
  maxLength: number,
  ellipsis: string = "...",
): string {
  if (str.length <= maxLength) {
    return str;
  }

  return str.slice(0, maxLength - ellipsis.length) + ellipsis;
}

export function camelToSnake(camelCaseStr: string): string {
  return camelCaseStr.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
}

// The default export name provided by @types/remove-markdown doesn't follow our naming conventions, so we'll rename it
export const removeMarkdown = RemoveMarkdown;

export const isString = (value: unknown): value is string =>
  typeof value === "string";

/**
 * Converts the first letter of a string to uppercase.
 */
export const ucFirst = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);
