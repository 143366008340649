import { useState } from "react";

import {
  CreateSeasonRequest,
  PricingScheme,
  UpdateSeasonRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { colors } from "../../colors";
import { makeTaxRateSelectOptions } from "../../data/makeTaxRateSelectOptions";
import { useTaxRates } from "../../data/useTaxRates";
import { DatePickerValue } from "../../forms/formComponentProps";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";
import { useSettings } from "../useSettings";

type UpdateSchema = UpdateSeasonRequest;
type CreateSchema = CreateSeasonRequest;

export const useSeasonFormDefinition = () => {
  const [startAt, setStartAt] = useState<DatePickerValue>();
  const [endAt, setEndAt] = useState<DatePickerValue>();
  const { enabled: taxEnabled } = useSettings("tax");
  const { taxRates } = useTaxRates();

  const builder = new FormDefinitionBuilder<CreateSeasonRequest>()
    .group("Basic Information", ["name", "startAt", "endAt"] as Array<
      keyof CreateSeasonRequest
    >)
    .text("name", {
      label: "Name",
      required: true,
      description: "Recommended maximum length: 20 characters",
    })
    .date("startAt", {
      label: "Start date",
      required: true,
      maxDate: endAt,
    })
    .date("endAt", {
      label: "End date",
      required: true,
      minDate: startAt,
    })
    .group("Registration", [
      "registrationOpen",
      "registrationFee",
      "maxRegistrationFee",
    ])
    .money("registrationFee", {
      label: "Registration fee per student",
      keepZeroValue: true,
      description:
        "Each time a new student registers for this season, a fixed fee will be applied.",
    })
    .money("maxRegistrationFee", {
      label: "Maximum registration fee per family",
      description:
        "The maximum registration fee will cap the amount charged when enrolling multiple students from the same family.",
    })
    .onChange(form => {
      if (form.startAt && startAt !== form.startAt) {
        setStartAt(form.startAt);
      }
      if (form.endAt && endAt !== form.endAt) {
        setEndAt(form.endAt);
      }
    });

  if (taxEnabled) {
    builder.select("registrationFeeTaxRateId", {
      label: "Registration fee tax rate",
      required: true,
      data: makeTaxRateSelectOptions(taxRates),
      notFoundLabel: (
        <ContentPlaceholder
          icon={"helpCircleOutline"}
          title={"No tax rates yet"}
          description={"Go to settings to add tax rates"}
        />
      ),
    });
  }

  return builder.getDefinition();
};

export const useUpdatePricingSchemeDefinition = () => {
  return new FormDefinitionBuilder<UpdateSeasonRequest>()
    .bigRadio("pricingScheme", {
      cols: 1,
      options: [
        {
          icon: "cash",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Price per class per month",
          description: "Charge a fixed monthly fee for each class.",
          value: PricingScheme.None,
        },
        {
          icon: "personAdd",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Number of classes",
          description:
            "Charge per month based on the number of classes a student takes per week.",
          value: PricingScheme.ClassCount,
        },
        {
          icon: "time",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Number of hours",
          description:
            "Charge per month based on the number of hours of classes a student takes per week.",
          value: PricingScheme.TotalDuration,
        },
      ],
    })
    .getDefinition();
};

export const makeSeasonCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.seasons.createSeason({
      createSeasonRequest: formData,
    });
  };

export const makeSeasonUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.seasons.updateSeason({
      id,
      updateSeasonRequest: formData,
    });
  };
