import { useEffect, useState } from "react";

import { MultiSelectChips } from "./MultiSelectChips";
import { rangeBetween } from "../../arrayUtils";
import { courseIsSuitableForAge } from "../../classUtils";
import { ageLabel, dayOfWeekLabels } from "../../courseFilterLabels";
import { useCatalogueData } from "../../data/useCatalogueData";
import { getPlatformFunction } from "../../platformSpecific";
import {
  CourseSearchValues,
  DayOfWeek,
} from "../courseSearch/courseSearchContext";
import { useGenericComponents } from "../GenericComponentsProvider";

export const CourseFilterForm = ({
  defaultValues,
  onChange,
}: {
  defaultValues: CourseSearchValues;
  onChange: (values: CourseSearchValues) => void;
}) => {
  const [values, setValues] = useState<CourseSearchValues>(defaultValues);
  const { View } = useGenericComponents();
  const useApi = getPlatformFunction("useApi");

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  const { assignedLocationIds, courses, locations, seasons } = useCatalogueData(
    useApi,
    true,
  );

  if (!courses || !locations || !seasons) {
    return null;
  }

  const seasonOptions =
    (seasons ?? []).map(season => ({ label: season.name, value: season.id })) ??
    [];

  const dayOptions: Array<{
    label: string;
    value: DayOfWeek;
  }> = Object.entries(dayOfWeekLabels).map(([value, label]) => ({
    label,
    value: value as DayOfWeek,
    disabled: !courses.some(c => c.entity.dayOfWeek === value),
  }));

  const locationOptions =
    (locations ?? []).map(location => ({
      label: location.name,
      value: location.id,
      disabled: !assignedLocationIds.includes(location.id),
    })) ?? [];

  const ageOptions = rangeBetween(1, 19).map(year => ({
    label: ageLabel(year),
    value: year,
    disabled: !courses.some(c => courseIsSuitableForAge(c, year)),
  }));

  return (
    <View className="flex flex-col gap-y-8">
      <View>
        <MultiSelectChips
          label={t("label.courseFilter.season")}
          options={seasonOptions}
          value={values.seasonIds}
          onChange={v => setValues({ ...values, seasonIds: [...v] })}
        />
      </View>
      <View>
        <MultiSelectChips
          label={"Day of the week"}
          options={dayOptions}
          value={values.days}
          onChange={v => setValues({ ...values, days: v })}
        />
      </View>
      <View>
        <MultiSelectChips
          label={"Location"}
          options={locationOptions}
          value={values.locationIds}
          onChange={v => setValues({ ...values, locationIds: v })}
        />
      </View>
      <View>
        <MultiSelectChips
          label={"Age"}
          options={ageOptions}
          value={values.ages}
          onChange={v => setValues({ ...values, ages: v })}
        />
      </View>
    </View>
  );
};
