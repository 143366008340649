import {
  BillingTiming,
  UpdateCompanySettingsRequest,
} from "@justraviga/classmanager-sdk";

import { showAlert } from "../../../../../alertState";
import { useAuthState } from "../../../../../auth/useAuthState";
import { FormDefinitionBuilder } from "../../../../../forms/formDefinitionBuilder";
import { getPlatformFunctions } from "../../../../../platformSpecific";
import { useFormActions } from "../../../../FormActionsProvider";
import {
  makeAutomatedTuitionFormRequest,
  useAutomatedTuitionForm,
} from "../../../../formDefinitions/settings/automatedTuitionForm";
import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { useSettings } from "../../../../useSettings";

export const useCreateSeasonSettingsSheet = () => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const { account, setAccount } = useAuthState();
  const billingSettings = useSettings("billing");
  const { setIsLoading } = useFormActions();

  const automatedTutionSettings = usePlatformEntityActions<
    Pick<
      Required<UpdateCompanySettingsRequest>["billing"],
      "billingTiming" | "billingDayOfMonth"
    > & { saveDefault: boolean }
  >({ entity: "company" });
  const showAutomatedTuitionSettingsForm = useAutomatedTuitionForm(true);

  const installmentsSettings = usePlatformEntityActions<{
    installments: number;
  }>({ entity: "company" });

  const discountSettings = usePlatformEntityActions<{
    discount: number;
  }>({ entity: "company" });

  const paymentStartDateSettings = usePlatformEntityActions<{
    paymentStartDate: string | null;
  }>({ entity: "company" });

  return {
    showAutomatedTuitionSettingsForm: ({
      userBillingDayOfMonth,
      setUserBillingDayOfMonth,
      userBillingTiming,
      setUserBillingTiming,
    }: {
      userBillingDayOfMonth: number | null;
      setUserBillingDayOfMonth: (userBillingDayOfMonth: number | null) => void;
      userBillingTiming: BillingTiming | null;
      setUserBillingTiming: (userBillingTiming: BillingTiming | null) => void;
    }) => {
      return automatedTutionSettings.showUpdateForm({
        title: "Automated tuition settings",
        form: (
          <GenericForm
            apiRequest={form => {
              if (form.saveDefault) {
                setIsLoading(true);
                return makeAutomatedTuitionFormRequest({
                  api,
                  setAccount,
                  account: account!,
                })(form).then(() => {
                  showAlert({
                    content: "Default settings saved successfully",
                  });
                  return form;
                });
              } else {
                return Promise.resolve(form);
              }
            }}
            defaultValues={{
              billingDayOfMonth:
                userBillingDayOfMonth || billingSettings.billingDayOfMonth,
              billingTiming: userBillingTiming || billingSettings.billingTiming,
              saveDefault: false,
            }}
            formDefinitionHook={() => showAutomatedTuitionSettingsForm}
            onSuccess={result => {
              setUserBillingDayOfMonth(result.billingDayOfMonth as number);
              setUserBillingTiming(result.billingTiming as BillingTiming);
              automatedTutionSettings.hideForm();
            }}
          />
        ),
      });
    },
    showInstallmentsForm: ({
      installments,
      userInstallments,
      setUserInstallments,
    }: {
      installments: number;
      userInstallments: number | null;
      setUserInstallments: (installments: number) => void;
    }) => {
      return installmentsSettings.showUpdateForm({
        title: "Change number of installments",
        form: (
          <GenericForm
            apiRequest={(formData: { installments: number }) =>
              Promise.resolve(formData)
            }
            defaultValues={{
              installments: userInstallments || installments,
            }}
            formDefinitionHook={() =>
              new FormDefinitionBuilder<{
                installments: number;
              }>()
                .integer("installments", {
                  label: "Number of installments",
                  required: true,
                })
                .getDefinition()
            }
            onSuccess={form => {
              setUserInstallments(form.installments);
              installmentsSettings.hideForm();
            }}
          />
        ),
      });
    },
    showAddDiscountForm: ({
      discount,
      setDiscount,
    }: {
      discount: number | null;
      setDiscount: (discount: number | null) => void;
    }) => {
      return discountSettings.showUpdateForm({
        title: "Add discount",
        form: (
          <GenericForm
            apiRequest={(formData: { discount: number }) =>
              new Promise<{
                discount: number;
              }>((resolve, reject) => {
                if (formData.discount < 0 || formData.discount > 100) {
                  reject({
                    statusCode: 422,
                    messages: ["Discount must be between 0 and 100."],
                    validationErrors: {
                      discount: ["Discount must be between 0 and 100."],
                    },
                  });
                } else {
                  resolve(formData);
                }
              })
            }
            defaultValues={{
              discount,
            }}
            formDefinitionHook={() =>
              new FormDefinitionBuilder<{
                discount: number;
              }>()
                .integer("discount", {
                  label: "Discount",
                  prefix: "%",
                  required: true,
                })
                .getDefinition()
            }
            onSuccess={form => {
              setDiscount(form.discount);
              discountSettings.hideForm();
            }}
          />
        ),
      });
    },
    showEditDiscountForm: ({
      discount,
      setDiscount,
    }: {
      discount: number | null;
      setDiscount: (discount: number | null) => void;
    }) => {
      return discountSettings.showUpdateForm({
        title: "Edit discount",
        form: (
          <GenericForm
            apiRequest={(formData: { discount: number }) =>
              new Promise<{
                discount: number;
              }>((resolve, reject) => {
                if (formData.discount < 0 || formData.discount > 100) {
                  reject({
                    statusCode: 422,
                    messages: ["Discount must be between 0 and 100."],
                    validationErrors: {
                      discount: ["Discount must be between 0 and 100."],
                    },
                  });
                } else {
                  resolve(formData);
                }
              })
            }
            defaultValues={{
              discount,
            }}
            formDefinitionHook={() =>
              new FormDefinitionBuilder<{
                discount: number;
              }>()
                .integer("discount", {
                  label: "Discount",
                  prefix: "%",
                  required: true,
                })
                .getDefinition()
            }
            onSuccess={form => {
              setDiscount(form.discount);
              discountSettings.hideForm();
            }}
          />
        ),
      });
    },
    showPaymentStartDateForm: ({
      paymentStartDate,
      userPaymentStartDate,
      setUserPaymentStartDate,
    }: {
      paymentStartDate: string | null;
      userPaymentStartDate: string | null;
      setUserPaymentStartDate: (setUserPaymentStartDate: string | null) => void;
    }) => {
      return paymentStartDateSettings.showUpdateForm({
        title: "Edit dates",
        form: (
          <GenericForm
            apiRequest={(formData: { paymentStartDate: string | null }) =>
              Promise.resolve(formData)
            }
            defaultValues={{
              paymentStartDate: userPaymentStartDate || paymentStartDate,
            }}
            formDefinitionHook={() =>
              new FormDefinitionBuilder<{
                paymentStartDate: string | null;
              }>()
                .date("paymentStartDate", {
                  label: "Payment date",
                  required: true,
                })
                .getDefinition()
            }
            onSuccess={form => {
              setUserPaymentStartDate(form.paymentStartDate);
              paymentStartDateSettings.hideForm();
            }}
          />
        ),
      });
    },
  };
};
