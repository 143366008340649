import { useWaitingListActions } from "@shared/components/actions/useWaitingListActions";

import {
  Breadcrumb,
  useEnrolmentsActions,
  useStudentWaitingListDatatable,
} from "shared/components";
import { getFullName } from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { Button } from "@/modules/common/ui/button/Button";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import {
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const StudentWaitingListPage = ({ studentId }: { studentId: string }) => {
  const actions = useWaitingListActions();
  const enrolmentActions = useEnrolmentsActions();

  const { data: student } = useApi("getStudent", { id: studentId });

  const { datatable } = useStudentWaitingListDatatable({
    Datatable,
    api,
    studentId,
    goToClass: id => Router.push("ClassDetails", { id }),
    enrollForClass: item =>
      enrolmentActions.showCreateForm({
        studentIds: [item.studentId],
        showStudentSelector: false,
      }),
    deleteOne: actions.deleteOne,
  });

  if (!student) {
    return <LoadingPlaceholder />;
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: "Students",
      onClick: () => Router.push("StudentList"),
    },
    {
      text: getFullName(student),
      onClick: () => Router.push("StudentDetails", { id: student.id }),
    },
    {
      text: "Waiting list",
    },
  ];

  return (
    <CompanyLayout datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Waiting list"} />
        <div>
          <ProtectedOverlay permission="enrolments:manage">
            <Button
              text={"Add"}
              size="sm"
              onClick={() => actions.showCreateForm({ studentId })}
              variant="brand"
            />
          </ProtectedOverlay>
        </div>
      </CompanyTitleBar>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "enrolments:view",
  StudentWaitingListPage,
  "Student waiting list",
);

export { PermissionChecked as StudentWaitingListPage };
