import { AccountPermissionSet, StaffDto } from "@justraviga/classmanager-sdk";

import { enumLabel, getInitials } from "shared/lib";

import {
  DetailProfileAvatarContainer,
  DetailProfileContactList,
  DetailProfileContainer,
} from "@/modules/company/common/DetailProfile";
import { AvatarWithUpload } from "@/modules/company/common/details/profile/AvatarWithUpload";

const Permission = ({
  permission,
}: {
  permission: AccountPermissionSet | null;
}) => {
  return (
    <div>
      <span className={"text-body-400 text-grey-600"}>Role </span>
      <span className={"text-body-400 capitalize text-grey-900"}>
        {enumLabel("accountPermissionSet", permission) ?? "-"}
      </span>
    </div>
  );
};

export const Profile = ({
  staff,
  uploadAction,
}: {
  staff: StaffDto;
  uploadAction: () => void;
}) => {
  return (
    <DetailProfileContainer>
      <DetailProfileAvatarContainer>
        <AvatarWithUpload
          src={staff.profilePicture}
          name={getInitials(staff)}
          showUploadIcon={!staff.archivedAt}
          uploadAction={uploadAction}
          permission="staff:manage"
        />
        <div>
          <div className={"flex flex-row items-center space-x-1"}>
            <p className={"line-clamp-1 text-heading5-600 text-grey-900"}>
              {staff.firstname} {staff.lastname}
            </p>
          </div>
        </div>
      </DetailProfileAvatarContainer>
      <DetailProfileContactList email={staff.email} phone={staff.phone} />
      <Permission permission={staff.permissionSet} />
    </DetailProfileContainer>
  );
};
