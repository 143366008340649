import { Breadcrumb, ContentPlaceholder } from "shared/components";

import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { useWaiversArchivedListPage } from "@/modules/company/waivers/useWaiversArchivedListPage";
import { WaiverCard } from "@/modules/company/waivers/WaiverCard";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";

const WaiversArchivedPage = () => {
  const { goToWaiversPage, isEmpty, waivers } = useWaiversArchivedListPage();

  const crumbs: Breadcrumb[] = [
    {
      text: "Waivers & policies",
      onClick: goToWaiversPage,
    },
    {
      text: "Archived waivers & policies",
    },
  ];

  return (
    <CompanyCardPage
      title={"Archived waivers & policies"}
      isEmpty={isEmpty()}
      crumbs={crumbs}
      hideGlobalHeader>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon="archiveOutline"
          title={"No archived waivers or policies"}
        />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {waivers?.data.map((waiver, key) => (
          <WaiverCard waiver={waiver} key={key} />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "waivers:view",
  WaiversArchivedPage,
  "Archived waivers",
);

export { PermissionChecked as WaiversArchivedPage };
