import { SeasonDto } from "@justraviga/classmanager-sdk";

import { ClassEntity } from "shared/lib";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { useCourseActions } from "@/modules/company/classPlanner/classes/useCourseActions";
import { Router } from "@/routing/router";

interface UseClassActionMenuParams {
  classEntity: ClassEntity;
  season: SeasonDto;
}

export const useClassActionMenu = ({
  classEntity,
  season,
}: UseClassActionMenuParams) => {
  const classActions = useCourseActions();

  const isArchived = !!classEntity.class.archivedAt;

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Go to class",
      leftIcon: "openOutline",
      onClick: () => Router.push("ClassDetails", { id: classEntity.class.id }),
      permission: "schedule:view",
    },
    {
      title: "Preview",
      leftIcon: "listOutline",
      onClick: () => classActions.showPreview(classEntity, season),
      permission: "schedule:view",
    },
    {
      title: "Edit",
      leftIcon: "createOutline",
      onClick: () => classActions.showUpdateForm(classEntity, season),
      permission: "schedule:manage",
    },
    // @TODO: email enrollments is not implemented yet
    // {
    //   title: "Email enrollments",
    //   leftIcon: "mailOutline",
    //   onClick: () => alert("Email enrollments is not implemented yet"),
    // },
    {
      title: isArchived ? "Restore" : "Archive",
      leftIcon: isArchived ? "reloadOutline" : "archiveOutline",
      onClick: () =>
        isArchived
          ? classActions.unarchive(classEntity.props)
          : classActions.archive(classEntity.props),
      destructive: true,
      hasDestructiveStyle: false,
      permission: "schedule:manage",
    },
    {
      title: "Delete",
      leftIcon: "trashOutline",
      onClick: () => classActions.deleteOne(classEntity.props),
      destructive: true,
      permission: "schedule:manage",
    },
  ];

  return {
    items: menuItems,
  };
};
