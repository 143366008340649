import { FC, useState } from "react";

import {
  PricingScheme,
  SeasonDto,
  SortSchema,
} from "@justraviga/classmanager-sdk";
import { PricingSchemeValueDto } from "@justraviga/classmanager-sdk/dist/models/PricingSchemeValueDto";
import { match } from "ts-pattern";

import { Api } from "../../api";
import { minutesToHumanReadable } from "../../classUtils";
import {
  DatatableConfiguration,
  DatatablePaginatedData,
  DatatableQueryProps,
} from "../../datatable";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { UseApi } from "../apiQueryFactory";

type Dto = PricingSchemeValueDto;
type FilterForm = object;
type RowDto = Dto;

const getRowHeading = (row: RowDto, numRows: number, season: SeasonDto) => {
  switch (season.pricingScheme) {
    case PricingScheme.ClassCount:
      return (
        row.quantity +
        (row.quantity === numRows ? "+ " : " ") +
        (row.quantity === 1 ? "class" : "classes") +
        " / week"
      );
    case PricingScheme.TotalDuration:
      return minutesToHumanReadable(row.quantity) + " / week";
  }
  return "";
};

let maxId: string | null = null;

export const usePricingTierDatatable = ({
  Datatable,
  api,
  seasonId,
  showCreateForm,
  showUpdateForm,
  deleteOne,
  useApi,
}: {
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, FilterForm, RowDto>;
  }>;
  api: Api;
  seasonId: SeasonDto["id"];
  showCreateForm: undefined | (() => void);
  showUpdateForm: (item: RowDto) => void;
  deleteOne: (item: Dto) => Promise<void>;
  useApi: UseApi;
}) => {
  const [listLength, setListLength] = useState(0);
  const { data: season } = useApi("getSeason", { id: seasonId });
  if (!season) return { datatable: null, listLength: 0 };
  const fetchData = async (
    query: DatatableQueryProps,
  ): Promise<DatatablePaginatedData<RowDto>> => {
    const { data, pagination } =
      await api.pricingSchemeValues.listPricingSchemeValue({
        ...query.toRequest(),
        where: {
          seasonId: {
            equals: seasonId,
          },
        },
        selectAll: true,
        sort: {
          quantity: SortSchema.Asc,
        },
      });

    setListLength(data.length);
    maxId = data.length > 0 ? data[data.length - 1].id : null;

    return {
      data,
      pagination,
    };
  };

  const [quantityColumnHeader, maxRowDescription, noContentPlaceholderText] =
    match(season.pricingScheme)
      .with(PricingScheme.ClassCount, () => [
        "Classes per student",
        "Tuition for any students enrolled to a greater number of classes will remain at this maximum price.",
        "Add them to calculate fees by adding up the number of classes each student takes.",
      ])
      .with(PricingScheme.TotalDuration, () => [
        "Hours per student",
        "Tuition for any students enrolled to a greater number of hours will remain at this maximum price.",
        "Add them to calculate fees by adding up the number of hours each student takes.",
      ])
      .with(PricingScheme.None, () => ["", ""])
      .exhaustive(); // to trigger a lint error if we add a new pricing scheme

  const config: DatatableConfiguration<Dto, object> = {
    id: "pricingSchemeValue",
    hasPagination: false,
    hasSearch: false,
    placeholdersCount: 3,
    title: "Pricing list",
    titleSize: "sm",
    permissions: {
      archive: "schedule:manage",
      create: "schedule:manage",
      delete: "schedule:manage",
      edit: "schedule:manage",
      restore: "schedule:manage",
    },
    createAction: showCreateForm,
    createLabel: "Add pricing tier",
    showTotalRecords: false,
    rowActions: {
      edit: showUpdateForm,
      additionalRowActions: () => [
        {
          icon: "trashOutline",
          label: "Delete",
          isDestructive: true,
          enabled: row =>
            season.pricingScheme === PricingScheme.TotalDuration ||
            row.quantity === listLength,
          onClick: deleteOne,
          permission: "schedule:manage",
        },
      ],
    },
    fetchData,
    columns: [
      {
        label: quantityColumnHeader,
        placeholder: "tile",
        value: row => {
          return {
            type: "tile",
            title: getRowHeading(row.item, listLength, season),
            subtitle: row.item.id === maxId ? maxRowDescription : undefined,
            hasImage: false,
            multilineSubtitle: true,
          };
        },
      },
      {
        label: "Price per month",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: `${formatMoneyFromInteger(row.item.price)}`,
        }),
      },
    ],
    mobileColumn: {
      hasImage: false,
      title: row => getRowHeading(row.item, listLength, season),
      subtitle: row => `${formatMoneyFromInteger(row.item.price)} / month`,
    },
    contentPlaceholders: {
      noContent: {
        title: "No pricing tiers",
        icon: "helpCircleOutline",
        description: noContentPlaceholderText,
      },
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
    listLength,
  };
};
