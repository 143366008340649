import { useEffect } from "react";

import { useAuthState } from "shared/components";
import { getCountryCode } from "shared/lib";

import { ClassManagerRegisterTermsAndPrivacy } from "@/modules/auth/common/ClassManagerRegisterTermsAndPrivacy";
import { RegistrationForm } from "@/modules/auth/companyRegistration/RegistrationForm";
import { TestimonialSection } from "@/modules/auth/companyRegistration/TestimonialSection";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { ClassManagerLogo } from "@/modules/common/ClassManagerLogo";
import { classManagerLogoSize } from "@/modules/common/logoSizes";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import {
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/modules/common/ui/card/Card";
import { Router } from "@/routing/router";

export const AddNewCompanyPage = () => {
  const { isLoggedIn } = useAuthState();
  const countryCode = getCountryCode();

  useEffect(() => {
    if (!isLoggedIn) {
      // redirect to register company page if not logged in
      Router.push("RegisterCompany");
    }
  }, [countryCode, isLoggedIn]);

  // If we aren't logged in, show nothing and the redirect above will take care of it
  if (!isLoggedIn) {
    return null;
  }

  function goBack() {
    window.history.back();
  }

  return (
    <NonAuthLayout size={"lg"}>
      <div className={"grid grid-cols-1 lg:grid-cols-2"}>
        <div
          className={
            "space-y-8 rounded-bl rounded-tl border-0 border-grey-300 bg-white px-8 py-10 lg:border lg:px-8 lg:py-12"
          }>
          <div className={"relative flex items-center"}>
            <IconButton
              icon={"chevronBack"}
              onClick={goBack}
              variant={"standard"}
              className={"absolute inset-y-0 left-0"}
            />
            <ClassManagerLogo
              color="grey-900"
              height={classManagerLogoSize.standard.height}
              width={classManagerLogoSize.standard.width}
              className={"mx-auto"}
            />
          </div>
          <CardHeader>
            <CardTitle>Add a new company</CardTitle>
          </CardHeader>
          <CardContent className={"space-y-8"}>
            <RegistrationForm countryCode={countryCode} />
          </CardContent>
          <CardFooter className="flex-col space-y-8 p-0">
            <ClassManagerRegisterTermsAndPrivacy />
          </CardFooter>
        </div>
        <TestimonialSection />
      </div>
    </NonAuthLayout>
  );
};
