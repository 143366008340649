import { ContactDto } from "@justraviga/classmanager-sdk";

import { useSharedContactActions } from "shared/components";
import { enumLabel, getFullName } from "shared/lib";
import { useBreakpoint } from "shared/lib";

import { copyToClipboard, openPhoneClient } from "@/lib/utils";
import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { DetailCard } from "@/modules/company/common/DetailCard";
import {
  ContactCardItem,
  ContactCardItemWithAction,
} from "@/modules/company/common/details/ContactItem";
import { Router } from "@/routing/router";

export const ContactCard = ({ contact }: { contact: ContactDto }) => {
  const breakpoints = useBreakpoint();
  const contactActions = useSharedContactActions();

  const makePrimaryAction: ActionMenuItemProps = {
    title: "Make primary",
    onClick: () => {
      contactActions.makePrimary(contact);
    },
    permission: "members:manage",
  };

  return (
    <DetailCard
      headerClass={"items-start"}
      header={
        <div
          className={
            "flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-2 md:space-y-0"
          }>
          <span className={"text-body-600 capitalize text-grey-900"}>
            {getFullName(contact)}
          </span>
          <div className={"flex flex-row space-x-2"}>
            {contact.isPrimary && <Chip size={"sm"} label={"Primary"} />}
            {contact.isEmergency && (
              <Chip label={"Emergency"} size={"sm"} variant={"danger"} />
            )}
          </div>
        </div>
      }
      headerButton={
        <ActionMenu
          trigger={
            <IconButton
              variant="standard"
              icon={"ellipsisHorizontal"}
              size="sm"
              aria-label="Close"
            />
          }
          items={[
            ...(contact.isPrimary ? [] : [makePrimaryAction]),
            {
              title: "Edit",
              onClick: () => {
                contactActions.showUpdateForm(contact);
              },
              permission: "members:manage",
            },
            {
              title: "Delete",
              destructive: true,
              onClick: () => contactActions.deleteOne(contact),
              permission: "members:manage",
            },
          ]}
        />
      }>
      <div className={"flex flex-col space-y-1"}>
        <div className={"p-2"}>
          <ContactCardItem
            icon={"personOutline"}
            text={
              contact.relation && enumLabel("contactRelation", contact.relation)
            }
          />
        </div>
        <div className={"p-2"}>
          <ContactCardItemWithAction
            shouldShowActions={!breakpoints.md}
            content={contact.phone}
            mainIcon={"callOutline"}
            actions={[
              {
                icon: "callOutline",
                action: () => {
                  contact.phone && openPhoneClient(contact.phone);
                },
              },
            ]}
          />
        </div>
        <div className={"p-2"}>
          <ContactCardItemWithAction
            shouldShowActions={true}
            content={contact.email}
            mainIcon={"mailOutline"}
            actions={[
              {
                icon: "copyOutline",
                action: () => {
                  contact.email &&
                    copyToClipboard(contact.email, "Email copied to clipboard");
                },
              },
              {
                icon: "mailOutline",
                action: () => {
                  contact.email &&
                    Router.push("WriteEmail", { contactId: contact.id });
                },
                permission: "messaging:manage",
              },
            ]}
          />
        </div>
      </div>
    </DetailCard>
  );
};
