import { NoItemsContentPlaceholder } from "../../../ui/NoItemsContentPlaceholder";

interface Props {
  onCreateClick(): void;
}

export const NoCustomFieldsPlaceholder = ({ onCreateClick }: Props) => {
  return (
    <NoItemsContentPlaceholder
      icon={"createOutline"}
      title={"Get started with custom fields"}
      description={
        "Custom fields are designed for you to be able to capture personalized information that is specific to your organization\n\nManage information for members, staff, classes and more..."
      }
      createAction={{
        text: "Create",
        onClick: onCreateClick,
        permission: "customFields:manage",
      }}
    />
  );
};
