import { EnrolmentStatsDto } from "@justraviga/classmanager-sdk";

import { EnrolmentsSummaryCard } from "shared/components";
import { SeasonStatus } from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export interface EnrolmentSummaryCardProps {
  courseCapacity: number;
  enrolmentCounts: EnrolmentStatsDto;
  seasonStatus: SeasonStatus;
  goToEnrolments: () => void;
  addEnrolment: () => void;
}

export const EnrolmentSummaryCard = ({
  addEnrolment,
  courseCapacity,
  enrolmentCounts,
  goToEnrolments,
  seasonStatus,
}: EnrolmentSummaryCardProps) => {
  return (
    <EnrolmentsSummaryCard
      courseCapacity={courseCapacity}
      enrolmentCounts={enrolmentCounts}
      seasonStatus={seasonStatus}
      goToEnrolments={goToEnrolments}
      addEnrolmentButton={
        <ProtectedOverlay permission={"enrolments:manage"}>
          <IconButton
            className={"-mr-2 -mt-2"}
            icon={"addCircleOutline"}
            variant={"standard"}
            onClick={e => {
              e.stopPropagation(); // make sure the underlying card isn't clicked
              addEnrolment();
            }}
          />
        </ProtectedOverlay>
      }
    />
  );
};
