import { useEffect, useState } from "react";

import { ContactDto, SortSchema } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { getClassSubtitle } from "../../classUtils";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";

interface FormSchema {
  seasonId: string;
  classIds: Array<string>;
}

const useFormDefinition = () => {
  const { useApi } = getPlatformFunctions();
  const { data: seasons } = useApi("listSeason", {
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  });
  const { data: courses } = useApi("listCourse", {});

  const [seasonId, setSeasonId] = useState<string | null>(null);

  const seasonOptions =
    seasons?.data.map(season => ({
      value: season.id,
      label: season.name,
    })) ?? [];

  const courseOptions =
    seasons && courses
      ? courses.data
          .filter(c => c.entity.seasonId === seasonId)
          .map(course => ({
            value: course.entity.id,
            label: course.entity.name,
            description: getClassSubtitle(course.entity),
          }))
      : [];

  return new FormDefinitionBuilder<FormSchema>()
    .select("seasonId", {
      label: t("label.season"),
      required: true,
      data: seasonOptions,
    })
    .multiSelect("classIds", {
      label: "Select classes",
      required: true,
      data: courseOptions,
      localSearch: true,
    })
    .conditional(["classIds"], ["seasonId"], v => {
      if (!v.seasonId) {
        return false;
      }

      if (seasonId !== v.seasonId) {
        setSeasonId(v.seasonId as string);
      }

      return true;
    })
    .getDefinition();
};

const makeRequest = (api: Api) => async (data: FormSchema) => {
  return api.contacts.searchContact({
    where: { classId: { in: data.classIds } },
  });
};

export const RecipientsByClassForm = ({
  onSuccess,
}: {
  onSuccess: (recipients: Array<ContactDto>) => void;
}) => {
  const { GenericForm } = useGenericComponents();
  const { api } = getPlatformFunctions();
  const formActions = useFormActions();

  useEffect(() => {
    formActions.setAllowCreateAdditional(false);
    formActions.setCreateButtonText("Add");
  }, [formActions]);

  return (
    <GenericForm
      apiRequest={makeRequest(api)}
      defaultValues={{}}
      formDefinitionHook={useFormDefinition}
      onSuccess={onSuccess}
    />
  );
};
