import { ReactNode } from "react";

import { useCreateSeasonSettingsSheet } from "./useCreateSeasonSettingsSheet";
import { formatDate } from "../../../../../intlFormatter";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const UpfrontPayment = ({
  paymentStartDate,
  userPaymentStartDate,
  discount,
  setDiscount,
  setUserPaymentStartDate,
  showAlertDialog,
}: {
  paymentStartDate: string;
  userPaymentStartDate: string | null;
  openSheet: ({
    title,
    content,
    footer,
  }: {
    title: string;
    content: ReactNode;
    footer: ReactNode;
  }) => void;
  closeSheet: () => void;
  discount: number | null;
  setDiscount: (discount: number | null) => void;
  setUserPaymentStartDate: (setUserPaymentStartDate: string | null) => void;
  showAlertDialog: ({
    title,
    description,
    confirmButtonText,
    confirmButtonStyle,
    cancelText,
  }: {
    title: string;
    description: string;
    confirmButtonText: string;
    confirmButtonStyle: "primary" | "destructive" | undefined;
    cancelText: string;
  }) => Promise<boolean>;
}) => {
  const { Button, Text, View } = useGenericComponents();
  const {
    showAddDiscountForm,
    showEditDiscountForm,
    showPaymentStartDateForm,
  } = useCreateSeasonSettingsSheet();
  return (
    <View className={"flex flex-col space-y-2"}>
      <View className={"flex flex-col sm:flex-row"}>
        <View>
          <Text className={"text-label-400 text-grey-600"}>
            On{" "}
            {paymentStartDate &&
              formatDate(
                userPaymentStartDate || paymentStartDate,
                "dayMonthYear",
              )}
          </Text>
        </View>
        <View>
          <View className={"-ml-2 sm:ml-0 -mt-1.5"}>
            <Button
              text={"Edit"}
              variant={"tertiaryLight"}
              size={"xs"}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                showPaymentStartDateForm({
                  paymentStartDate,
                  userPaymentStartDate,
                  setUserPaymentStartDate,
                });
              }}
            />
          </View>
        </View>
      </View>
      <View>
        {!discount ? (
          <View className={"-ml-2"}>
            <Button
              text={"Add a discount for paying upfront"}
              variant={"tertiaryLight"}
              size={"xs"}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                showAddDiscountForm({
                  discount,
                  setDiscount,
                });
              }}
            />
          </View>
        ) : (
          <View
            className={"flex flex-col sm:flex-row items-start sm:items-center"}>
            <View>
              <Text className={"text-label-400 text-grey-600"}>
                {discount}% upfront discount
              </Text>
            </View>
            <View className={"flex flex-row flex-grow space-x-2"}>
              <View className={"-ml-2 sm:ml-0"}>
                <Button
                  variant={"tertiaryLight"}
                  size={"xs"}
                  text={"Edit"}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    showEditDiscountForm({
                      discount,
                      setDiscount,
                    });
                  }}
                />
              </View>
              <View className={"-ml-2 sm:ml-0"}>
                <Button
                  variant={"destructive-tertiary"}
                  size={"xs"}
                  text={"Remove"}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    showAlertDialog({
                      title: "Delete discount",
                      description:
                        "Are you sure you want to delete this discount? This cannot be undone.",
                      confirmButtonText: "Delete",
                      confirmButtonStyle: "destructive",
                      cancelText: "Cancel",
                    }).then(result => {
                      if (result) {
                        setDiscount(null);
                      }
                    });
                  }}
                />
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
