import { ReactNode } from "react";

import { Feature, FeatureContext } from "./featureContext";
import { useAuthState } from "../../auth/useAuthState";
import { getPlatformFunctions } from "../../platformSpecific";

export const FeatureProvider = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, account } = useAuthState();

  const { useApi } = getPlatformFunctions();

  const { data, isLoading } = useApi(
    "getFeatures",
    {},
    {
      enabled: isLoggedIn && account?.role !== "admin",
    },
  );

  const enabledFeatures = (data?.features ?? [])
    .filter(f => f.enabled && !f.disabledByAdmin)
    .map(f => f.name) as Feature[];

  function hasFeature(feature: Feature) {
    return enabledFeatures.includes(feature);
  }

  if (isLoading) {
    return null;
  }

  return (
    <FeatureContext.Provider
      value={{
        hasFeature,
      }}>
      {children}
    </FeatureContext.Provider>
  );
};
