import { useEffect } from "react";

import { RepetitionInterval } from "@justraviga/classmanager-sdk";

import { useTaxRates } from "../../data/useTaxRates";
import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import {
  makeRegistrationFeeCreateRequest,
  useRegistrationFeeFormDefinition,
} from "../formDefinitions/registrationFeeForm";
import { useGenericComponents } from "../GenericComponentsProvider";

interface CreateRegistrationFeeFormProps {
  repetitionInterval: RepetitionInterval;
  onSuccess: () => void;
}

export const CreateRegistrationFeeForm = ({
  repetitionInterval,
  onSuccess,
}: CreateRegistrationFeeFormProps) => {
  const { api } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const { setAllowCreateAdditional } = useFormActions();
  const { defaultTaxRateId } = useTaxRates();

  useEffect(() => {
    setAllowCreateAdditional(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenericForm
      onSuccess={onSuccess}
      apiRequest={makeRegistrationFeeCreateRequest({ api, repetitionInterval })}
      defaultValues={{
        repetitionInterval,
        proRated: false,
        taxRateId: defaultTaxRateId,
      }}
      formDefinitionHook={useRegistrationFeeFormDefinition(repetitionInterval)}
    />
  );
};
