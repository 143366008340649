import { AddressDto } from "@justraviga/classmanager-sdk";

import { Permission } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { Button } from "@/modules/common/ui/button/Button";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import { useAddressesActions } from "@/modules/company/addresses/useAddressesActions";
import { DetailCard } from "@/modules/company/common/DetailCard";

interface AddressCardProps {
  entityId: string;
  updatePermission: Permission;
}

function buildAddress(addr: AddressDto) {
  return [addr.address1, addr.address2, addr.town, addr.state, addr.zip]
    .filter(Boolean)
    .join(", ");
}

export const AddressCard = ({
  entityId,
  updatePermission,
}: AddressCardProps) => {
  const { showUpdateForm } = useAddressesActions();

  const { data, isLoading } = useApi("getAddresses", { entityId });
  const addresses = data?.addresses || [];
  const address = addresses.length > 0 ? addresses[0] : ({} as AddressDto);

  return (
    <DetailCard
      isFoldable={false}
      title={"Address"}
      icon={"locationOutline"}
      headerButton={
        <ProtectedOverlay permission={updatePermission}>
          <Button
            variant={"subtle"}
            size={"xs"}
            onClick={() => showUpdateForm(entityId, address)}
            text={"Edit"}
          />
        </ProtectedOverlay>
      }
      children={
        <AddressCardContent isLoading={isLoading} addresses={addresses} />
      }
    />
  );
};

const AddressCardContent = ({
  isLoading,
  addresses,
}: {
  isLoading: boolean;
  addresses: AddressDto[];
}) => {
  if (isLoading) {
    return (
      <div className={"flex items-center justify-center p-3"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  return (
    <section>
      <div className={"p-2 text-base text-grey-900"}>
        {addresses.length === 0 ? "-" : buildAddress(addresses[0])}
      </div>
    </section>
  );
};
