import { CustomFieldEntity, StaffDto } from "@justraviga/classmanager-sdk";

import { Breadcrumb, CustomFieldResponsesCard } from "shared/components";
import {
  getFullName,
  useBreakpoint,
  WebActionMenuItemProps,
  WebActionMenuProps,
} from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { AddressCard } from "@/modules/company/addresses/AddressCard";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { CustomFieldResponsesContent } from "@/modules/company/customFields/entityResponses/card/CustomFieldResponsesContent";
import { useCustomFieldActions } from "@/modules/company/customFields/useCustomFieldActions";
import { Profile } from "@/modules/company/staff/details/Profile";
import { StaffScheduleWidget } from "@/modules/company/staff/StaffScheduleWidget";
import { useStaffActions } from "@/modules/company/staff/useStaffActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const StaffDetailsPage = ({ id }: { id: StaffDto["id"] }) => {
  const breakpoints = useBreakpoint();
  const staffActions = useStaffActions();
  const customFieldActions = useCustomFieldActions();

  const { data: staffData } = useApi("getStaff", { id });

  if (staffData === undefined) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  const staff = staffData as StaffDto;

  const editAction: WebActionMenuItemProps = {
    title: "Edit",
    onClick: () => staffActions.showUpdateForm(staff),
    permission: "staff:manage",
  };

  const actions: WebActionMenuProps["items"] = [
    ...(breakpoints.md ? [] : [editAction]),
    {
      title: staff.archivedAt ? "Restore" : "Archive",
      onClick: () => {
        staff.archivedAt
          ? staffActions.unarchive(staff).then()
          : staffActions.archive(staff).then();
      },
      permission: "staff:manage",
    },
    {
      title: "Delete",
      destructive: true,
      onClick: () => {
        staffActions.deleteOne(staff).then(() => {
          Router.push("StaffList");
        });
      },
      permission: "staff:manage",
    },
  ];

  const crumbs: Breadcrumb[] = [
    {
      text: "Staff",
      onClick: () => {
        Router.push("StaffList");
      },
    },
    {
      text: getFullName(staff),
    },
  ];

  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        isDesktop={breakpoints.md}
        crumbs={crumbs}
        isArchived={staff.archivedAt !== null}
        header={
          <Profile
            staff={staff}
            uploadAction={() => staffActions.showAvatarUploadSheet(staff)}
          />
        }
        editAction={() => staffActions.showUpdateForm(staff)}
        restoreAction={() => staffActions.unarchive(staff).then()}
        archivedEntityName={"staff member"}
        permissions={{ edit: "staff:manage", restore: "staff:manage" }}
        actions={actions}>
        <DetailPageGrid>
          <DetailPageGridLeftColumn>
            <StaffScheduleWidget staffId={staff.id} />
          </DetailPageGridLeftColumn>
          <DetailPageGridRightColumn>
            <div className={"w-full"}>
              <CustomFieldResponsesCard
                entityId={staff.id}
                defaultEntity={CustomFieldEntity.Staff}
                CustomFieldResponsesContent={CustomFieldResponsesContent}
                customFieldActions={customFieldActions}
                managePermission="staff:manage"
              />
            </div>

            <AddressCard entityId={staff.id} updatePermission="staff:manage" />
          </DetailPageGridRightColumn>
        </DetailPageGrid>
      </DetailPageLayout>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "staff:view",
  StaffDetailsPage,
  "Staff details",
);

export { PermissionChecked as StaffDetailsPage };
