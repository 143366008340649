import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  BillDto,
  CreateFromBillTransactionRequest,
  SeasonDto,
} from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { usePreviewTuitionFirstStepForm } from "./usePreviewTuitionFirstStepForm";
import { FormDefinition } from "../../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { MultiStepsHeaderStep } from "../../../forms/multiStepFormProps";
import { getPlatformFunctions } from "../../../platformSpecific";
import { useFormActions } from "../../FormActionsProvider";

export const previewTuitionFormSteps: MultiStepsHeaderStep[] = [
  { index: 1, label: "Select billing period" },
  { index: 2, label: "Review" },
];
export type PreviewTuitionFormStepType = 1 | 2;

export type PreviewTuitionFormSchema = CreateFromBillTransactionRequest;

export type SubmitPreviewMonthForm = (
  formData: PreviewTuitionFormSchema,
) => Promise<PreviewTuitionFormSchema | AggregateTransactionDto>;

export type ExitTuitionPreviewForm = () => Promise<object>;

interface PreviewTuitionFormData {
  formHook: FormDefinition<PreviewTuitionFormSchema>;
  step: PreviewTuitionFormStepType;
  setStep: Dispatch<SetStateAction<PreviewTuitionFormStepType>>;
  selectedSeason: SeasonDto | undefined;
  billingMonth: string | undefined;
  billData: BillDto | undefined;
  onSuccess: () => void;
  submitFunction: SubmitPreviewMonthForm | ExitTuitionPreviewForm;
}

export const usePreviewTuitionForm = (
  familyId: string,
): PreviewTuitionFormData => {
  const builder = new FormDefinitionBuilder<PreviewTuitionFormSchema>();
  const [billData, setBillData] = useState<BillDto | undefined>(undefined);
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<AggregateTransactionDto>({
    entity: "transaction",
  });
  const { setAllowCreateAdditional, setCreateButtonText, setResetAfterSubmit } =
    useFormActions();
  const [step, setStep] = useState<PreviewTuitionFormStepType>(1);
  const { selectedSeason, billingMonth } = usePreviewTuitionFirstStepForm(
    builder,
    step,
  );

  const [formValues, setFormValues] =
    useState<Partial<PreviewTuitionFormSchema>>();

  const loadBillData = async (seasonId: string, billingMonth: string) => {
    return await api.bills.getBill({
      familyId,
      seasonId,
      billingMonth,
    });
  };

  //it is important to use useEffect here to prevent the stack trace error
  useEffect(() => {
    setAllowCreateAdditional(false);
    setCreateButtonText("Preview");
    setResetAfterSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = match(step)
    .with(1, () => {
      return {
        onSuccess: () => {
          setStep(2);
          setCreateButtonText("Exit");
        },
        submitFunction: async (formData: PreviewTuitionFormSchema) => {
          const newValues = { ...formData, ...formValues };
          setFormValues(newValues);

          setBillData(
            await loadBillData(formData.seasonId, formData.billingMonth),
          );

          return Promise.resolve(newValues);
        },
      };
    })
    .with(2, () => {
      return {
        onSuccess: () => defaultActions.hideForm(),
        submitFunction: () => Promise.resolve({}),
      };
    })
    .run();

  return {
    formHook:
      builder.getDefinition() as FormDefinition<PreviewTuitionFormSchema>,
    billingMonth,
    billData,
    step,
    setStep,
    selectedSeason,
    ...data,
  };
};
