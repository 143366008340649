import { FocusEvent, useEffect, useRef, useState } from "react";

import Calendar from "react-calendar";
import DatePicker from "react-date-picker";

import { useAuthState } from "shared/components";
import {
  colors,
  dateIsBetween,
  formatWeekDay,
  interpretTwoDigitYear,
  toLocalDateObject,
  toLocalIsoDateString,
} from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";
import "./Calendar.css";
import "./DatePicker.css";

export type ActualDatePickerValue = Date | null;
export type DatePickerValue =
  | ActualDatePickerValue
  | [ActualDatePickerValue, ActualDatePickerValue];
export type BaseDatePickerValue = string | null;

export interface BaseDatePickerProps {
  name?: string;
  error?: boolean;
  onBlur?: () => void;
  locale?: "en-US" | "en-GB" | "en-CA" | "en-AU" | "en-NZ" | "zh-TW" | "ja-JP";
  value?: BaseDatePickerValue;
  onChange?: (date: BaseDatePickerValue) => void;
  minDate?: BaseDatePickerValue;
  maxDate?: BaseDatePickerValue;
}

export const BaseDatePicker = ({
  name,
  error,
  locale,
  value = null,
  onChange,
  onBlur,
  minDate,
  maxDate,
}: BaseDatePickerProps) => {
  const defaultMinDate = new Date(new Date().getFullYear() - 95, 0, 1);
  const defaultMaxDate = new Date(new Date().getFullYear() + 95, 0, 1);

  // A Date representation of the externally-supplied value (in local timezone)
  const externalDate = value ? toLocalDateObject(value) : null;

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerFocus, setContainerFocus] = useState(false);
  const [internalValue, setInternalValue] = useState(externalDate);
  const [activeStartDate, setActiveStartDate] = useState(externalDate);
  const [isValidInternalValue, setIsValidInternalValue] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const errorClass = error ? "datePickerHasError" : "";
  const focusClass = containerFocus ? "datePickerFocused" : "";
  const calendarRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLInputElement>(null);

  // A string representation of our internal value
  const internalString = internalValue
    ? toLocalIsoDateString(internalValue)
    : null;

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Node;
    if (calendarRef.current && !calendarRef.current.contains(target)) {
      setShowCalendar(false);
    }
  };
  const onChangeHandler = (date: DatePickerValue) => {
    const actualDate = date as ActualDatePickerValue;
    if (
      actualDate &&
      dateIsBetween(
        actualDate,
        minDate ? toLocalDateObject(minDate) : defaultMinDate,
        maxDate ? toLocalDateObject(maxDate) : defaultMaxDate,
      )
    ) {
      setInternalValue(actualDate);
      setActiveStartDate(actualDate);
      setIsValidInternalValue(true);
    }
  };
  const onCalendarChangeHandler = (date: DatePickerValue) => {
    // The date returned from the third party Calendar component is set to
    // 00:00 in ** the current user's browser timezone **.
    const actualDate = date as ActualDatePickerValue;
    onChangeHandler(actualDate);
    onChange && onChange(actualDate ? toLocalIsoDateString(actualDate) : null);
    onBlur && onBlur();
    setShowCalendar(false);
  };
  const onInvalidChangeHandler = () => {
    setIsValidInternalValue(false);
  };
  const handleOnBlur = () => {
    if (!isValidInternalValue) {
      setInternalValue(null);
      onChange && onChange(null);
    } else if (internalValue) {
      // Reformat the year to so 23 would be interpreted as something like 2023
      const newDate = new Date(internalValue);
      const newYear = newDate.getFullYear();
      const changedYear = interpretTwoDigitYear(newYear);
      newDate.setFullYear(changedYear);
      if (onChange) {
        onChange(toLocalIsoDateString(newDate));
      } else {
        setInternalValue(newDate);
      }
    } else {
      if (onChange) {
        onChange(null);
      } else {
        setInternalValue(null);
      }
    }
    onBlur && onBlur();
  };

  useEffect(
    () => {
      // To avoid infinite loops, we need to compare the external and internal values as strings (or nulls)
      if (value !== internalString) {
        setInternalValue(externalDate);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  useEffect(() => {
    setActiveStartDate(internalValue);
  }, [internalValue]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const companyLocale =
    useAuthState().account?.company?.settings?.general.locale;

  const handleContainerFocus = () => setContainerFocus(true);

  const handleContainerBlur = (event: FocusEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.relatedTarget)
    ) {
      setContainerFocus(false);
      handleOnBlur();
    }
  };

  return (
    <div
      className={"relative"}
      data-name={name}
      ref={containerRef}
      onBlur={handleContainerBlur}
      onFocus={handleContainerFocus}>
      <DatePicker
        onKeyDown={(evt: KeyboardEvent) => {
          if (evt.key === "Enter") {
            submitButtonRef.current?.focus();
            const enterEvent = new KeyboardEvent("keydown", {
              bubbles: true,
              cancelable: true,
              key: "Enter",
            });
            submitButtonRef.current?.dispatchEvent(enterEvent);
          }
        }}
        className={[errorClass, focusClass, "w-full"]}
        locale={locale ?? companyLocale}
        onInvalidChange={onInvalidChangeHandler}
        onChange={onChangeHandler}
        value={internalValue}
        calendarIcon={
          // calendarIcon is a slot given by react-date-picker, it is wrapped with a button, so without heavily modifying the lib,
          // we can't simply use an icon button here. For now treat this as special case and just use one
          internalValue ? (
            <div data-testid={"closeIcon"} className="-mr-2">
              <div
                onClick={() => {
                  onCalendarChangeHandler(null);
                }}
                className={"hover:overlay-grey-lighter rounded p-1.5"}>
                <Icon
                  size={20}
                  color={colors.grey["500"]}
                  name={"closeOutline"}
                />
              </div>
            </div>
          ) : (
            <div className="-mr-2">
              <div
                onClick={() => setShowCalendar(!showCalendar)}
                className={"hover:overlay-grey-lighter rounded p-1.5"}
                data-testid={"calendarIcon"}>
                <Icon
                  size={20}
                  name={"calendarClearOutline"}
                  color={colors.grey["500"]}
                />
              </div>
            </div>
          )
        }
        monthPlaceholder={"mm"}
        yearPlaceholder={"yyyy"}
        dayPlaceholder={"dd"}
        shouldOpenCalendar={() => {
          return false;
        }}
      />
      {showCalendar && (
        <div
          ref={calendarRef}
          data-testid={"calendar"}
          className={"absolute left-0 top-full z-10 bg-white"}>
          <Calendar
            value={externalDate}
            activeStartDate={activeStartDate ?? undefined}
            onActiveStartDateChange={({ activeStartDate }) => {
              setActiveStartDate(activeStartDate);
            }}
            formatShortWeekday={formatWeekDay}
            onChange={onCalendarChangeHandler}
            nextLabel={<Icon name={"chevronForwardOutline"} />}
            prevLabel={<Icon name={"chevronBackOutline"} />}
            minDate={minDate ? toLocalDateObject(minDate) : defaultMinDate}
            maxDate={maxDate ? toLocalDateObject(maxDate) : defaultMaxDate}
          />
        </div>
      )}
      {/*This input exists so when user press enter, we manually dispatch an enter event on this, to trigger a form submit,
      and for some reason putting this input before <DatePicker> will cause it to trigger onChange when user just click on the date picker*/}
      <input ref={submitButtonRef} className={"absolute -left-[100vw]"} />
    </div>
  );
};
