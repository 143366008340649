import { ReactNode } from "react";

import { UseFormReturn } from "react-hook-form";

import {
  BaseInputProps,
  BaseInputValue,
  cn,
  DatatableFilter,
  FilterFormDefinition,
} from "shared/lib";

import { DetailCard } from "./DetailCard";
import { GroupListFilter } from "./GroupListFilter";
import { ClassScheduleFormSchema } from "../../../../formDefinitions/classScheduleFilterForm";
import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { ContentPlaceholder } from "../../../../ui/ContentPlaceholder";
import { SearchInput } from "../../../common/SearchInput";

export interface GroupedListProps<T> {
  isLoading: boolean;
  title: string;
  header: ReactNode;
  beforeContent?: ReactNode;
  items: T[];
  renderItem: (item: T) => ReactNode;
  groups: GroupedListGroupProps<T>[];
  searchValue?: BaseInputValue;
  onSearch?: (search: BaseInputValue) => void;
  filterValues: DatatableFilter[];
  filterForm: FilterFormDefinition<object, object>;
  onFilter?: (filters: DatatableFilter[]) => void;
  BaseInput: (props: BaseInputProps) => ReactNode;
  showWebSize?: boolean;
  open: ({
    title,
    onClose,
    side,
    content,
    footer,
  }: {
    title: string;
    content: ReactNode;
    footer: ReactNode;
    onClose: () => void;
    side: "right" | "top" | "bottom" | undefined;
  }) => void;
  close: () => void;
  DatatableFiltersSheetFooter: React.ComponentType<{
    onApply: () => void;
    onClear: () => void;
  }>;
  form: React.JSX.Element;
  formHandlers: UseFormReturn<ClassScheduleFormSchema>;
}

export interface GroupedListGroupProps<T> {
  title: (groupItems: T[]) => string;
  titleAddition?: (groupItems: T[]) => string;
  groupBy: (item: T) => boolean;
  onAdd?: () => void;
}

interface ItemsGroup<T> {
  title: string;
  titleAddition?: string;
  items: T[];
  onAdd?: () => void;
}

export const GroupedList = <T,>({
  isLoading,
  title,
  header,
  beforeContent,
  items,
  renderItem,
  groups,
  searchValue,
  onSearch,
  filterValues,
  filterForm,
  onFilter,
  BaseInput,
  showWebSize = false,
  open,
  close,
  DatatableFiltersSheetFooter,
  form,
  formHandlers,
}: GroupedListProps<T>) => {
  const { View } = useGenericComponents();
  const groupedItems: ItemsGroup<T>[] = groups.map(group => {
    const groupItems = items.filter(group.groupBy);

    return {
      title: group.title(groupItems),
      titleAddition: group.titleAddition
        ? group.titleAddition(groupItems)
        : undefined,
      items: groupItems,
      onAdd: group.onAdd,
    };
  });

  const isAnyFilteringApplied =
    (filterValues ?? []).length > 0 ||
    (searchValue?.toString() ?? "").length > 0;

  return (
    <DetailCard title={title}>
      <View className={"px-2"}>
        <View className={"pb-6"}>{header}</View>

        <View className={"flex flex-row justify-between space-x-4"}>
          <View className={"flex-grow"}>
            <SearchInput
              height={showWebSize ? "sm" : "md"}
              value={searchValue}
              onChange={onSearch}
              BaseInput={BaseInput}
            />
          </View>
          <View>
            {filterForm && onFilter ? (
              <GroupListFilter
                filterForm={filterForm}
                onFilter={onFilter}
                appliedFilters={filterValues || []}
                showWebSize={showWebSize}
                open={open}
                close={close}
                DatatableFiltersSheetFooter={DatatableFiltersSheetFooter}
                form={form}
                formHandlers={formHandlers}
              />
            ) : null}
          </View>
        </View>

        {beforeContent ? <View>{beforeContent}</View> : null}

        <GroupedListContent
          isAnyFilteringApplied={isAnyFilteringApplied}
          groupedItems={groupedItems}
          isLoading={isLoading}
          renderItem={renderItem}
          showWebSize={showWebSize}
        />
      </View>
    </DetailCard>
  );
};

const GroupedListContent = <T,>({
  isAnyFilteringApplied,
  groupedItems,
  isLoading,
  renderItem,
  showWebSize,
}: {
  isAnyFilteringApplied: boolean;
  groupedItems: ItemsGroup<T>[];
  isLoading: boolean;
  renderItem: (item: T) => ReactNode;
  showWebSize: boolean;
}) => {
  const { IconButton, Text, View, Loader, ProtectedOverlay } =
    useGenericComponents();
  if (isLoading) {
    return (
      <View className={"pt-6"}>
        <View className={"flex w-full flex-1 items-center justify-center"}>
          <Loader />
        </View>
      </View>
    );
  }
  if (
    isAnyFilteringApplied &&
    groupedItems.every(group => group.items.length === 0)
  ) {
    return (
      <View className={"pt-6"}>
        <ContentPlaceholder
          icon={"searchOutline"}
          title={"We couldn't find what you’re looking for"}
          description={"Try broadening your search"}
        />
      </View>
    );
  }

  return (
    <View>
      {groupedItems.map((group, index) => (
        <View
          key={`list-group-${index}`}
          className={cn("pb-4", {
            "border-t border-grey-300 ": index !== 0,
          })}>
          <View
            className={cn("flex flex-row items-center justify-between pt-6", {
              "pb-2": group.items.length === 0,
              "pb-3": group.items.length > 0,
            })}>
            <View
              className={
                "flex flex-grow justify-between text-sm text-grey-600 md:flex-grow-0"
              }>
              <Text className={"mr-1"}>{group.title}</Text>
              {group.titleAddition ? (
                <Text className={"text-sm text-grey-600"}>
                  {group.titleAddition}
                </Text>
              ) : null}
            </View>
            <View className={"hidden md:flex"}>
              {group.onAdd ? (
                <View>
                  <ProtectedOverlay permission="schedule:manage">
                    <IconButton
                      icon={"addOutline"}
                      size={showWebSize ? "sm" : "lg"}
                      variant={"secondary-outline"}
                      onClick={group.onAdd}
                    />
                  </ProtectedOverlay>
                </View>
              ) : null}
            </View>
          </View>

          <View className={"mb-3 flex flex-col space-y-3"}>
            {group.items.map((item, itemIndex) => (
              <View key={`list-group-${index}-item-${itemIndex}`}>
                {renderItem(item)}
              </View>
            ))}
          </View>

          <View className={"flex pt-4 md:hidden"}>
            {group.onAdd ? (
              <View>
                <ProtectedOverlay permission="schedule:manage">
                  <IconButton
                    icon={"addOutline"}
                    variant={"secondary-outline"}
                    onClick={group.onAdd}
                  />
                </ProtectedOverlay>
              </View>
            ) : null}
          </View>
        </View>
      ))}
    </View>
  );
};
