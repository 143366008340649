import { ComponentProps } from "react";

import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  pricingSchemeDescription,
  pricingSchemeTypeIcon,
} from "../../../pricingSchemeUtils";
import { enumLabel } from "../../../translateUtils";
import { SeasonImplementedActions } from "../../actions/useSharedSeasonActions";
import { useGenericComponents } from "../../GenericComponentsProvider";

export const PricingTiersContentCard = ({
  season,
  seasonActions,
}: {
  season: SeasonDto;
  seasonActions: SeasonImplementedActions;
}) => {
  const { BaseCard, ProtectedOverlay } = useGenericComponents();
  const defaultProps: ComponentProps<typeof BaseCard> = {
    title: enumLabel("pricingScheme", season.pricingScheme),
    titleColor: "grey-900",
    titleWeight: 600,
    titleSize: 16,
    description: pricingSchemeDescription[season.pricingScheme],
    icon: pricingSchemeTypeIcon[season.pricingScheme],
    iconPosition: "left-framed",
    headerAction: {
      text: "Edit",
      onClick: () => {
        seasonActions.showPricingSchemeUpdateForm(season);
      },
    },
    onClick: () => {
      seasonActions.showPricingSchemeUpdateForm(season);
    },
  };

  return (
    <ProtectedOverlay permission={"schedule:manage"}>
      <BaseCard {...defaultProps} />
    </ProtectedOverlay>
  );
};
