import {
  CourseDto,
  PricingScheme,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { enumLabel } from "../../../../translateUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const CoursePreviewPrice = ({
  course,
  season,
  fallBack,
  onClick,
}: {
  course: CourseDto;
  season: SeasonDto;
  fallBack?: string;
  onClick?: () => void;
}) => {
  const { Text, View, Pressable } = useGenericComponents();

  if (!course.price || season.pricingScheme == PricingScheme.ClassCount) {
    return fallBack ? (
      onClick ? (
        <Pressable onClick={() => onClick()}>
          <Text className={"text-label-400 text-grey-600"}>{fallBack}</Text>
        </Pressable>
      ) : (
        <View>
          <Text className={"text-label-400 text-grey-600"}>{fallBack}</Text>
        </View>
      )
    ) : null;
  }

  return (
    <View className={"flex flex-row items-center"}>
      <Text className={"text-grey-900 font-semibold text-body-400"}>
        {formatMoneyFromInteger(course.price ?? 0)}
      </Text>
      <Text className={"text-grey-600 text-body-400"}>
        /{enumLabel("pricingModelWithoutPrefix", season.pricingModel)}
      </Text>
    </View>
  );
};
