import { FC, ReactNode } from "react";

import { WaitingListDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { fetchWaitingLists } from "../../data/waitingListsData";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { formatDateTime } from "../../intlFormatter";
import { getFullName } from "../../personUtil";
import { displayStudentAge } from "../../studentUtils";
import { ExtendedWaitingListDto } from "../formDefinitions/waitingListForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { Chip } from "../ui/Chip";

type Dto = WaitingListDto;
type RowDto = ExtendedWaitingListDto & {
  id: string;
};
type FilterForm = object;

interface UseClassWaitingListDatatableParams {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, object, RowDto> }>;
  api: Api;
  classId: string;
  goToStudent: (id: string) => void;
  enrollForClass: (item: RowDto) => void;
  deleteOne: (item: RowDto) => void;
  showAddForm?: () => void;
}

export const useClassWaitingListDatatable = ({
  Datatable,
  api,
  classId,
  goToStudent,
  enrollForClass,
  deleteOne,
  showAddForm,
}: UseClassWaitingListDatatableParams): { datatable: ReactNode } => {
  const { View } = useGenericComponents();

  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    return fetchWaitingLists({ api, request, classId, selectAll: true });
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: `class-waiting-list-${classId}`,
    permissions: {
      archive: "enrolments:manage",
      create: "enrolments:manage",
      delete: "enrolments:manage",
      edit: "enrolments:manage",
      restore: "enrolments:manage",
    },
    createLabel: "Add",
    createAction: showAddForm,
    rowActions: {
      title: item => item.class.entity.name,
      click: item => goToStudent(item.student.id),
      additionalRowActions: () => [
        {
          icon: "openOutline",
          label: "Go to student",
          onClick: item => goToStudent(item.student.id),
          permission: "members:view",
        },
        {
          title: "Enroll",
          actions: [
            {
              icon: "calendarOutline",
              label: "Enroll for class",
              onClick: enrollForClass,
              permission: "enrolments:manage",
            },
          ],
        },
        {
          icon: "trashOutline",
          label: "Remove from waiting list",
          onClick: deleteOne,
          isDestructive: true,
          permission: "enrolments:manage",
        },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "This class does not have any students waiting.",
        description: "",
      },
    },
    hasPagination: false,
    hasSearch: true,
    showTotalRecords: true,
    placeholdersCount: 2,
    fetchData,
    columns: [
      {
        label: "Student",
        placeholder: "tile",
        value: row => ({
          type: "tile",
          title: getFullName(row.item.student),
          subtitle: displayStudentAge(row.item.student),
          image: row.item.student.profilePicture ?? undefined,
        }),
      },
      {
        label: "Date requested",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDateTime(row.item.createdAt, "dayMonthYear"),
        }),
        sortableField: "createdAt",
      },
    ],
    mobileColumn: {
      hasImage: true,
      title: row => getFullName(row.item.student),
      subtitle: row => displayStudentAge(row.item.student),
      image: row => row.item.student.profilePicture ?? undefined,
      children: row => (
        <View className="flex flex-row items-start">
          <Chip
            size="sm"
            variant="secondary"
            label={formatDateTime(row.item.createdAt, "dayMonthYear")}
          />
        </View>
      ),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
