import { createContext } from "react";

import { FeatureName } from "@justraviga/classmanager-sdk";

export type Feature = `${FeatureName}`;

export type FeatureContext = {
  hasFeature: (feature: Feature) => boolean;
};

export const FeatureContext = createContext<FeatureContext>({
  hasFeature: () => true,
});
