import { ReactNode } from "react";

import { cn, IconName, Permission } from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

type ContactCardItemWithActionProps = {
  mainIcon: IconName;
  actions: {
    icon: IconName;
    action?: () => void;
    permission?: Permission;
  }[];
  content: string | null | ReactNode;
  shouldShowActions: boolean;
};

export const ContactCardItemWithAction = ({
  mainIcon,
  actions,
  content,
  shouldShowActions,
}: ContactCardItemWithActionProps) => {
  const contentIsNode = typeof content === "object" && content !== null;
  return (
    <div className="flex h-8 flex-row items-center justify-between gap-4">
      <div>
        <Icon size={24} name={mainIcon} />
      </div>
      <div className={"flex-grow truncate"}>
        {contentIsNode ? (
          content
        ) : content ? (
          <span>{content}</span>
        ) : (
          <span>-</span>
        )}
      </div>
      {content && (
        <div
          className={cn("flex flex-row gap-x-4", {
            hidden: !shouldShowActions,
          })}>
          {actions.map(({ icon, action, permission }, key) => (
            <ProtectedOverlay permission={permission} key={key}>
              <IconButton
                variant={"secondary-outline"}
                icon={icon}
                size={"sm"}
                onClick={action}
              />
            </ProtectedOverlay>
          ))}
        </div>
      )}
    </div>
  );
};

export const ContactCardItem = ({
  icon,
  text,
}: {
  icon: IconName;
  text: string | null;
}) => {
  return (
    <div className={"flex h-8 flex-row items-center gap-x-4"}>
      <Icon size={24} name={icon} />
      {<span>{text ?? "-"}</span>}
    </div>
  );
};
