import { TrialDto } from "@justraviga/classmanager-sdk";

import { useTrialActions } from "shared/components";
import { LessonStudentRow } from "shared/lib";

import { useStudentActions } from "@/modules/common/students/useStudentActions";
import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Router } from "@/routing/router";

export const StudentRowActionMenu = ({
  record,
}: Omit<LessonStudentRow, "isCancelled" | "isDesktop" | "season">) => {
  const { showUpdateForm: editTrial } = useTrialActions();
  const { makeUpLesson } = useStudentActions();

  const actionsItems: ActionMenuItemProps[] = [
    {
      title: "Go to student",
      leftIcon: "personOutline",
      onClick: () => Router.push("StudentDetails", { id: record.student.id }),
      permission: "members:view",
    },
  ];

  if (record.trial !== undefined) {
    actionsItems.push({
      title: "Trial",
      group: {
        items: [
          {
            title: "Edit",
            leftIcon: "createOutline",
            onClick: () => editTrial(record.trial as TrialDto),
            permission: "enrolments:manage",
          },
        ],
      },
    });
  } else {
    actionsItems.push({
      title: "Enrollment",
      group: {
        items: [
          {
            title: "Assign make up lesson",
            leftIcon: "swapHorizontalOutline",
            onClick: () => makeUpLesson(record.student),
            permission: "enrolments:manage",
          },
        ],
      },
    });
  }

  return (
    <ActionMenu
      width={"w-80"}
      showActionsAsBottomSheetOnMobile={true}
      header={
        <h3 className={"truncate text-heading6-600 text-grey-900"}>
          {record.student.firstname} {record.student.lastname}
        </h3>
      }
      trigger={
        <IconButton variant="standard" icon={"ellipsisHorizontal"} size="lg" />
      }
      items={[
        ...actionsItems,
        {
          title: "Billing",
          group: {
            items: [
              {
                title: "Add fee for this student",
                leftIcon: "cashOutline",
                onClick: () =>
                  Router.push("TransactionCreate", {
                    familyId: record.student.familyId,
                  }),
                permission: "financial:manage",
              },
            ],
          },
        },
      ]}
    />
  );
};
