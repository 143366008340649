import { SeasonDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import {
  makeSeasonUpdateRequest,
  useSeasonFormDefinition,
} from "../formDefinitions/seasonForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { SeasonCreateForm } from "../modules/company/seasons/SeasonCreateForm";
import {
  DefaultEntityActions,
  EntityActions,
} from "../useDefaultEntityActions";

export type SeasonImplementedActions = Omit<
  EntityActions<Model>,
  "deleteMany" | "archiveMany" | "unarchiveMany"
> & {
  showPricingSchemeUpdateForm: (entity: Model) => void;
  showPreview: (entity: Model) => void;
};

type Model = SeasonDto;

interface UseSharedSeasonActionsParams {
  defaultActions: DefaultEntityActions<Model>;
  goToDetailsPage: (season: Model) => void;
  closeSheet: () => void;
  setTitle: (title: string) => void;
}

export const useSharedSeasonActions = ({
  defaultActions,
  goToDetailsPage,
  closeSheet,
  setTitle,
}: UseSharedSeasonActionsParams) => {
  const { GenericForm } = useGenericComponents();
  const { api } = getPlatformFunctions();

  const {
    setCreateButtonText,
    setAllowCreateAdditional,
    shouldCloseOnSuccess,
  } = useFormActions();

  return {
    showCreateForm: () => {
      return defaultActions.showCreateForm({
        form: (
          <SeasonCreateForm
            onSuccess={defaultActions.makeOnCreateSuccess({
              redirect: goToDetailsPage,
            })}
            shouldCloseOnSuccess={shouldCloseOnSuccess}
            closeSheet={closeSheet}
            setTitle={setTitle}
            setCreateButtonText={setCreateButtonText}
            setAllowCreateAdditional={setAllowCreateAdditional}
          />
        ),
      });
    },
    showUpdateForm: (entity: Model) => {
      return defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeSeasonUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={useSeasonFormDefinition}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      });
    },

    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.seasons.deleteSeason({ id: entity.id }),
      ),

    archive: (entity: Model) =>
      defaultActions.archive(() =>
        api.seasons.archiveSeason({ id: entity.id }),
      ),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.seasons.unarchiveSeason({ id: entity.id }),
      ),
  };
};
