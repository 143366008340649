import { ReactNode } from "react";

import { ActionMenu } from "@shared/components/action-menu/ActionMenu";

import {
  bannerIcon,
  BannerProps,
  bannerVariantContainerClass,
  bannerVariantIconColor,
  bannerVariantTextColorClass,
  cn,
} from "shared/lib";

import { IconButton } from "@/modules/common/ui/button/IconButton";
import { Icon } from "@/modules/common/ui/icon/Icon";

interface BannerAction {
  text: string;
  onClick: () => void;
}

const ActionButton = ({
  action,
  textColor,
  loading,
}: {
  action: BannerAction;
  textColor: string;
  loading: boolean;
}) => (
  <button
    className={cn(
      "text-left text-label-600 underline underline-offset-4",
      textColor,
      {
        "cursor-wait": loading,
      },
    )}
    onClick={loading ? undefined : action.onClick}
    disabled={loading}>
    {action.text}
  </button>
);

export const Banner = ({
  icon,
  action,
  actionSlot,
  actionMenuTitle,
  actionMenuItems,
  onClose,
  className = "",
  title,
  content,
  variant = "default",
  itemPosition = "start",
  loading = false,
}: BannerProps) => {
  const variantClassName = bannerVariantContainerClass[variant];
  const textColor = bannerVariantTextColorClass[variant];
  const iconColor = bannerVariantIconColor[variant];

  const hasCloseButton = !!onClose;

  /**
   * Ensure the content is wrapped if it's been given as a basic string
   */
  if (typeof content === "string") {
    content = <Banner.content textColor={textColor}>{content}</Banner.content>;
  }

  /**
   * Set default icon if variant has an icon associated with it
   */
  if (!icon) {
    icon = bannerIcon[variant];
  }

  const actionMenu =
    actionSlot ||
    (actionMenuItems && (
      <ActionMenu
        title={actionMenuTitle}
        size={"lg"}
        trigger={
          <IconButton
            icon={"ellipsisHorizontal"}
            variant={"standard"}
            size={"xs"}
          />
        }
        items={actionMenuItems}
      />
    ));

  return (
    <div
      className={cn(
        "flex w-full flex-row justify-between gap-4 rounded border px-4 py-3",
        itemPosition === "center" ? "items-center" : "items-start",
        variantClassName,
        className,
        { "p-2 pl-4": hasCloseButton },
      )}>
      {icon && (
        <div className={cn("flex-none", { "py-1": hasCloseButton })}>
          <Icon name={icon.name} color={icon.color} size={24} />
        </div>
      )}

      <div className={cn("flex-grow", textColor, { "py-1": hasCloseButton })}>
        {title && <Banner.title textColor={textColor}>{title}</Banner.title>}
        {content}
        {action && (
          <div
            className={cn("flex-initial pt-2 md:hidden", {
              "py-1": hasCloseButton,
            })}>
            <ActionButton
              action={action}
              textColor={textColor}
              loading={loading}
            />
          </div>
        )}
      </div>

      {action && (
        <div
          className={cn("hidden md:block md:flex-none", {
            "py-1": hasCloseButton,
          })}>
          <ActionButton
            action={action}
            textColor={textColor}
            loading={loading}
          />
        </div>
      )}

      {
        /**
         * Allow for a custom slot to be passed in
         */
        !action && actionMenu
      }

      {hasCloseButton && (
        <IconButton
          icon={"closeOutline"}
          variant={"standard"}
          onClick={onClose}
          color={iconColor}
          size={"sm"}
        />
      )}
    </div>
  );
};

Banner.title = ({
  textColor,
  children,
}: {
  textColor?: string;
  children: ReactNode;
}) => <p className={cn("mb-1 text-body-600", textColor)}>{children}</p>;

Banner.content = ({
  textColor,
  children,
}: {
  textColor?: string;
  children: ReactNode;
}) => <p className={cn("pt-[2px] text-label-400", textColor)}>{children}</p>;
