import { WaiverType } from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { UseApi } from "../components/apiQueryFactory";

export const useWaivers = (useApi: UseApi) => {
  return useApi("listWaiver", {
    selectAll: true,
  });
};

export const useTermsAndConditions = (useApi: UseApi) => {
  const { data: waivers, ...rest } = useWaivers(useApi);
  const { status } = rest;

  return match(status)
    .with("pending", () => ({
      termsAndConditions: null,
      ...rest,
    }))
    .with("error", () => ({
      termsAndConditions: null,
      ...rest,
    }))
    .with("success", () => {
      const termsAndConditions = waivers?.data.find(
        waiver => waiver.type === WaiverType.TermsAndConditions,
      );
      if (!termsAndConditions) {
        return {
          termsAndConditions: null,
          ...rest,
          error: new Error("Terms and conditions not found"),
        };
      }

      return {
        termsAndConditions,
        ...rest,
      };
    })
    .exhaustive();
};

export const usePrivacyPolicy = (useApi: UseApi) => {
  const { data: waivers, ...rest } = useWaivers(useApi);
  const { status } = rest;

  return match(status)
    .with("pending", () => ({
      privacyPolicy: null,
      ...rest,
    }))
    .with("error", () => ({
      privacyPolicy: null,
      ...rest,
    }))
    .with("success", () => {
      const privacyPolicy = waivers?.data.find(
        waiver => waiver.type === WaiverType.PrivacyPolicy,
      );
      if (!privacyPolicy) {
        return {
          privacyPolicy: null,
          ...rest,
          error: new Error("Privacy policy not found"),
        };
      }

      return {
        privacyPolicy,
        ...rest,
        error: null,
      };
    })
    .exhaustive();
};
