import { FC, ReactNode } from "react";

import { Api } from "../../api";
import { fetchWaitingLists } from "../../data/waitingListsData";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { formatDateTime, formatTime } from "../../intlFormatter";
import { getFullName } from "../../personUtil";
import { displayStudentAge } from "../../studentUtils";
import { enumLabel } from "../../translateUtils";
import { UseApi } from "../apiQueryFactory";
import {
  useWaitingListFilterForm,
  WaitingListFilterFormSchema,
} from "../formDefinitions/waitingListFilterForm";
import { ExtendedWaitingListDto } from "../formDefinitions/waitingListForm";

type RowDto = ExtendedWaitingListDto;

type FilterForm = WaitingListFilterFormSchema;

interface UseWaitingListDatatableParams {
  classId?: string;
  Datatable: FC<{
    configuration: DatatableConfiguration<RowDto, FilterForm>;
  }>;
  api: Api;
  useApi: UseApi;
  goToStudent: (id: string) => void;
  enrollForClass: (item: RowDto) => void;
  deleteOne: (item: RowDto) => Promise<void>;
  showCreateForm: () => void;
  renderMobileDetails: (item: RowDto) => ReactNode;
  renderClassDetails?: (item: RowDto) => ReactNode;
}

export const useWaitingListDatatable = ({
  classId,
  Datatable,
  api,
  useApi,
  goToStudent,
  enrollForClass,
  deleteOne,
  renderMobileDetails,
  showCreateForm,
  renderClassDetails,
}: UseWaitingListDatatableParams) => {
  const filterForm = useWaitingListFilterForm({ useApi });

  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    return fetchWaitingLists({ api, request, classId, selectAll: false });
  };

  const getRequestedDate = (createdAt: Date) => {
    return formatDateTime(createdAt, "dayMonthYear");
  };

  const getMobileSubtitle = (item: RowDto) => {
    return [
      enumLabel("weekDaysShort", item.class.entity.dayOfWeek),
      formatTime(item.class.entity.startTime, "hourMinute"),
      item.season.name,
    ].join(" • ");
  };

  const config: DatatableConfiguration<RowDto, FilterForm> = {
    id: "waitingList",
    title: "Waiting list",
    permissions: {
      archive: "enrolments:manage",
      create: "enrolments:manage",
      delete: "enrolments:manage",
      edit: "enrolments:manage",
      restore: "enrolments:manage",
    },
    createAction: showCreateForm,
    createLabel: "Add to waiting list",
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "No one waiting",
        description: "There are currently no students waiting to join classes.",
      },
    },
    hasPagination: true,
    placeholdersCount: 5,
    fetchData,
    rowActions: {
      title: item => getFullName(item.student),
      click: item => goToStudent(item.student.id),
      additionalRowActions: () => [
        {
          label: "Go to student",
          icon: "openOutline",
          onClick: item => goToStudent(item.student.id),
          permission: "members:view",
        },
        {
          title: "Enroll",
          actions: [
            {
              label: "Enroll for class",
              icon: "calendarOutline",
              onClick: enrollForClass,
              permission: "enrolments:manage",
            },
          ],
        },
        {
          label: "Remove from waiting list",
          icon: "trashOutline",
          onClick: deleteOne,
          isDestructive: true,
          hasDestructiveStyle: false,
          permission: "enrolments:manage",
        },
      ],
    },
    columns: [
      {
        label: "Student",
        placeholder: "tile",
        value: ({ item }) => {
          return {
            type: "tile",
            title: getFullName(item.student),
            subtitle: displayStudentAge(item.student),
            image: item.student.profilePicture || undefined,
          };
        },
      },
      {
        label: "Class",
        placeholder: "text",
        value: row => {
          return {
            type: "custom",
            children: renderClassDetails ? renderClassDetails(row.item) : <></>,
          };
        },
      },
      {
        label: "Date requested",
        placeholder: "text",
        value: ({ item }) => {
          return {
            type: "text",
            text: formatDateTime(item.createdAt, "dayMonthYearHourMinute"),
          };
        },
        sortableField: "createdAt",
      },
    ],
    mobileColumn: {
      hasImage: true,
      image: row => row.item.student.profilePicture ?? undefined,
      title: row => getFullName(row.item.student),
      children: row => renderMobileDetails(row.item),
    },
    filterForm,
  };

  return {
    datatable: Datatable({ configuration: config }),
    getMobileSubtitle,
    getRequestedDate,
  };
};
