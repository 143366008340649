import { Row, Table } from "@tanstack/react-table";

import { DatatableModelSelection } from "shared/lib";

import { DatatableCheckbox } from "@/modules/common/datatable/columns/DatatableCheckbox";
import { CheckboxCellPlaceholder } from "@/modules/common/datatable/placeholders/CheckboxCellPlaceholder";

export const checkboxColumnDef = (
  modelSelection: DatatableModelSelection<string>,
) => {
  return {
    id: "select",
    size: 28,
    meta: {
      align: "left",
      placeholder: <CheckboxCellPlaceholder />,
      onHeaderClick: <ModelDto,>(table: Table<Table<ModelDto>>) => {
        modelSelection.toggleAll(table.getCenterRows().map(row => row.id));
      },
      onRowClick: <ModelDto,>(row: Row<ModelDto>) => {
        modelSelection.toggle(row.id);
      },
    },
    header: <ModelDto,>({ table }: { table: Table<ModelDto> }) => {
      const allRowsSelected = modelSelection.hasAll(
        table.getCenterRows().map(row => row.id),
      );

      return (
        <DatatableCheckbox
          role={"datatable-header-checkbox"}
          checked={
            allRowsSelected
              ? allRowsSelected
              : modelSelection.isNotEmpty()
                ? "indeterminate"
                : false
          }
          onChange={() => {
            if (modelSelection.isNotEmpty()) {
              modelSelection.clear();
              return;
            }

            modelSelection.toggleAll(table.getCenterRows().map(row => row.id));
          }}
        />
      );
    },
    cell: <ModelDto,>({ row }: { row: Row<ModelDto> }) => {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <DatatableCheckbox
            role={"datatable-row-checkbox"}
            checked={modelSelection.has(row.id)}
            onChange={() => modelSelection.toggle(row.id)}
          />
        </div>
      );
    },
  };
};
