import { useEffect, useState } from "react";

import { SeasonDto } from "@justraviga/classmanager-sdk";

import { RegistrationWidget } from "@shared/components/modules/company/seasons/registration/RegistrationWidget";

import {
  Breadcrumb,
  ClassScheduleFormSchema,
  SeasonImplementedActions,
  SeasonScheduleWidget,
  useClassScheduleFilterForm,
} from "shared/components";
import {
  DatatableFilter,
  DatatableFilterOperator,
  FilterFormDefinition,
  groupListFilterDefaultValues,
  useBreakpoint,
  useSeasonDetailsData,
} from "shared/lib";

import { DatatableFiltersSheetFooter } from "@/modules/common/datatable/common/DatatableFiltersSheetFooter";
import { BaseInput } from "@/modules/common/form/BaseInput";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import { ScheduleClassCard } from "@/modules/company/classPlanner/classes/cards/ScheduleClassCard";
import { useCourseActions } from "@/modules/company/classPlanner/classes/useCourseActions";
import { SeasonPricingSchemeCard } from "@/modules/company/classPlanner/seasons/card/seasonPricing/SeasonPricingSchemeCard";
import { SeasonDetailsHeader } from "@/modules/company/classPlanner/seasons/headers/SeasonDetailsHeader";
import { useSeasonActions } from "@/modules/company/classPlanner/seasons/useSeasonActions";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const SeasonDetailsPage = ({ id }: { id: string }) => {
  const { md: showWebSize } = useBreakpoint();
  const seasonActions = useSeasonActions();
  const classActions = useCourseActions();
  const { isDesktop, season, pricingSchemeValues, locations, rooms, staff } =
    useSeasonDetailsData(id);
  const { openSheet, closeSheet } = useSheet();
  const filterForm = useClassScheduleFilterForm({
    classStaffMembers: staff,
    classLocations: locations,
    classRooms: rooms,
  });
  const [scheduleFilters, setScheduleFilters] = useState<DatatableFilter[]>([]);
  const { form, formHandlers } = useFormBuilder<ClassScheduleFormSchema>(
    filterForm.formDefinition,
    () => {},
    groupListFilterDefaultValues({
      filterForm: filterForm as FilterFormDefinition<object, object>,
      appliedFilters: scheduleFilters,
    }),
  );

  useEffect(() => {
    if (season.archivedAt) {
      setScheduleFilters(filters =>
        filters
          .filter(filter => filter.field !== "isArchived")
          .concat([
            {
              field: "isArchived",
              operator: DatatableFilterOperator.Equals,
              value: true,
            },
          ]),
      );
    } else {
      setScheduleFilters(filters =>
        filters.filter(filter => filter.field !== "isArchived"),
      );
    }
  }, [season.archivedAt]);

  const menuItems: ActionMenuItemProps[] = buildMenuItems(
    seasonActions,
    season,
    isDesktop,
  );

  // web only
  const crumbs = buildBreadcrumbs(season);

  // todo
  if (!season) {
    // we couldn't fetch the season
    // we should redirect to seasons list page
    return <div>Error</div>;
  }

  return (
    <DetailPageLayout
      permissions={{
        edit: "schedule:manage",
        restore: "schedule:manage",
      }}
      isDesktop={isDesktop}
      archivedEntityName={t("entity.season", { count: 1 })}
      restoreAction={() => seasonActions.unarchive(season).then()}
      editAction={() => seasonActions.showUpdateForm(season)}
      additionalActions={
        <ProtectedOverlay permission={"schedule:manage"}>
          <Button
            variant={"brand"}
            size={"sm"}
            onClick={() => classActions.showCreateForm(season)}
            text={"Create class"}
          />
        </ProtectedOverlay>
      }
      primaryAction={{
        icon: "addOutline",
        title: "Create",
        onClick: () => classActions.showCreateForm(season),
        permission: "schedule:manage",
      }}
      actions={menuItems}
      crumbs={crumbs}
      spacingVariant={"tight"}
      header={<SeasonDetailsHeader season={season} isDesktop={isDesktop} />}>
      <DetailPageGrid>
        <DetailPageGridLeftColumn spacingVariant={"tight"}>
          <div className={"w-full md:hidden"}>
            <RegistrationWidget season={season} />
          </div>
          <div className={"w-full md:hidden"}>
            <SeasonPricingSchemeCard
              season={season}
              isDesktop={isDesktop}
              pricingSchemeValues={pricingSchemeValues}
            />
          </div>
          <SeasonScheduleWidget
            season={season}
            locations={locations}
            rooms={rooms}
            staff={staff}
            useCourseActions={useCourseActions}
            showWebSize={showWebSize}
            open={openSheet}
            close={closeSheet}
            DatatableFiltersSheetFooter={DatatableFiltersSheetFooter}
            form={form}
            formHandlers={formHandlers}
            BaseInput={BaseInput}
            ScheduleClassCard={ScheduleClassCard}
            scheduleFilters={scheduleFilters}
            setScheduleFilters={setScheduleFilters}
            filterForm={filterForm as FilterFormDefinition<object, object>}
          />
        </DetailPageGridLeftColumn>
        <DetailPageGridRightColumn>
          <div className={"hidden w-full md:block"}>
            <RegistrationWidget season={season} />
          </div>
          <div className={"hidden w-full md:block"}>
            <SeasonPricingSchemeCard
              season={season}
              isDesktop={isDesktop}
              pricingSchemeValues={pricingSchemeValues}
            />
          </div>
        </DetailPageGridRightColumn>
      </DetailPageGrid>
    </DetailPageLayout>
  );
};

const buildMenuItems = (
  seasonActions: SeasonImplementedActions,
  season: SeasonDto | undefined,
  isDesktop: boolean,
): ActionMenuItemProps[] => {
  const actions: ActionMenuItemProps[] = [];

  if (season === undefined) {
    return [];
  }

  if (!isDesktop) {
    actions.push({
      title: "Edit",
      leftIcon: "createOutline",
      onClick: () => seasonActions.showUpdateForm(season),
      permission: "schedule:manage",
    });
  }

  actions.push(
    {
      title: season.archivedAt ? "Restore" : "Archive",
      leftIcon: season.archivedAt ? "syncOutline" : "archiveOutline",
      onClick: () =>
        season.archivedAt
          ? seasonActions.unarchive(season)
          : seasonActions.archive(season),
      permission: "schedule:manage",
    },
    {
      title: "Delete",
      leftIcon: "trashOutline",
      onClick: () =>
        seasonActions.deleteOne(season).then(() => Router.push("SeasonList")),
      destructive: true,
      permission: "schedule:manage",
    },
  );

  return actions;
};

const buildBreadcrumbs = (season: SeasonDto): Breadcrumb[] => {
  return [
    {
      text: t("breadcrumb.seasons"),
      onClick() {
        Router.push("SeasonList");
      },
    },
    { text: season.name },
  ];
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "schedule:view",
  SeasonDetailsPage,
  t("pageTitle.seasonDetails"),
);

export { PermissionChecked as SeasonDetailsPage };
