import * as React from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { CheckboxProps } from "@shared/components/interfaces/checkbox";

import { cn } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

export const BaseCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-4 w-4 shrink-0 rounded border-grey-400 disabled:cursor-not-allowed disabled:opacity-50",
      "data-[state=unchecked]:border data-[state=checked]:bg-grey-900 data-[state=indeterminate]:bg-grey-900",
      "data-[state=unchecked]:hover:border-grey-500",
      className,
    )}
    {...props}>
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-red-500")}>
      {props.checked === "indeterminate" && (
        <div className={"flex items-center justify-center"}>
          <Icon name={"removeOutline"} size={16} color={"#FFFFFF"} />
        </div>
      )}
      {props.checked === true && (
        <Icon name={"checkmarkSharp"} size={12} color={"white"} />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
BaseCheckbox.displayName = CheckboxPrimitive.Root.displayName;

export const Checkbox = ({
  checked,
  required,
  label,
  description,
  name,
  disabled,
  onChange,
  role = "checkbox",
}: CheckboxProps) => {
  return (
    <label className={cn("relative flex flex-row gap-x-3")}>
      <div className={cn("box-content h-4 w-4 rounded p-2 hover:bg-grey-200")}>
        <label
          className={cn(
            "absolute -left-2 -top-2 p-4 leading-none",
            disabled ? "cursor-not-allowed opacity-70" : "cursor-pointer",
          )}>
          <BaseCheckbox
            role={role}
            name={name}
            disabled={disabled}
            checked={checked}
            onCheckedChange={e => {
              onChange(e);
            }}
          />
        </label>
      </div>
      {(label || description) && (
        <div className="flex flex-col gap-1">
          {label && (
            <div className={"flex flex-row gap-1"}>
              <p className={"text-body-400 text-grey-900"}>{label}</p>
              {required && (
                <p className={"text-body-400 text-grey-500"}>(required)</p>
              )}
            </div>
          )}
          {description && (
            <div className={"flex flex-row gap-1"}>
              <div className="text-label-400 leading-normal text-grey-600">
                {description}
                {required && !label && (
                  <span className={"text-label-400 text-grey-500"}>
                    {" "}
                    (required)
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </label>
  );
};
