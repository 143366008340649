import {
  ContentPlaceholder,
  getTaxRatesIntroCardProps,
  taxRatesPlaceholderProps,
  useSettings,
  useTaxRatesDatatable,
} from "shared/components";
import { useBreakpoint } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { DetailPageLayout } from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { useCompanySettingsActions } from "@/modules/company/settings/useCompanySettingsActions";
import { useTaxRateActions } from "@/modules/company/tax/useTaxRateActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const TaxSettingsPage = () => {
  const { md: isDesktop } = useBreakpoint();
  const { enabled, taxIdLabel, taxIdNumber } = useSettings("tax");
  const { showTaxSettingsForm, toggleTax } = useCompanySettingsActions();
  const { showCreateForm, showUpdateForm, makeDefault, deleteOne } =
    useTaxRateActions();

  const { datatable } = useTaxRatesDatatable({
    showCreateForm,
    showUpdateForm,
    makeDefault,
    deleteOne,
    renderLabel: item => (
      <div className="flex flex-row gap-2">
        <span className="text-body-600 text-grey-900">{item.label}</span>
        {item.isDefault && (
          <Chip label={"Default"} variant="primary" size="sm" />
        )}
      </div>
    ),
    renderMobileColumn: item => (
      <div className="flex flex-col gap-1">
        <div className="flex flex-row gap-2">
          <span className="text-body-400 text-grey-900">{item.label}</span>
          {item.isDefault && (
            <Chip label={"Default"} variant="primary" size="sm" />
          )}
        </div>
        <span className="text-label-400 text-grey-600">{item.rate}%</span>
      </div>
    ),
    Datatable,
    api,
  });
  return (
    <CompanyLayout alwaysShowGlobalHeader={false}>
      <DetailPageLayout
        isDesktop={isDesktop}
        crumbs={[
          {
            text: "Settings",
            onClick: () => Router.push("CompanySettings"),
          },
          { text: "Tax configuration" },
        ]}
        title="Tax configuration"
        archivedEntityName=""
        editAction={enabled ? showTaxSettingsForm : undefined}
        additionalActions={
          enabled ? (
            <Button
              size="sm"
              variant="secondary"
              text="Disable"
              onClick={() => toggleTax(false)}
            />
          ) : undefined
        }
        actions={
          enabled && !isDesktop
            ? [
                {
                  title: "Edit tax settings",
                  onClick: showTaxSettingsForm,
                },
                {
                  title: "Disable",
                  onClick: () => {
                    toggleTax(false);
                  },
                  destructive: true,
                },
              ]
            : undefined
        }
        children={
          enabled ? (
            <div>
              <h2 className="pb-2 text-body-600 text-grey-900">Tax ID</h2>
              {presentSettings([
                ["Tax identification label", taxIdLabel],
                ["Tax identification number", taxIdNumber],
              ])}
              <div className="pt-4">
                <BaseCard {...getTaxRatesIntroCardProps(t)} />
              </div>
              <div className="pt-8">{datatable}</div>
            </div>
          ) : (
            <TaxNotEnabledPlaceholder enable={() => toggleTax(true)} />
          )
        }
      />
    </CompanyLayout>
  );
};

const TaxNotEnabledPlaceholder = ({ enable }: { enable: () => void }) => (
  <ContentPlaceholder
    {...taxRatesPlaceholderProps}
    action={<Button size="sm" variant="brand" text="Enable" onClick={enable} />}
  />
);

const presentSettings = (settings: [string, string | null][]) => (
  <dl className="flex flex-col gap-2">
    {settings.map(([label, value]) => (
      <div className="flex-row gap-2 md:flex" key={label}>
        <dt className="text-body-400 text-grey-900">{label}</dt>
        <dd className="grow text-body-400 text-grey-600">{value}</dd>
      </div>
    ))}
  </dl>
);

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "settings:manage",
  TaxSettingsPage,
  "Tax configuration",
);

export { PermissionChecked as TaxSettingsPage };
