import { UserAccountDto, UserDto } from "@justraviga/classmanager-sdk";

import { AppcuesAdapterInterface } from "@shared/appcues/AppcuesAdapter";

interface AppcuesIdentityAttributes {
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  permissionSet?: string;
  role?: string;
  companyId?: string;
  country?: string;
}

/**
 * Convince Typescript that Appcues exists on the window object
 */
declare global {
  interface Window {
    Appcues?: {
      identify: (
        userId: string | undefined,
        userAttributes: AppcuesIdentityAttributes,
      ) => void;
      page: () => void;
      track: (eventName: string, payload: unknown) => void;
    };
  }
}

export function identifyUser(user: UserDto, account: UserAccountDto | null) {
  try {
    window.Appcues?.identify?.(user.id, {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      permissionSet: account?.permissionSet,
      role: account?.role,
      companyId: account?.company?.id,
      country: account?.company?.countryCode,
    });
  } catch (e) {
    // we don't want to the apps to crash if this fails
    console.error(e);
  }
}

export function trackEvent(eventName: string, payload: unknown) {
  if (typeof window !== "undefined" && window.Appcues?.track) {
    window.Appcues.track(eventName, payload);
  }
}

export const appcuesAdapter: AppcuesAdapterInterface = {
  identifyUser,
};
