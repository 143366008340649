import { useState } from "react";

import { Link } from "@swan-io/chicane";

import {
  useAuthState,
  useGenericComponents,
  useStripeStatus,
} from "shared/components";

import { api } from "@/lib/api/apiClient";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export const BillingOnlinePaymentsNavItem = () => {
  const { protector: stripeDeniedMessage } = useStripeStatus();
  const [isLoading, setIsLoading] = useState(false);
  const { account } = useAuthState();

  const accessIsDenied = account?.permissionSet !== "owner";
  const onlinePaymentsDeniedMessage =
    stripeDeniedMessage ?? accessIsDenied
      ? "You don't currently have access to this feature."
      : undefined;

  async function onClick() {
    setIsLoading(true);
    try {
      const response = await api.stripe.getProfileLink();
      window.location.href = response.url;
    } finally {
      setIsLoading(false);
    }
  }

  const { Loader } = useGenericComponents();

  return (
    <ProtectedOverlay protector={onlinePaymentsDeniedMessage}>
      <li>
        {isLoading ? (
          <div className={"px-4 py-2"}>
            <Loader />
          </div>
        ) : (
          <Link
            to={""}
            onClick={onClick}
            className={"flex rounded px-4 py-2 hover:bg-grey-100"}
            activeClassName={"bg-grey-200"}>
            <p className={"truncate"}>Online payments</p>
          </Link>
        )}
      </li>
    </ProtectedOverlay>
  );
};
