import { useWaitingListActions } from "@shared/components/actions/useWaitingListActions";

import { SummaryCard } from "@/modules/company/classPlanner/classes/classDetails/SummaryCard";
import { Router } from "@/routing/router";

export const WaitingListSummaryCard = ({
  classId,
  waitingListCount,
}: {
  classId: string;
  waitingListCount: number;
}) => {
  const actions = useWaitingListActions();
  return (
    <SummaryCard
      addPermission={"enrolments:manage"}
      icon={"reorderFour"}
      onCardClick={() => Router.push("ClassWaitingList", { classId })}
      onAddClick={() =>
        actions.showCreateForm({
          courseId: classId,
        })
      }
      text={`${waitingListCount} waiting`}
      footer={"Outstanding"}
    />
  );
};
