import { useAuthState } from "../auth/useAuthState";
import { getPlatformFunctions } from "../platformSpecific";

export const useTaxRates = () => {
  const { account } = useAuthState();
  const { useApi } = getPlatformFunctions();

  const hasTaxEnabled = account?.company?.settings.tax.enabled ?? false;

  const { data: taxRatesResponse, isLoading } = useApi(
    "listTaxRate",
    { selectAll: true },
    { enabled: hasTaxEnabled },
  );
  const taxRates = taxRatesResponse?.data ?? [];
  const defaultTaxRateId = taxRates.find(rate => rate.isDefault)?.id;

  return {
    isLoading,
    hasTaxEnabled,
    taxRates,
    defaultTaxRateId,
  };
};
