import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const PricingBlurb = () => {
  const { Text, View } = useGenericComponents();
  return (
    <View className={"py-4 space-y-1"}>
      <View
        className={
          "flex flex-col sm:flex-row items-start sm:items-center gap-2 pb-4 md:pb-0"
        }>
        <Text className={"text-body-600 text-grey-900"}>
          When do your members pay?
        </Text>
        <Text className={"text-body-400 text-grey-600"}>
          (Select at least one)
        </Text>
      </View>
      <View className={"flex flex-row items-center"}>
        <Text className={"text-body-400 text-grey-600"}>
          Members can choose when to pay for their classes when they enroll.
        </Text>
      </View>
    </View>
  );
};
