import {
  LocationDto,
  PricingScheme,
  RoomDto,
  SeasonDto,
  StaffDto,
} from "@justraviga/classmanager-sdk";

import {
  classAvailableSpacesInformation,
  ClassEntity,
  colorPaletteColors,
  displayLessonTimes,
  enumLabel,
  formatDate,
  formatMoneyFromInteger,
  getSeasonStatus,
  useBreakpoint,
} from "shared/lib";

import { Tooltip } from "@/modules/common/overlays/Tooltip";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { useClassActionMenu } from "@/modules/company/classPlanner/classes/menus/useClassActionMenu";
import { Router } from "@/routing/router";

export const ScheduleClassCard = ({
  season,
  classEntity,
  location,
  room,
  staff,
}: {
  season: SeasonDto;
  classEntity: ClassEntity;
  location?: LocationDto | undefined;
  room?: RoomDto | undefined;
  staff?: StaffDto | undefined;
}) => {
  const { md: showWebSize } = useBreakpoint();
  const { class: courseDto, waitingLists } = classEntity;
  const { items } = useClassActionMenu({ classEntity, season });

  const waitingListsCount = waitingLists.totalCount;

  const locationName = location ? location.name : null;
  const roomName = room ? room.name : null;

  const staffName = staff ? `${staff.firstname} ${staff.lastname}` : null;

  const formatedPrice = () => {
    const price = formatMoneyFromInteger(courseDto.price ?? 0);
    const label = enumLabel("pricingModel", season.pricingModel);

    return `${price} ${label}`;
  };

  const classPrice =
    season.pricingScheme == PricingScheme.ClassCount
      ? "See pricing scheme"
      : formatedPrice();

  const classSpacesInfo = classAvailableSpacesInformation({
    course: classEntity.props,
    seasonStatus: getSeasonStatus(season),
  });

  const fillColor = courseDto.archivedAt
    ? undefined
    : colorPaletteColors[courseDto.colorId];

  return (
    <BaseCard
      border={!!courseDto.archivedAt}
      onClick={() => {
        Router.push("ClassDetails", { id: courseDto.id });
      }}
      padding={2}
      gap={0}
      fillColor={fillColor}
      headerRightSlot={
        <>
          {courseDto.archivedAt && !showWebSize && (
            <Chip label={"Archived"} variant={"neutral"} size={"sm"} />
          )}

          <div className="hidden whitespace-nowrap text-body-400 leading-relaxed text-grey-900 md:flex">
            {displayLessonTimes(classEntity.class)}
          </div>
        </>
      }
      leftSlot={
        <div className={"flex items-center space-x-2 truncate"}>
          <Tooltip
            showContent={showWebSize}
            trigger={
              <h4
                onClick={() => {
                  Router.push("ClassDetails", { id: courseDto.id });
                }}
                className={
                  "flex-grow cursor-pointer truncate pr-1 font-semibold text-grey-900"
                }>
                {courseDto.name}
              </h4>
            }
            children={
              <div className={"whitespace-pre-wrap"}>{courseDto.name}</div>
            }
          />
          <div className="hidden md:flex">
            {courseDto.archivedAt && (
              <Chip label={"Archived"} variant={"neutral"} size={"sm"} />
            )}
          </div>
        </div>
      }
      headerMenu={
        <ActionMenu
          width={"w-80"}
          header={
            <h3
              className={
                "truncate text-label-600 text-grey-900 md:text-body-600"
              }>
              {courseDto.name}
            </h3>
          }
          showActionsAsBottomSheetOnMobile={true}
          trigger={
            <IconButton
              size={"lg"}
              icon={"ellipsisHorizontal"}
              variant={"standard"}
            />
          }
          items={items}
        />
      }
      footerSlot={
        <div className={"space-y-1 pt-1"}>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row gap-x-3">
              <Tooltip
                showContent={showWebSize}
                trigger={
                  <div>
                    <Icon name={"calendarClearOutline"} size={16} />
                  </div>
                }
                children={
                  <div className={"flex flex-col"}>
                    <span>
                      Start date: {formatDate(season.startAt, "dayMonthYear")}
                    </span>
                    <span>
                      End date: {formatDate(season.endAt, "dayMonthYear")}
                    </span>
                  </div>
                }
              />

              {locationName && (
                <Tooltip
                  showContent={showWebSize}
                  trigger={
                    <div>
                      <Icon name={"locationOutline"} size={16} />
                    </div>
                  }
                  children={
                    <span>
                      {locationName} - {roomName}
                    </span>
                  }
                />
              )}

              {staffName && (
                <Tooltip
                  showContent={showWebSize}
                  trigger={
                    <div>
                      <Icon name={"personOutline"} size={16} />
                    </div>
                  }
                  children={<span>{staffName}</span>}
                />
              )}

              <Tooltip
                showContent={showWebSize}
                trigger={
                  <div>
                    <Icon name={"cashOutline"} size={16} />
                  </div>
                }
                children={<span>{classPrice}</span>}
              />
            </div>
            <div className="flex text-label-400 leading-relaxed text-grey-900 md:hidden">
              {displayLessonTimes(classEntity.class)}
            </div>
            <div className="hidden flex-row gap-x-3 md:flex">
              <div className="flex flex-row items-center space-x-1">
                <Tooltip
                  trigger={
                    <div>
                      <Icon name={"peopleOutline"} size={16} />
                    </div>
                  }
                  children={<span>{classSpacesInfo.description}</span>}
                />
                <span className={"text-sm text-grey-900"}>
                  {classSpacesInfo.text}
                </span>
              </div>
              <div className="flex flex-row items-center space-x-1">
                <Tooltip
                  trigger={
                    <div>
                      <Icon name={"timeOutline"} size={16} />
                    </div>
                  }
                  children={
                    <span>
                      {` ${waitingListsCount} ${
                        waitingListsCount === 1 ? "student" : "students"
                      } on the waiting list`}
                    </span>
                  }
                />

                <span className={"text-sm text-grey-900"}>
                  {waitingListsCount}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between gap-x-3 md:hidden">
            <div className="flex flex-row items-center space-x-1">
              <Icon name={"peopleOutline"} size={16} />
              <span className={"text-sm text-grey-900"}>
                {classSpacesInfo.text}
              </span>
            </div>
            <div className="flex flex-row items-center space-x-1">
              <Icon name={"timeOutline"} size={16} />

              <span className={"text-sm text-grey-900"}>
                {waitingListsCount}
              </span>
            </div>
          </div>
        </div>
      }
    />
  );
};
