import { Option } from "./adminPageTypes";
import { CheckboxProps } from "../../components/interfaces/checkbox";

export const checkboxOptions = (
  isChecked: boolean,
  setIsChecked: React.Dispatch<boolean>,
): Array<Option<CheckboxProps>> => {
  return [
    {
      title: "Text & Titles",
      props: [
        { label: "Just label", checked: true, onChange: () => {} },
        {
          label: "Label",
          description: "with description",
          checked: false,
          onChange: () => {},
        },
        { description: "Just description", checked: true, onChange: () => {} },
      ],
    },
    {
      title: "Required",
      props: [
        {
          label: "Just label",
          checked: false,
          required: true,
          onChange: () => {},
        },
        {
          label: "Required",
          description: "with description",
          checked: false,
          required: true,
          onChange: () => {},
        },
        {
          description: "Just description",
          checked: false,
          required: true,
          onChange: () => {},
        },
      ],
    },
    {
      title: "States",
      props: [
        { label: "Checked", checked: true, onChange: () => {} },
        {
          label: "Indeterminate",
          checked: "indeterminate",
          onChange: () => {},
        },
        { label: "Unchecked", checked: false, onChange: () => {} },
      ],
    },
    {
      title: "Disabled",
      props: [
        {
          label: "Just label",
          checked: false,
          onChange: () => {},
          disabled: true,
        },
        {
          label: "Label",
          description: "with description",
          checked: false,
          onChange: () => {},
          disabled: true,
        },
        {
          description: "Just description",
          checked: false,
          onChange: () => {},
          disabled: true,
        },
      ],
    },
    {
      title: "Clickable area test",
      props: [
        {
          label: "Medium clickable area",
          description:
            "Used in most forms. Has negative margins to align with labels and other form elements",
          checked: isChecked,
          onChange: () => {
            setIsChecked(!isChecked);
          },
        },
      ],
    },
  ];
};
