import { createContext } from "react";

import { Permission } from "../../permission";

export type PermissionContext = {
  hasPermission: (permission: Permission) => boolean;
  isLoading: boolean;
};

export const PermissionContext = createContext<PermissionContext>({
  hasPermission: () => true,
  isLoading: false,
});
