import { useFormActions } from "../../../FormActionsProvider";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  goToSeasonList: () => void;
}

export const CreateSeasonFooter = ({ goToSeasonList }: Props) => {
  const { Button, View } = useGenericComponents();
  const { submit, isLoading, isSubmitDisabled } = useFormActions();

  return (
    <View className={"flex flex-row items-center justify-between px-8 py-4"}>
      <View>
        <Button
          variant={"secondary"}
          size={"lg"}
          text={"Cancel"}
          onClick={goToSeasonList}
        />
      </View>
      <View>
        <Button
          variant={"brand"}
          size={"lg"}
          text={"Create"}
          onClick={submit}
          disabled={isSubmitDisabled || isLoading}
          loading={isLoading}
        />
      </View>
    </View>
  );
};
