import {
  CreateRegistrationFeeRequest,
  RepetitionInterval,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { makeTaxRateSelectOptions } from "../../data/makeTaxRateSelectOptions";
import { useTaxRates } from "../../data/useTaxRates";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { monthDayToZeroYearDate } from "../../registrationFeeUtils";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";
import { useSettings } from "../useSettings";

type Schema = Omit<CreateRegistrationFeeRequest, "repititionInterval">;

export const useRegistrationFeeFormDefinition = (
  repetitionInterval: RepetitionInterval,
) => {
  const { hasTaxEnabled, taxRates } = useTaxRates();
  const { locale } = useSettings("general");

  return () => {
    const builder = new FormDefinitionBuilder<Schema>()
      .text("name", { label: "Name", required: true })
      .conditional(["dueDate", "proRated"], ["repetitionInterval"], v => {
        return v.repetitionInterval === RepetitionInterval.Annual;
      })
      .dayMonth("dueDate", {
        required: repetitionInterval === RepetitionInterval.Annual,
        label: "Year start date",
        locale,
      })
      .switch("proRated", {
        checked: false,
        label: "Prorate annual fee",
        description:
          "Charge a lower fee when a student enrolls later in the year. Calculated by the number of days remaining.",
      })
      .money("amount", { label: "Per student", required: true })
      .money("maxAmountPerFamily", {
        label: "Max per family",
        description:
          "The maximum registration fee will cap the amount charged when enrolling multiple students from the same family.",
      })
      .conditional(["taxRateId"], ["taxRateId"], () => hasTaxEnabled)
      .select("taxRateId", {
        label: "Tax rate",
        data: makeTaxRateSelectOptions(taxRates),
        required: hasTaxEnabled,
        notFoundLabel: (
          <ContentPlaceholder
            icon={"helpCircleOutline"}
            title={"No tax rates yet"}
            description={"Go to settings to add tax rates"}
          />
        ),
      });

    return builder.getDefinition() as FormDefinition<Schema>;
  };
};

export const makeRegistrationFeeCreateRequest =
  ({
    api,
    repetitionInterval,
  }: {
    api: Api;
    repetitionInterval: RepetitionInterval;
  }) =>
  (formData: Schema) => {
    if (formData.dueDate) {
      formData.dueDate = monthDayToZeroYearDate(formData.dueDate);
    }

    return api.registrationFees.createRegistrationFee({
      createRegistrationFeeRequest: {
        ...formData,
        repetitionInterval,
      },
    });
  };

export const makeRegistrationFeeUpdateRequest =
  ({
    api,
    id,
    repetitionInterval,
  }: {
    api: Api;
    id: string;
    repetitionInterval: RepetitionInterval;
  }) =>
  (formData: Schema) => {
    if (formData.dueDate) {
      formData.dueDate = monthDayToZeroYearDate(formData.dueDate);
    }

    return api.registrationFees.updateRegistrationFee({
      id,
      createRegistrationFeeRequest: {
        ...formData,
        repetitionInterval,
      },
    });
  };
