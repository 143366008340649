import { CardContainerContent } from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { Banner } from "@/modules/common/ui/Banner";
import { HowToUseWaiversBanner } from "@/modules/company/waivers/HowToUseWaiversBanner";
import { useWaiversListPage } from "@/modules/company/waivers/useWaiversListPage";
import { WaiverCard } from "@/modules/company/waivers/WaiverCard";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";

const WaiversPage = () => {
  const { waivers, isEmpty, showCreateForm, goToArchivedItems } =
    useWaiversListPage();

  const primaryAction: PrimaryActionItemProps = {
    icon: "addOutline",
    title: "Create",
    onClick: showCreateForm,
    permission: "waivers:manage",
  };
  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Go to archived items",
      leftIcon: "archiveOutline",
      onClick: goToArchivedItems,
      permission: "waivers:view",
    },
  ];

  return (
    <CompanyCardPage
      title={"Waivers & policies"}
      isEmpty={isEmpty()}
      primaryAction={primaryAction}
      menuItems={menuItems}
      banner={<Banner content={<HowToUseWaiversBanner />} />}>
      <CardContainerContent maxColumns={2}>
        {waivers?.data.map((waiver, key) => (
          <WaiverCard waiver={waiver} key={key} />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "waivers:view",
  WaiversPage,
  "Waivers",
);

export { PermissionChecked as WaiversPage };
