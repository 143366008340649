import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import { dateToday } from "../../dateUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import { TransactionPrimaryType } from "../../transactions/transactionTypes";
import { AggregateTransactionWithPrimaryType } from "../../transactionUtils";
import { FamilyTransaction } from "../datatables/useTransactionsDatatable";
import { CustomTransactionItem } from "../formDefinitions/customTransactionItemForm";
import {
  makeRecordPaymentForm,
  makeRecordPaymentRequest,
} from "../formDefinitions/recordPaymentForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { CreditNoteProcess } from "../transactions/CreditNoteProcess";
import { RefundProcess } from "../transactions/RefundProcess";
import { EntityActions } from "../useDefaultEntityActions";

type Model = AggregateTransactionWithPrimaryType;

export type ShowPreviewType = (
  transaction: AggregateTransactionWithPrimaryType,
  isFamilyPortal?: boolean,
) => void;

export type ImplementedTransactionActions = Pick<
  EntityActions<Model>,
  "deleteOne"
> & {
  showRetryPaymentForm: (transaction: AggregateTransactionDto) => void;
  showCreditNoteForm: (
    transaction: AggregateTransactionDto,
    setTitle: (title: string) => void,
  ) => void;
  showChargeCardOnFileForm: (familyId: string) => void;
  showPreview: ShowPreviewType;
  showRefundForm: (
    transaction: FamilyTransaction,
    setTitle: (title: string) => void,
  ) => void;
  showRecordPaymentForm: ({ familyId }: { familyId?: string }) => void;
  showCustomTransactionCreateForm: () => void;
  showCustomTransactionCreateItemSheet: ({
    addItem,
  }: {
    addItem: (item: CustomTransactionItem) => void;
  }) => void;
  showCustomTransactionUpdateItemSheet: ({
    item,
    updateItem,
  }: {
    item: CustomTransactionItem;
    updateItem: (item: CustomTransactionItem) => void;
  }) => void;
  showUnpostedTuitionForm: (familyId: string) => void;
  showPreviewTuitionForm: (familyId: string) => void;
};

/**
 * These actions don't need any frontend so can be shared
 */
export const useSharedTransactionActions = () => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();

  const defaultActions = usePlatformEntityActions<Model>({
    entity: "transaction",
  });

  const today = dateToday();

  return {
    showCreditNoteForm: (
      transaction: AggregateTransactionDto,
      setTitle: (title: string) => void,
    ) =>
      defaultActions.showCreateForm({
        title: "Add credit note",
        form: (
          <CreditNoteProcess transaction={transaction} setTitle={setTitle} />
        ),
      }),
    showRecordPaymentForm: ({ familyId }: { familyId?: string }) => {
      defaultActions.showCreateForm({
        title: "Record payment",
        form: (
          <GenericForm
            apiRequest={makeRecordPaymentRequest({ familyId })}
            defaultValues={{ date: today, familyId }}
            formDefinitionHook={makeRecordPaymentForm({ familyId })}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      });
    },
    showRefundForm: (
      transaction: FamilyTransaction,
      setTitle: (title: string) => void,
    ) =>
      defaultActions.showCreateForm({
        title: "Process Refund",
        form: <RefundProcess transaction={transaction} setTitle={setTitle} />,
      }),
    deleteOne: (entity: Model) => {
      return defaultActions.deleteOne(
        () => api.transactions.deleteTransaction({ id: entity.transaction.id }),
        {
          title: deleteTitles[entity.primaryType],
          description: deleteConfirmationTexts[entity.primaryType],
        },
      );
    },
    showCustomTransactionCreateItemSheet: () => {},

    showCustomTransactionUpdateItemSheet: () => {},
  };
};

const deleteTitles: Record<TransactionPrimaryType, string> = {
  paymentPending: "Delete pending payment",
  creditNote: "Delete credit note",
  fee: "Delete fee",
  manualDebit: "Delete transaction",
  payment: "Delete payment",
  paymentFailed: "Delete payment",
  paymentFailedReversal: "Delete payment",
  refund: "Delete transaction",
  unknown: "Delete transaction",
};

const deleteConfirmationTexts: Record<TransactionPrimaryType, string> = {
  paymentPending:
    "Are you sure you want to delete this pending payment? This will result in the family not being charged for their outstanding tuition",
  creditNote:
    "Are you sure you want to delete this credit note? This cannot be undone.",
  fee: "Are you sure you want to delete this fee? This cannot be undone.",
  manualDebit:
    "Are you sure you want to delete this transaction? This cannot be undone.",
  payment:
    "Are you sure you want to delete this payment? This may result in an outstanding balance for this family.",
  paymentFailed:
    "Are you sure you want to delete this failed payment record? This cannot be undone and will delete the associated failed payment reversal.",
  paymentFailedReversal:
    "Are you sure you want to delete this failed payment reversal? This cannot be undone.",
  refund:
    "Are you sure you want to delete this transaction? This cannot be undone.",
  unknown:
    "Are you sure you want to delete this transaction? This cannot be undone.",
};
