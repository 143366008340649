import { SortSchema } from "@justraviga/classmanager-sdk";

import { Breadcrumb, ContentPlaceholder } from "shared/components";

import { useApi } from "@/lib/api/apiClient";
import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { SeasonCard } from "@/modules/company/classPlanner/seasons/card/SeasonCard";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const ArchivedSeasonsPage = () => {
  const { data: seasons } = useApi("listSeason", {
    onlyArchived: true,
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  });

  const isEmpty = !seasons?.data || seasons.data.length === 0;

  const crumbs: Breadcrumb[] = [
    {
      text: t("breadcrumb.seasons"),
      onClick: () => {
        Router.push("SeasonList");
      },
    },
    {
      text: t("pageTitle.seasonArchive"),
    },
  ];

  return (
    <CompanyCardPage
      title={t("pageTitle.seasonArchive")}
      crumbs={crumbs}
      isEmpty={isEmpty}>
      <CardContainerPlaceholder>
        <ContentPlaceholder
          icon={"archiveOutline"}
          title={t("placeholder.noArchivedSeasons")}
        />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {seasons?.data.map((season, key) => (
          <SeasonCard season={season} key={key} />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "schedule:view",
  ArchivedSeasonsPage,
  t("pageTitle.seasonArchive"),
);

export { PermissionChecked as ArchivedSeasonsPage };
