import { useStaffDatatable } from "shared/components";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { useStaffActions } from "@/modules/company/staff/useStaffActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const StaffList = () => {
  const staffActions = useStaffActions();
  const { datatable } = useStaffDatatable({
    Datatable,
    api,
    archive: staffActions.archive,
    archiveMany: staffActions.archiveMany,
    deleteMany: staffActions.deleteMany,
    deleteOne: staffActions.deleteOne,
    onRowClick: item => Router.push("StaffDetails", { id: item.id }),
    showCreateForm: staffActions.showCreateForm,
    showUpdateForm: staffActions.showUpdateForm,
    unarchive: staffActions.unarchive,
  });
  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "staff:view",
  StaffList,
  "Staff",
);

export { PermissionChecked as StaffList };
