import { PropsWithChildren } from "react";

import { CustomFieldDto } from "@justraviga/classmanager-sdk";

import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { CustomFiledCardFooter } from "@/modules/company/customFields/card/CustomFieldCardFooter";
import { CustomFieldCardHeader } from "@/modules/company/customFields/card/CustomFieldCardHeader";
import { useCustomFieldActions } from "@/modules/company/customFields/useCustomFieldActions";

export const CustomFieldCard = ({
  customField,
}: {
  customField: CustomFieldDto;
}) => {
  const { deleteOne, showUpdateForm } = useCustomFieldActions();

  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Edit",
      onClick: () => showUpdateForm(customField),
      permission: "customFields:manage",
    },
    {
      title: "Delete",
      onClick: () => deleteOne(customField),
      destructive: true,
      permission: "customFields:manage",
    },
  ];

  return (
    <>
      <CustomFieldCardContainer>
        <CustomFieldCardHeader type={customField.type} menuItems={menuItems} />
        <CustomFieldCardBody content={customField.title} />
        <CustomFiledCardFooter entity={customField.entity} />
      </CustomFieldCardContainer>
    </>
  );
};

const CustomFieldCardContainer = ({ children }: PropsWithChildren) => (
  <div className={"flex flex-col gap-4 rounded border border-grey-300 p-4"}>
    {children}
  </div>
);

const CustomFieldCardBody = ({ content }: { content: string }) => (
  <div className={"line-clamp-2 flex-grow"}>{content}</div>
);
